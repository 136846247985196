import React, { useState } from 'react';
import { Range } from 'react-range';
import Currency from './Currency';

const STEP = 1;
const MIN = 10;
const MAX = 1000;

export default function RangeSlider({ rangeValue }) {
    const [values, setValues] = useState([10, 1000]);

    const handleChange = (newValues) => {
        setValues(newValues);
        rangeValue(newValues);
    };

    return (
        <div className='w-100'>

            <Range
                values={values}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={handleChange}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        className='rangeSlider__'
                        style={{
                            ...props.style,
                            height: '6px',
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div {...props}
                        style={{
                            ...props.style,
                            height: '16px',
                            width: '16px',
                            borderRadius: '50%',
                            boxShadow: '0px 2px 6px rgba(var(--color8),var(--alpha3))',
                        }}
                    />
                )}
            />

            <p className='mt-2 fw-bold'>Price - <Currency/>{values[0]} - <Currency/>{values[1]}</p>
        </div>
    );
}

// newslettersSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';

// Define an initial state
const initialState = {
  newsletterList: {
    items: [],
  },
  loading: false,
  error: null,
};

// Create an instance of the NewsletterService class
const services = new Service();

export const fetchNewsletterList = createAsyncThunk('newsletters/fetchNewsletterList', async (page = 1) => {
  try {
     const url = `newsletter/list?page=${page}`;
    const data = await services.get(url);
    return data?.data; // Assuming the response has a 'data' property containing the newsletters
  } catch (error) {
    console.error(error.response); // Adjust error handling as needed
    throw Error('Failed to fetch newsletters');
  }
});

// Create the slice
const newslettersSlice = createSlice({
  name: 'newsletters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsletterList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewsletterList.fulfilled, (state, action) => {
        state.loading = false;
        state.newsletterList = action.payload;
      })
      .addCase(fetchNewsletterList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the reducer
export default newslettersSlice.reducer;

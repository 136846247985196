// testimonialSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../Components/Utils';

// Define the initial state for the testimonial slice
const initialState = {
   testimoniallist: [],
   testimonialDetails: {},
   testimonial: null,
   status: 'idle',
   error: null,
};
const baseurl = process.env.REACT_APP_BASE_URL

// Define an async thunk for fetching the testimonial list
export const fetchTestimonialList = createAsyncThunk('testimonial/fetchList', async (_, { rejectWithValue }) => {
   try {
      const response = await fetch(`${baseurl}/testimonial/list   `);
      const data = await response.json();
      return data;
   } catch (error) {
      return rejectWithValue(error.message);
   }
});

// Create the testimonial slice
const testimonialSlice = createSlice({
   name: 'testimonial',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchTestimonialList.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(fetchTestimonialList.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.testimoniallist = action.payload;
         })
         .addCase(fetchTestimonialList.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload; // Now storing the error message in the state
         })
   },
});

export default testimonialSlice.reducer;

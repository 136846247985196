import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Notification, { notify } from "../../Components/Notification";
import LTable from "../../Components/LTable";
import Pagelayout from "../../Layouts/Pagelayout";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllReviews } from "../../redux/reviewSlice";
import { AiFillStar } from "react-icons/ai";
import { tableSerialNumber } from "../../Components/Utils";
import doAPI from "../../api";
import Pagination from "../../Components/Pagination";
import { fetchProductListAll } from "../../redux/productSlice";

export default function Reviews() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchEntries = () => {
    dispatch(
      fetchAllReviews({ page: page, product_id: "", keywords: "", status: 0 })
    );
  };

  const fetchProducts = () => {
    dispatch(
      fetchProductListAll({
        page: 1,
        search: searchQuery,
        category: "",
        price: "",
        size: "",
        color: "",
        status: "",
        sku: "",
      })
    );
  };

  useEffect(() => {
    fetchEntries();
  }, [dispatch, page]);

  useEffect(() => {
    fetchProducts();
  }, [dispatch, searchQuery]);

  
  const productList = useSelector((state) => state.product)?.productList;
  console.log(productList);

  const reviews = useSelector((state) => state?.reviews?.reviews?.data?.data);

  const handleStatusChange = (userId, isActive) => {
    doAPI
      .putData("product/review/status-change", {
        id: userId,
        status: isActive == 1 ? 0 : 1,
      })
      .then((res) => {
        fetchEntries();
        if (res.status) {
          notify(res?.data?.message);
        } else {
          notify(res.message[0]?.message);
        }
      })
      .catch((error) => {
        console.error("Error - ", error);
      });
  };

  const handlePageChange = (newPage) => {
    console.log(newPage);
    setPage(parseInt(newPage));
  };
  const handleGoToPage = (event) => {
    console.log(event.target.value);
    setPage(parseInt(event.target.value));
  };

  const searchName = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Pagelayout
      title="Reviews"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Reviews", link: "#" },
      ]}
      actions={
        <>
          <input
            onChange={(event) => searchName(event)}
            type="search"
            placeholder="Search Product..."
          />
        </>
      }
    >
      <Notification />
      <Card>
        <Card.Body>
          <LTable isLoading={reviews?.rows === undefined}>
            <thead>
              <tr>
                <th>S.no.</th>
                <th>Rating</th>
                <th>Review</th>
                <th>User</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {reviews &&
                reviews?.rows.map((i, index) => {
                  const serialNumber = tableSerialNumber(
                    index,
                    reviews?.cur_page,
                    reviews?.rows.length,
                    20
                  );
                  return (
                    <tr key={index}>
                      <td>{serialNumber}</td>
                      <td>
                        {i.rating} <AiFillStar className="text-warning" />
                      </td>
                      <td>{i.review}</td>
                      <td>
                        {i.user?.first_name} {i.user?.family_name}
                      </td>
                      <td>
                        <label
                          onClick={() => handleStatusChange(i.id, i.is_active)}
                          className={
                            i.is_active === 1
                              ? "custom_check active"
                              : "custom_check"
                          }
                        >
                          <div className="custom_check_wrapper">
                            <span></span>
                            <label>
                              {i.is_active === 1 ? "Active" : "Deactivated"}
                            </label>
                          </div>
                        </label>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </LTable>
          <Pagination
            pagecount={reviews?.total_pages}
            handleGoToPage={handleGoToPage}
            currentpage={page || 1}
            onPageChange={handlePageChange}
          />
        </Card.Body>
      </Card>
    </Pagelayout>
  );
}

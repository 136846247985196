// carouselSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../Components/Utils';

// Define the initial state for the carousel slice
const initialState = {
   carousellist: [],
   carouselDetails: {},
   carousel: null,
   status: 'idle',
   error: null,
};
const baseurl = process.env.REACT_APP_BASE_URL

// Define an async thunk for fetching the carousel list
export const fetchCarouselList = createAsyncThunk('carousel/fetchList', async (_, { rejectWithValue }) => {
   try {
      const response = await fetch(`${baseurl}/slider/list`);
      const data = await response.json();
      return data;
   } catch (error) {
      return rejectWithValue(error.message);
   }
});

// Create the carousel slice
const carouselSlice = createSlice({
   name: 'carousel',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchCarouselList.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(fetchCarouselList.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.carousellist = action.payload;
         })
         .addCase(fetchCarouselList.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload; // Now storing the error message in the state
         })
   },
});

export default carouselSlice.reducer;

import React from 'react'
import Charts from '../../Components/Charts';

export default function MonthlyStoresCharts() {
  const lineChartOptions = {
    colors : ['#45D995'],
    chart: {
      id: 'basic-line'
    },
    fill: {
      opacity: 0.5
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    grid: { show: false },
  };

  const lineChartSeries = [
    {
      name: 'series-2',
      data: [10, 30, 45, 35, 60, 55, 80, 75, 95, 120, 130, 140]
    }
  ];

  return (
    <Charts type="area" options={lineChartOptions} series={lineChartSeries} height={350} />
  )
}

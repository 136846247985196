import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import Notification, { notify } from '../Components/Notification';
import * as Yup from 'yup';
import Service from '../http';
import Label from '../Components/Label';
import CustomInput from '../Components/CustomInput';
import Logo from '../Components/logo';
import { Link, useNavigate, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../redux/loginSlice'; // Import the login async thunk
import Image from '../Components/Image';
import { BiLoader } from 'react-icons/bi';

export default function Login() {

   const navigate = useNavigate();

   const services = new Service();

   const [isLoading, setIsLoading] = useState(false)

   const dispatch = useDispatch();

   const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

   const [locationData, setLocationData] = useState(null);

   useEffect(() => {
      // Fetch geolocation data when the component mounts
      fetch('https://geolocation-db.com/json/')
         .then((response) => response.json())
         .then((data) => {
            // Set the geolocation data in state
            setLocationData(data);
         })
         .catch((error) => {
            console.error('Error fetching geolocation data:', error);
         });
   }, []);


   const handleSubmit = async (values, { resetForm }) => {

      setIsLoading(true);
      try {
         await dispatch(loginUser(values));
      } catch (error) {
         console.error('Login failed:', error);
      } finally {
         setIsLoading(false);
      }

      resetForm();
   };



   const formik = useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
         username: "",
         password: "",

      },
      onSubmit: handleSubmit, // Pass your submit function here
      validationSchema: Yup.object().shape({
         username: Yup.string().email().required('Not a proper username'),
         password: Yup.string().required('Please Enter your password'),
         // .matches(
         // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
         // "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
         // ),
      })
   });

   return (
      <Row className='m-0 d-flex align-items-center' style={{ minHeight: '100vh' }}>
         <Col xl={8} lg={7} className='d-flex align-items-center justify-content-center'><Image src={'https://minimals.cc/assets/illustrations/illustration_dashboard.png'} className={'mw-100'} />

         </Col>
         <Col xl={4} lg={5}>
            <Notification />

            <Card className="login-wrap rounded-0 shadow-none h-100">
               <Card.Body className='p-0'>
                  <Col md={12} className='px-md-5 p-3'>
                     <Row>
                        <Col md={12} className='text-center'>
                           <Logo variant="dark" className="my-4" height={70} />

                           <h2 className='fw-bold'>Login into your account</h2>
                           <h5 className="mb-md-5 mb-4">Welcome User!</h5>
                        </Col>
                        <Form onSubmit={formik.handleSubmit} >
                           <Col className="col-12 mx-auto mb-md-3 mb-2">
                              <Label aria-required>Email Address</Label>
                              <CustomInput
                                 type="email"
                                 className="form-control"
                                 placeholder="Email Address"
                                 name="username"
                                 value={formik.values.username}
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                              />
                              {formik.touched.username && formik.errors.username ? (
                                 <div className="error">{formik.errors.username}</div>
                              ) : null}
                           </Col>
                           <Col className="col-12 mx-auto mb-md-3 mb-2">
                              <Label aria-required>Password</Label>

                              <CustomInput
                                 type='password'
                                 className="form-control"
                                 placeholder="Password"
                                 name="password"
                                 value={formik.values.password}
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                              />
                              {formik.touched.password && formik.errors.password ? (
                                 <div className="error">{formik.errors.password}</div>
                              ) : null}
                           </Col>
                           <Col className="col-12 mx-auto mb-md-3 mb-2">
                              <div className="form-group">
                                 <Button size='lg' className='w-100 p-3' type="submit">
                                    {isLoading ? <BiLoader className='loader' /> : "Sign In"}

                                 </Button>
                              </div>
                           </Col>
                           <Col className="col-12 mx-auto mb-md-3 mb-2">
                              <Link className='text-center d-block text-dark' to={''}>Forgot Password?</Link>
                           </Col>
                        </Form>
                     </Row>
                  </Col>
               </Card.Body>
            </Card >
         </Col>
      </Row>
   )
}

import React, { useEffect } from "react";
import { fetchOrderStatus } from "../../redux/orderStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { MdAccessTime, MdDoneAll, MdOutlineCheck, MdOutlineInbox, MdOutlineLocalShipping, MdShoppingCartCheckout } from "react-icons/md";

const OrderStatusTracking = ({ currentStatus }) => {
  const dispatch = useDispatch();
  // Find the index of the current status

  const orderStatus = useSelector(
    (state) => state.orderStatus?.status?.data?.data
  );

  const statuslist = [
    {
      title: "Ordered",
      icon: (
        <>
          <MdShoppingCartCheckout />
        </>
      ),
    },
    {
      title: "Pending",
      icon: (
        <>
          <MdAccessTime />
        </>
      ),
    },
    {
      title: "Confirmed",
      icon: (
        <>
          <MdOutlineCheck />
        </>
      ),
    },
    {
      title: "Shipped",
      icon: (
        <>
          <MdOutlineLocalShipping />
        </>
      ),
    },
    {
      title: "Delivered",
      icon: (
        <>
          <MdOutlineInbox />
        </>
      ),
    },
    {
      title: "Delivery Accepted",
      icon: (
        <>
          <MdDoneAll />
        </>
      ),
    },
    // { title: "Cancelled", icon: <><MdAccessTime /></> },
    // { title: "Out for delivery", icon: <><MdAccessTime /></> },
    // {title:"Rejected",icon:<><MdAccessTime /></>}
  ];

  useEffect(() => {
    dispatch(fetchOrderStatus({ type: "manager" }));
  }, [dispatch]);

  return (
    <div>
      {/* <h2>Order Status Tracking</h2> */}
      <ul className="statusTracking">
        {statuslist.map((status, index) => (
          <li
            key={index}
            className={status.title === currentStatus ? "current-status" : ""}
          >
           <span className="icon">{ status?.icon}</span><span>{status.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderStatusTracking;

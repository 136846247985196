import React, { useEffect, useState } from 'react'
import Charts from '../../Components/Charts';
import { useSelector } from 'react-redux';

export default function MonthlySalesCharts() {

  const orderList =
    useSelector((state) => state?.order?.orders?.data?.data?.result) || [];
  
    const [monthCounts, setMonthCounts] = useState([]);

    useEffect(() => {
      const getMonthCounts = () => {
        const counts = {};
  
        orderList.forEach((order) => {
          const orderDate = new Date(order.created_at);
          const monthYearKey = `${orderDate.getFullYear()}-${orderDate.getMonth() + 1}`;
  
          if (counts[monthYearKey]) {
            counts[monthYearKey]++;
          } else {
            counts[monthYearKey] = 1;
          }
        });
  
        const countsArray = Object.values(counts);
        setMonthCounts(countsArray);
      };
  
      getMonthCounts();
    }, [orderList]);

  const lineChartOptions = {
    colors : ['#3CA5F2', '#48DA74'],
    chart: {
      id: 'basic-line'
    },
    fill: {
      opacity: 0.5
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    grid: { show: false },
  };

  const lineChartSeries = [
    {
      name: 'series-1',
      data: monthCounts
    },
    {
      name: 'series-2',
      data: monthCounts
    }
  ];

  console.log(monthCounts)
  
  return (
    <Charts type="area" options={lineChartOptions} series={lineChartSeries} height={350} />
  )
}

import React, { useState } from 'react'
import Pagelayout from '../../../Layouts/Pagelayout'
import { Badge, Button, Card, Modal } from 'react-bootstrap'
import CustomSelect from '../../../Components/CustomSelect'
import LTable from '../../../Components/LTable'
import Currency from '../../../Components/Currency'
import { AiFillFileText, AiOutlineFileText, AiOutlinePlus } from 'react-icons/ai'
import CustomDatePicker from '../../../Components/CustomDatePicker'
import Label from '../../../Components/Label'
import { Link } from 'react-router-dom'
import NewInventoryCount from './NewInventoryCount'

export default function StockAdjustment() {
   const [newInventoryCountForm, setShowNewInventoryCountForm] = useState(false);
   const handleCloseNewInventoryCountForm = () => setShowNewInventoryCountForm(false);
   const handleShowNewInventoryCountForm = () => setShowNewInventoryCountForm(true);

   return (
      <Pagelayout
         title="Inventory"
         breadcrumbs={
            [
               { title: 'Inventory', link: '/inventory' },
               { title: 'Stock Adjustment', link: '/inventory/stock-adjustment' },
            ]
         }>
         <Card>
            <Card.Body>
               <div className='table_filter equal_space'>
                  <div className='table_filter_item align-items-center w-100'>
                     <Label>Type - </Label>  <label className='me-3'><input type="checkbox" name="type" /> Delivery note / Purchase invoice</label>
                     <label className='me-3'><input type="checkbox" name="type" /> Purchase order</label>
                     <label className='me-3'><input type="checkbox" name="type" /> Credit invoice</label>
                  </div>
                  <div className='table_filter_item align-items-center'>
                     <Label>Start</Label>
                     <CustomDatePicker
                        name="dob"
                        className="form-control"
                        // value={formik.values.dob}
                        onChange={(value) => console.log(value)}
                     // onBlur={formik.handleBlur}
                     />
                  </div>
                  <div className='table_filter_item align-items-center'>
                     <Label>End</Label> <CustomDatePicker
                        name="dob"
                        className="form-control"
                        // value={formik.values.dob}
                        onChange={(value) => console.log(value)}
                     // onBlur={formik.handleBlur}
                     />
                  </div>

                  <div className='table_filter_item'>
                     <CustomSelect
                        placeholder="Select Store"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[]}
                     />
                  </div>

                  <div className='table_filter_item'>
                     <CustomSelect
                        placeholder="Document Type"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[]}
                     />
                  </div>

                  <div className='table_filter_item'>
                     <Button className='w-100' size='lg' onClick={handleShowNewInventoryCountForm}><AiOutlinePlus /> New Inventory Count</Button>
                  </div>
                  <div className='table_filter_item'>
                     <Button className='w-100' variant='dark' size='lg'><AiOutlinePlus /> New Cost Adjustment</Button>
                  </div>
               </div>
               <LTable>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>Document Type</th>
                        <th>Status</th>
                        <th>Store</th>
                        <th>Difference in quantity</th>
                        <th>Price Difference</th>
                        <th>Created</th>
                        <th>Accepted</th>
                        <th>Comment</th>
                        <th>Created By</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>
                        <td>Single product quantity adjustment</td>
                        <td><Badge bg="success">Success</Badge></td>
                        <td>Store name</td>
                        <td>-1.00</td>
                        <td>-20.00</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>Online dispatch</td>
                        <td>Enow Enow</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>

                        <td>Single product quantity adjustment</td>

                        <td><Badge bg="success">Success</Badge></td>
                        <td>Store name</td>
                        <td>-1.00</td>
                        <td>-20.00</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>Online dispatch</td>
                        <td>Enow Enow</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-danger' /></td>

                        <td>Single product quantity adjustment</td>

                        <td><Badge bg="success">Success</Badge></td>
                        <td>Store name</td>
                        <td>-1.00</td>
                        <td>-20.00</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>Online dispatch</td>
                        <td>Enow Enow</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>

                        <td>Single product quantity adjustment</td>

                        <td><Badge bg="success">Success</Badge></td>
                        <td>Store name</td>
                        <td>-1.00</td>
                        <td>-20.00</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>Online dispatch</td>
                        <td>Enow Enow</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-danger' /></td>

                        <td>Single product quantity adjustment</td>

                        <td><Badge bg="success">Success</Badge></td>
                        <td>Store name</td>
                        <td>-1.00</td>
                        <td>-20.00</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>Online dispatch</td>
                        <td>Enow Enow</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>

                        <td>Single product quantity adjustment</td>

                        <td><Badge bg="success">Success</Badge></td>
                        <td>Store name</td>
                        <td>-1.00</td>
                        <td>-20.00</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>Online dispatch</td>
                        <td>Enow Enow</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>

                        <td>Single product quantity adjustment</td>

                        <td><Badge bg="success">Success</Badge></td>
                        <td>Store name</td>
                        <td>-1.00</td>
                        <td>-20.00</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>Online dispatch</td>
                        <td>Enow Enow</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>

                        <td>Single product quantity adjustment</td>

                        <td><Badge bg="success">Success</Badge></td>
                        <td>Store name</td>
                        <td>-1.00</td>
                        <td>-20.00</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>20, Nov. 2023, 11:58</td>
                        <td>Online dispatch</td>
                        <td>Enow Enow</td>
                     </tr>

                  </tbody>
               </LTable>
            </Card.Body>
         </Card>

         <Modal show={newInventoryCountForm} onHide={handleShowNewInventoryCountForm}>
            <Modal.Header closeButton>
               <Modal.Title>New Inventory Count</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <NewInventoryCount />
            </Modal.Body>
         </Modal>

      </Pagelayout >

   )
}

// courierCompaniesSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

// Async thunk for fetching courier companies list
export const fetchCourierCompanies = createAsyncThunk(
  "courierCompanies/fetchList",
  async (pageNo) => {
    try {
      const response = await doAPI.getData(
        `logistics-company/list?page_no=${pageNo}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a courier company
export const addCourierCompany = createAsyncThunk(
  "courierCompanies/add",
  async (companyData) => {
    try {
      const response = await doAPI.postData(
        "logistics-company/add",
        companyData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a courier company
export const updateCourierCompany = createAsyncThunk(
  "courierCompanies/update",
  async (companyData) => {
    try {
      const response = await doAPI.putData(
        "logistics-company/update",
        companyData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Initial state
const initialState = {
  courierCompanies: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  response: null,
};

// Create a slice
const courierCompaniesSlice = createSlice({
  name: "courierCompanies",
  initialState,
  reducers: {
    clearResponse: (state) => {
      state.response = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourierCompanies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCourierCompanies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.courierCompanies = action.payload;
      })
      .addCase(fetchCourierCompanies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addCourierCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCourierCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(addCourierCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.response = action.payload;
      })
      .addCase(updateCourierCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCourierCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(updateCourierCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.response = action.payload;
      });
  },
});
export const { clearResponse } = courierCompaniesSlice.actions;

export default courierCompaniesSlice.reducer;

import React, { useEffect, useState } from 'react'
import Service from '../../../http';
import { useDispatch, useSelector } from 'react-redux';
import Notification, { notify } from '../../../Components/Notification';
import { Card, Dropdown, Modal } from 'react-bootstrap';
import Pagelayout from '../../../Layouts/Pagelayout';
import LTable from '../../../Components/LTable';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';
import EditVatSetting from '../EditVatSetting';
import { fetchPriceAction, fetchPriceActionList } from '../../../redux/priceActionSlice';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { tableSerialNumber } from '../../../Components/Utils';

export default function PriceMovementSetting() {

  const dispatch = useDispatch()
  const priceAction = useSelector(state => state.priceAction?.priceAction)

  useEffect(() => {
    dispatch(fetchPriceAction())
  }, [dispatch]);

  return (
    <Pagelayout
      title="Price Movement"
      breadcrumbs={
        [
          { title: 'Dashboard', link: '/dashboard' },
          { title: 'Price Movement', link: '#' },
        ]
      }
    // actions={<Button onClick={() =>handleShowSettingEditForm()}>Add VAT Setting</Button>}
    >
      <Card>
        <Card.Body>
          <LTable isLoading={priceAction.length == 0}>
            <thead>
              <tr>
                <th>#</th>
                <th>Price Gram</th>
                <th>Piece Action</th>
                <th>Piece Percentage</th>
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              {priceAction.map((i, index) => <tr key={index}>
              <td>{tableSerialNumber(index, priceAction.cur_page, priceAction?.row.length)}</td>
                <td>{i.price_gram}g</td>
                <td> {i.piece_action == 0 ? <span className='text-success'><AiOutlineArrowUp /> Increment</span> : <span className='text-danger'><AiOutlineArrowDown /> Decrement</span>}</td>
                <td>{i.piece_percentage}%</td>
                <td>{i.currency?.name}</td>
              </tr>)}
            </tbody>
          </LTable>
        </Card.Body>
      </Card>




    </Pagelayout>

  )
}

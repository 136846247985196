import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../Components/Utils';

const initialState = {
  theme: null,
  loading: false,
  error: null,
};

export const fetchTheme = createAsyncThunk('themeApi/getTheme', async () => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/theme/list`);
  const data = await response.json();
  localStorage.setItem('theme', JSON.stringify(data))
  return data;
});

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTheme.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTheme.fulfilled, (state, action) => {
        state.theme = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchTheme.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectTheme = (state) => state.theme.theme;
export const selectLoading = (state) => state.theme.loading;
export const selectError = (state) => state.theme.error;

export default themeSlice.reducer;

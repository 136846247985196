import React, { useEffect, useState } from 'react'
import Notification, { notify } from '../../Components/Notification'
import { Button, Col, Row, Form, Card, Dropdown, Modal } from 'react-bootstrap'
import Label from '../../Components/Label'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import Service from '../../http'
import CustomInput from '../../Components/CustomInput'
import { useDispatch, useSelector } from 'react-redux'
import { BiDotsVerticalRounded, BiLoader } from 'react-icons/bi'
import Pagelayout from '../../Layouts/Pagelayout'
import LTable from '../../Components/LTable'
import { MdDelete, MdEdit } from 'react-icons/md'
import { fetchTheme } from '../../redux/themeSlice'
import Image from '../../Components/Image'
import AddTheme from './AddTheme';
import EditTheme from './EditTheme'
import Swal from 'sweetalert2'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import RatingStars from '../../Components/RatingStars';
import { tableSerialNumber } from '../../Components/Utils'

export default function Theme() {
   const services = new Service();
   const dispatch = useDispatch()

   const [themeData, setThemeData] = useState()
   const handleFormSubmitSuccess = () => {
      setShowThemeEditForm(false)
      setShowThemeAddForm(false)
      dispatch(fetchTheme())
   }

   const [ThemeAddForm, setShowThemeAddForm] = useState(false);
   const handleCloseThemeAddForm = () => setShowThemeAddForm(false);
   const handleShowThemeAddForm = () => setShowThemeAddForm(true);


   const [ThemeEditForm, setShowThemeEditForm] = useState(false);
   const handleCloseThemeEditForm = () => setShowThemeEditForm(false);
   const handleShowThemeEditForm = (data) => {
      setThemeData(data)
      setShowThemeEditForm(true)
   };

   const theme = useSelector(state => state.theme)
   useEffect(() => {
      dispatch(fetchTheme())
   }, [dispatch])

   console.log(theme?.theme?.data)

   const handleDeleteTheme = (themeId) => {

      const options = {
         title: 'Delete Themes',
         text: 'Are you sure you want to delete this themes?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, delete it!',
      };

      // Use Swal.fire to show the SweetAlert dialog
      Swal.fire(options).then((result) => {
         if (result.isConfirmed) {
            // User confirmed, proceed with deletion
            services
               .put('theme/delete/' + themeId)
               .then((res) => {
                  if (res.status) {
                     notify(res.message);
                     dispatch(fetchTheme());
                  } else {
                     notify(res.message[0]?.message);
                  }
               })
               .catch((error) => {
                  console.error('Error - ', error);
               });
         }
      });
   }
   return (
      <Pagelayout
         title="Theme"
         breadcrumbs={
            [
               { title: 'Dashboard', link: '/dashboard' },
               { title: 'Theme list', link: '#' },
            ]
         }
         actions={<Button onClick={() => handleShowThemeAddForm()}>Add Theme</Button>}
      >
         <Card>
            <Card.Body>
               <LTable isLoading={theme.status == 'pending'}>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Primary</th>
                        <th>Secondary</th>
                        <th>Background</th>
                        <th>Action</th>
                     </tr>
                  </thead>
                  <tbody>
                     {theme?.theme?.data && theme?.theme?.data.map((i, index) => <tr key={index}>
                        <td>{tableSerialNumber(index, theme?.theme?.data.cur_page, theme?.theme?.data.length)}</td>
                        <td> {i.theme_name}</td>
                        <td> <div className='d-flex'><div style={{ height: 20, width: 20, borderRadius: 5, border: '1px solid #ddd', background: i.primary }} /> &nbsp; {i.primary}</div></td>
                        <td> <div className='d-flex'><div style={{ height: 20, width: 20, borderRadius: 5, border: '1px solid #ddd', background: i.secondary }} /> &nbsp; {i.secondary}</div></td>
                        <td> <div className='d-flex'><div style={{ height: 20, width: 20, borderRadius: 5, border: '1px solid #ddd', background: i.background }} /> &nbsp; {i.background}</div></td>
                        <td>
                           <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                 <BiDotsVerticalRounded />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                 <Dropdown.Item onClick={() => handleShowThemeEditForm(i)}><MdEdit /> Edit</Dropdown.Item>
                                 <Dropdown.Item onClick={() => handleDeleteTheme(i.id)}><MdDelete /> Delete</Dropdown.Item>
                              </Dropdown.Menu>
                           </Dropdown>
                        </td>
                     </tr>)}
                  </tbody>
               </LTable>
            </Card.Body>
         </Card>


         <Modal show={ThemeEditForm} onHide={handleCloseThemeEditForm}>
            <Modal.Header closeButton>
               <Modal.Title>Edit Theme</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <EditTheme onFormSubmitSuccess={handleFormSubmitSuccess} data={themeData} />
            </Modal.Body>
         </Modal>

         <Modal show={ThemeAddForm} onHide={handleCloseThemeAddForm}>
            <Modal.Header closeButton>
               <Modal.Title>Add Theme</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <AddTheme onFormSubmitSuccess={handleFormSubmitSuccess} />
            </Modal.Body>
         </Modal>
      </Pagelayout>
   )
}


import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import LTable from '../../../Components/LTable';
import CustomSelect from '../../../Components/CustomSelect';
import { Button, Modal } from 'react-bootstrap';
import Configuration from './Configuration';
import { AiOutlineUpload } from 'react-icons/ai';

function ExcelReader() {
  const [excelData, setExcelData] = useState([]);
  const [fileName, setFileName] = useState(""); // State to store the file name


  const [configurationForm, setShowConfigurationForm] = useState(false);
  const handleCloseConfigurationForm = () => setShowConfigurationForm(false);
  const handleShowConfigurationForm = () => setShowConfigurationForm(true);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);

      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setExcelData(excelData);

      // Set the file name in state
      setFileName(file.name);
    };

    reader.readAsArrayBuffer(file);
  };
  return (
    <div>


      <div className='table_filter equal_space'>
        <div className='table_filter_item'>
          <input className='form-control' placeholder='search' type='search' />
          <input className='form-control' type='date' />
          <div className='fileImporter'>
            <AiOutlineUpload /> Upload
            <input type="file" accept=".xlsx,.csv" onChange={handleFileUpload} />
          </div>
        </div>
        <div className='table_filter_item ms-auto'>
          <CustomSelect
            placeholder="Select Import Type"
            //   value={formik.values.country}
            onChange={(value) =>
              console.log(value)
            }
            options={[{ label: 'Product', value: 1 }, { label: 'Delivery note', value: 2 }]}
          />
          <Button size='lg' onClick={handleShowConfigurationForm}>Configuration</Button>
        </div>
      </div>

      <LTable>
        {excelData.length > 0 && (
          <thead>
            <tr>
              {excelData[0].map((i, index) => <th key={index}>{i}</th>)}
            </tr>
          </thead>
        )}
        <tbody>
          {excelData.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </LTable>

      <Modal show={configurationForm} onHide={handleCloseConfigurationForm}>
        <Modal.Header closeButton>
          <Modal.Title>Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Configuration />
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default ExcelReader;

import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Label from '../../../Components/Label'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import doAPI from '../../../api';
import { useState } from 'react';
import Notification, { notify } from '../../../Components/Notification';
import CustomInput from '../../../Components/CustomInput';
import { useSelector } from 'react-redux';
import { BiLoader } from 'react-icons/bi';

export default function AddConsignment({ onFormSubmitSuccess }) {
   const selectedCurrency = useSelector((state) => state.property.selectedCurrency)?.id;

   const currency = useSelector((state) => state.currency)?.currencylist?.data;

   const [isLoading, setIsLoading] = useState(false)
   const NewProductSizeSchema = Yup.object().shape({
      stock_cost: Yup.number().required('Consignment Cost is required'),
      stock_price: Yup.number().required('Consignment Price is required'),
      stock_weight: Yup.number().required('Consignment Weight is required'),
      currency_id: Yup.number().required('Currency is required'),

   });

   const formik = useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
         stock_cost: null,
         stock_price: null,
         stock_weight: null,
         currency_id: 1
      },
      onSubmit: (values, { resetForm }) => {
         setIsLoading(true)
         doAPI.postData('setting/add-stock', values).then((res) => {
            setIsLoading(true)
            if (res.status) {
               notify(res.data.message);
               setTimeout(() => {
                  onFormSubmitSuccess();
               }, 1000);
               setIsLoading(false)
               resetForm()
            } else {
               notify(res.message[0]?.message[0]?.message, { variant: 'error' });
            }
         })
      },
      validationSchema: NewProductSizeSchema
   });

   return (
      <Form onSubmit={formik.handleSubmit}>
         <Notification />
         <Row>
            <Col><em className='text-danger'>You can record the stock of Silver when it is bought (Bulk Purchase)</em></Col>
            <Col md={12} className='mb-3'>
               <Label aria-required>Total Consignment Cost</Label>
               <CustomInput type='number' name="stock_cost" placeholder='Consignment Cost' className='form-control'
                  value={formik.values.stock_cost}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.stock_cost && formik.errors.stock_cost ? (
                  <div className="error">{formik.errors.stock_cost}</div>
               ) : null}
            </Col>

            <Col md={12} className='mb-3'>
               <Label aria-required>Consignment Price (Per Kg)</Label>
               <CustomInput type='number' name="stock_price" placeholder='Consignment Price' className='form-control'
                  value={formik.values.stock_price}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.stock_price && formik.errors.stock_price ? (
                  <div className="error">{formik.errors.stock_price}</div>
               ) : null}
            </Col>

            <Col md={12} className='mb-3'>
               <Label aria-required>Consignment Weight (Kg)</Label>
               <CustomInput type='number' name="stock_weight" placeholder='Consignment Weight' className='form-control'
                  value={formik.values.stock_weight}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.stock_weight && formik.errors.stock_weight ? (
                  <div className="error">{formik.errors.stock_weight}</div>
               ) : null}
            </Col>

            <Col md={12} className='mb-3'>
               <Label aria-required>Select Currency</Label>
               <select name="currency_id" onChange={(event) => formik.setFieldValue('currency_id', parseInt(event.target.value))}
                  className='form-control'>
                  <option value={null}>Select Currency</option>
                  {currency.map((i, index) => <option key={index} selected={i.id == selectedCurrency} value={i.id}>{i.name}</option>)}
               </select>
               {formik.touched.currency_id && formik.errors.currency_id ? (
                  <div className="error">{formik.errors.currency_id}</div>
               ) : null}
            </Col>

            <Col md={12} className='mb-3'>
               <Button type="submit"
                  size='lg'
                  className="px-md-5 w-100"
                  variant="primary">
                  {isLoading ? <BiLoader className='loader' /> : "Submit"}
               </Button>
            </Col>
         </Row>
      </Form>
   )
}

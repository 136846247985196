import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import RouteFile from './Routes/RouteFile';
import './styles/main.css'
import { resetUserState } from './redux/loginSlice'; // Import your userSlice action
import { useDispatch } from 'react-redux';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'RESET_USER_STATE_FROM_LOCAL_STORAGE' });
  }, [dispatch]);
  return <RouteFile />
}

export default App;

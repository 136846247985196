import React from "react";
import Select from "react-select";
export default function CustomSelect(props) {

  const {
    placeholder,
    onChange,
    options,
    value,
    isMulti,
    defaultValue_,
    field,
    className,
    isDisabled,
    onInputChange
  } = props

  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : "";
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: 'var(--color5)',
      minHeight: '43px',
      height: '43px',
      width: className === 'w-100' ? '100%' : 250,

      // padding: '6px 12px',
      // backgroundColor: '#eee',
      backgroundImage: 'none',
      border: '1px solid #ccc',
      // border
      borderRadius: '4px',
      boxShadow: 'inset 0 1px 1px rgba(var(--color8),var(--alpha7))',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
      boxShadow: state.isFocused ? null : null,
    }),
    option: (base, state) => ({
      ...base,
      // backgroundColor: state.isSelected ? theme?.primary : themeContrast(theme?.primary),
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: '43px',
      // padding: '0 6px'
    }),

    // input: (provided, state) => ({
    //   ...provided,
    //   margin: '0px',
    // }),
    // indicatorSeparator: state => ({
    //   display: 'none',
    // }),
    // indicatorsContainer: (provided, state) => ({
    //   ...provided,
    //   height: '30px',
    // }),
  };

  const customFilter = (option, rawInput) => {
    const label = option.label.toLowerCase();
    const input = rawInput.toLowerCase();

    // Customize the filtering logic based on your requirements
    return label.includes(input);
  };

  return (
    <div className="react_select">
      <Select
        {...field}
        isDisabled={isDisabled}
        styles={customStyles}
        className={className}
        placeholder={placeholder}
        isMulti={isMulti}
        value={defaultValue(options, value)}
        onChange={(value) => onChange(value)}
        options={options}
        filterOption={customFilter} // Set the custom filter function
        onInputChange={onInputChange}
      ></Select>
    </div>
  );
}
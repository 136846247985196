// Chart.js
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const Charts = ({ type, options, series, height }) => {
  return (
    <div>
      <ReactApexChart options={options} series={series} type={type} height={height} />
      <style jsx>{`
        .apexcharts-zoom-icon,
        .apexcharts-pan-icon,
        .apexcharts-zoomin-icon,
        .apexcharts-zoomout-icon {
          display: none;
        }
        .apexcharts-toolbar {
          top:-48px !important
        }
      `}</style>
    </div>
  );
};

export default Charts;

import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrencyList } from '../redux/currencySlice';

export default function Currency({ currency }) {

   const dispatch = useDispatch()

   const selectedCurrency = useSelector((state) => state.property.selectedCurrency);

   const currencylist = useSelector((state) => state.currency?.currencylist?.data);

   useEffect(() => {
      dispatch(fetchCurrencyList());
   }, [dispatch]);

   return (
      <span className='d-inline-block currency'>{!currency ? selectedCurrency?.symbol : currencylist.find(i=>i.id==currency).symbol}</span>
   )
}

import React, { useEffect, useState } from 'react';
import Pagelayout from '../../Layouts/Pagelayout';
import { Badge, Button, Card, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import LTable from '../../Components/LTable';
import CustomSelect from '../../Components/CustomSelect';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { MdDelete, MdEdit, MdOutlineHistory } from 'react-icons/md';
import { HiOutlineNewspaper } from 'react-icons/hi';
import Currency from '../../Components/Currency';
import AddCoupon from './AddCoupon';
import EditCoupon from './EditCoupon';
import { AiOutlinePlus } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCoupons } from '../../redux/couponSlice';
import { formatDate, tableSerialNumber } from './../../Components/Utils';
import Service from '../../http';
import Notification, { notify } from '../../Components/Notification';
import CustomInput from '../../Components/CustomInput';
import Pagination from '../../Components/Pagination';
import CustomDatePicker from '../../Components/CustomDatePicker';

export default function CouponList() {

   const services = new Service();
   const dispatch = useDispatch();
   const { coupons, loading, error } = useSelector((state) => state.coupons);

   const [searchQuery, setSearchQuery] = useState('')
   const [page, setPage] = useState(1)
   const [couponId, setCouponId] = useState()

   const [CouponAddForm, setShowCouponAddForm] = useState(false);
   const handleCloseCouponAddForm = () => setShowCouponAddForm(false);
   const handleShowCouponAddForm = () => setShowCouponAddForm(true);

   const [CouponEditForm, setShowCouponEditForm] = useState(false);
   const handleCloseCouponEditForm = () => setShowCouponEditForm(false);
   const handleShowCouponEditForm = (id) => {
      setCouponId(id)
      setShowCouponEditForm(true)
   };


   useEffect(() => {
      dispatch(fetchCoupons({ page: page, search: searchQuery }));
   }, [dispatch, searchQuery, page]);

   const handleStatusChange = (couponId, isActive) => {
      console.log({ coupon_id: couponId, is_active: isActive == 1 ? 0 : 1 })
      services &&
         services.put("coupon/change-status", {
            coupon_id: couponId,
            is_active: isActive == 1 ? 0 : 1
         })
            .then((res) => {
               if (res.status) {
                  notify(res.message);
                  dispatch(fetchCoupons({ page: page, search: searchQuery }));
               } else {
                  notify(res.message[0]?.message);
               }
            })
            .catch((error) => {
               console.error('Error - ', error);
            });
   };

   const searchName = (event) => {
      setSearchQuery(event.target.value)
   }

   const handlePageChange = (newPage) => {
      setPage(parseInt(newPage));
   };

   return (
      <Pagelayout
         title="Coupon"
         breadcrumbs={
            [
               { title: 'Dashboard', link: '/dashboard' },
               { title: 'Coupon', link: '/coupon/list' },
            ]
         }
         actions={
            <Button onClick={handleShowCouponAddForm}><AiOutlinePlus /> Add Coupon</Button>
         }>
         <Notification />
         <Card>
            <Card.Body>
               <div className='table_filter equal_space'>
                  <div className='table_filter_item'>
                     <CustomInput
                        className="form-control input-sm"
                        onChange={(event) => searchName(event)}
                        type="search"
                        placeholder="Search name..."
                     />
                  </div>
                  <div className='table_filter_item'>
                     <CustomDatePicker
                        name="date"
                        className="form-control"
                     // value={formik.values.date}
                     // onChange={(value) => formik.setFieldValue('date', value)}
                     // onBlur={formik.handleBlur}
                     />
                  </div>
                  <div className='table_filter_item ms-auto'>
                     <CustomSelect
                        placeholder="Select Coupon Type"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[{ label: 'Percentage Discount', value: 1 }, { label: 'Fixed Card Discount', value: 2 }]}
                     />
                  </div>
               </div>

               <LTable isLoading={loading}>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Valid From</th>
                        <th>Valid Till</th>
                        <th>Discount Type</th>
                        <th>Maximum Use</th>
                        <th>Status</th>
                        <th>Action</th>
                     </tr>
                  </thead>
                  <tbody>
                     {coupons.rows !== undefined && coupons.rows.map((i, index) =>
                        <tr key={index}>
                           <td>{tableSerialNumber(index, coupons.cur_page, coupons.rows.length)}</td>
                           <td>{i.code}</td>
                        <td>{formatDate(i.valid_from)}</td>
                           <td>{formatDate(i.valid_to)}</td>
                           <td>{i.discount} {i.discount_type === 0 ? '%' : <Currency />}</td>
                           <td>{i.maximum_used} Times</td>
                           <td>
                              <label
                                 onClick={() => handleStatusChange(i.id, i.is_active)}
                                 className={i.is_active === 1 ? 'custom_check active' : 'custom_check'}
                              >
                                 <div className='custom_check_wrapper'>
                                    <span></span>
                                    <label>
                                       {i.is_active === 1 ? 'Active' : 'Deactivated'}
                                    </label>
                                 </div>
                              </label>
                           </td>
                           <td>
                              <Dropdown>
                                 <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <BiDotsVerticalRounded />
                                 </Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleShowCouponEditForm(i.id)}><MdEdit /> Edit</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2"><MdDelete /> Delete</Dropdown.Item>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </td>
                        </tr>
                     )}
                  </tbody>
               </LTable>
               <Pagination pagecount={coupons.total_pages} currentpage={page} onPageChange={handlePageChange} />
            </Card.Body>
         </Card>

         <Modal show={CouponAddForm} onHide={handleCloseCouponAddForm}>
            <Modal.Header closeButton>
               <Modal.Title>Add Coupon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <AddCoupon onSuccess={() => setShowCouponAddForm(false)} />
            </Modal.Body>
         </Modal>

         <Modal show={CouponEditForm} onHide={handleCloseCouponEditForm}>
            <Modal.Header closeButton>
               <Modal.Title>Edit Coupon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <EditCoupon couponId={couponId} onSuccess={() => setShowCouponEditForm(false)} />
            </Modal.Body>
         </Modal>
      </Pagelayout>
   )
}

import React, { useEffect, useState } from 'react'
import Service from '../../../http';
import { useDispatch, useSelector } from 'react-redux';
import Notification, { notify } from '../../../Components/Notification';
import { Button, Card, Dropdown, Modal } from 'react-bootstrap';
import Pagelayout from '../../../Layouts/Pagelayout';
import LTable from '../../../Components/LTable';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { MdDelete, MdEdit } from 'react-icons/md';
import { fetchCurrentConsignment } from '../../../redux/consignmentSlice';
import { formatDate } from '../../../Components/Utils';
import AddConsignment from './AddConsignment';

export default function CurrentConsignment() {
   const services = new Service();

   const dispatch = useDispatch()
   const consignmentlist = useSelector(state => state.consignmentlist)

   useEffect(() => {
      dispatch(fetchCurrentConsignment())
   }, [dispatch])

   const [ConsignmentAddForm, setShowConsignmentAddForm] = useState(false);
   const handleCloseConsignmentAddForm = () => setShowConsignmentAddForm(false);
   const handleShowConsignmentAddForm = () => setShowConsignmentAddForm(true);

   const handleFormSubmitSuccess = () => {
      handleCloseConsignmentAddForm(false)
   }

   console.log({ consignmentlist })
   return (
      <Pagelayout
         title="Consignment List"
         breadcrumbs={
            [
               { title: 'Dashboard', link: '/dashboard' },
               { title: 'Consignment List', link: '#' },
            ]
         }
      // actions={<Button onClick={() => handleShowConsignmentAddForm()}>Add Consignment</Button>}
      >
         <Card>
            <Card.Body>
               <LTable
                  isLoading={consignmentlist.status == 'pending'}
               >
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>Consignment Title</th>
                        <th>Consignment cost</th>
                        <th>Consignment price</th>
                        <th>Consignment weight</th>
                        <th>Currency</th>
                        <th>Date</th>
                     </tr>
                  </thead>
                  <tbody>
                     {consignmentlist?.currentConsignment && [consignmentlist?.currentConsignment].map((i, index) => <tr key={index}>
                        <td>{i.stock_title}</td>
                        <td>{i.stock_price}</td>
                        <td>{i.stock_price}</td>
                        <td>{i.stock_weight}</td>
                        <td>{i.currency?.name}</td>
                        <td>{formatDate(i.updated_at)}</td>

                     </tr>)}
                  </tbody>
               </LTable>
            </Card.Body>
         </Card>


         <Modal show={ConsignmentAddForm} onHide={handleCloseConsignmentAddForm}>
            <Modal.Header closeButton>
               <Modal.Title>Add Consignment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <AddConsignment onFormSubmitSuccess={() => {
                  setShowConsignmentAddForm(false)
                  dispatch(fetchCurrentConsignment())
               }} />
            </Modal.Body>
         </Modal>

      </Pagelayout>

   )
}

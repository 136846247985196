import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Pagelayout from '../../../Layouts/Pagelayout';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import Notification, { notify } from '../../../Components/Notification';
import Label from '../../../Components/Label';
import CustomInput from '../../../Components/CustomInput';
import Currency from './../../../Components/Currency';
import Service from '../../../http';
import doAPI from '../../../api';
import LTable from '../../../Components/LTable';
import { useSelector } from 'react-redux';
import { AiOutlineArrowDown, AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineArrowUp } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime, logoutApp, tableSerialNumber } from '../../../Components/Utils';
import Pagination from './../../../Components/Pagination';

export default function UpdatePrice() {
   const services = new Service();
   const [gramList, setGramList] = useState([]);
   const [prices, setPrices] = useState([]);
   const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
   const [pageCount, setPageCount] = useState()
   const currency = useSelector((state) => state.currency)?.currencylist?.data;
   const [setPriceList, priceList] = useState([])
   const [page, setPage] = useState(10)
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   useEffect(() => {

      services.get('master/list-gram').then((res) => {
         setGramList(res.data.rows);
      });
   }, []);

   const formik = useFormik({
      initialValues: {
         prices: gramList.map((item) => ({
            master_gram_id: item.id,
            price_gram_thb: 0,
            piece_action_thb: 0,
            piece_percentage_thb: 0,
            price_gram_usd: 0,
            piece_action_usd: 0,
            piece_percentage_usd: 0,
         })),
      },
      onSubmit: (values) => {
         // Do something with the form values (values.prices)
         console.log(values);

         const pricesArray = values.prices.map((item) => ({
            master_gram_id: item.master_gram_id,
            price_gram_thb: parseFloat(item.price_gram_thb) || 0,
            piece_action_thb: item.piece_action_usd || 0,
            piece_percentage_thb: parseFloat(item.piece_percentage_thb) || 0,
            price_gram_usd: parseFloat(item.price_gram_usd) || 0,
            piece_action_usd: item.piece_action_usd || 0,
            piece_percentage_usd: parseFloat(item.piece_percentage_usd) || 0,
         }));

         doAPI.postData('setting/add-price', { prices: pricesArray }).then((res) => {
            if (res.status) {
               notify(res.data.message);
               handleClose()
               // navigate('/order/list')
            } else {
               console.log(res);
               notify(res.message[0]?.message);
            }
         })
            .catch((error) => {
               console.error('Error - ', error);
            });
      },
   });

   const groupDataByMasterGramId = (data) => {
      const groupedData = {};
      data.forEach((item) => {
         const { master_gram_id } = item;
         if (!groupedData[master_gram_id]) {
            groupedData[master_gram_id] = [];
         }
         groupedData[master_gram_id].push(item);
      });
      return groupedData;
   };

   useEffect(() => {
      services.get(`setting/price?page=${page}&year=${selectedYear}`).then((res) => {
         const groupedData = groupDataByMasterGramId(res.data.rows);
         setPageCount(res.data.count)
         const initialPrices = Object.values(groupedData).map((group) => ({
            master_gram_id: group[0].master_gram_id,
            price_gram_thb: group.find((item) => item.currency_id === 1)?.price_gram || 0,
            piece_action_thb: group.find((item) => item.currency_id === 1)?.piece_action || 0,
            piece_percentage_thb: group.find((item) => item.currency_id === 1)?.piece_percentage || 0,
            price_gram_usd: group.find((item) => item.currency_id === 2)?.price_gram || 0,
            piece_action_usd: group.find((item) => item.currency_id === 2)?.piece_action || 0,
            piece_percentage_usd: group.find((item) => item.currency_id === 2)?.piece_percentage || 0,
            created_at: group[0].created_at
         }));
         setPrices(initialPrices)
         formik.setValues({
            prices: Object.values(groupedData).map((group) => ({
               master_gram_id: group[0].master_gram_id,
               price_gram_thb: group.find((item) => item.currency_id === 1)?.price_gram || 0,
               piece_action_thb: group.find((item) => item.currency_id === 1)?.piece_action || 0,
               piece_percentage_thb: group.find((item) => item.currency_id === 1)?.piece_percentage || 0,
               price_gram_usd: group.find((item) => item.currency_id === 2)?.price_gram || 0,
               piece_action_usd: group.find((item) => item.currency_id === 2)?.piece_action || 0,
               piece_percentage_usd: group.find((item) => item.currency_id === 2)?.piece_percentage || 0,
            }))
         });
      });
   }, [selectedYear, page]);



   const handlePreviousPage = () => {
      if (page > 1) {
         setPage(page - 1);
      }
   };

   const handleNextPage = () => {
      if (page < pageCount) {
         setPage(page + 1);
      }
   };


   const handleYearChange = (event) => {
      const selectedValue = parseInt(event.target.value, 10);
      setSelectedYear(selectedValue);
   };

   const currentYear = new Date().getFullYear();
   const lowestYear = 2023;
   const highestYear = currentYear + 1;

   const years = Array.from({ length: highestYear - lowestYear + 1 }, (_, index) => lowestYear + index);

   return (
      <Pagelayout
         title="Price Record"
         breadcrumbs={[
            { title: 'Dashboard', link: '/dashboard' },
            { title: 'Price Record', link: '#' },
         ]}
         actions={<div className='d-flex'>

            <select className='form-control' onChange={handleYearChange}>
               {years.map((year, index) => (
                  <option key={index} selected={year === currentYear}>
                     {year}
                  </option>
               ))}
            </select>
            &nbsp;
            <Button variant="primary" onClick={handleShow}>
               Add Record
            </Button>
            {/* <Button as={Link} variant="primary" to={'/price-movements'}>
               Price Movement
            </Button> */}
         </div>}
      >
         <Notification />

         <Card className="overflow-visible mb-3">
            <Card.Body>
               <LTable>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>Weight Range</th>
                        <th>Price Per Gram ฿ (THB)</th>
                        <th>Price Per Gram $ (USD)</th>
                        <th>Inc / Dec (Per Peice)</th>
                        <th>% Inc / Dec</th>
                        <th>Date</th>
                     </tr>
                  </thead>
                  <tbody>
                     {prices && prices.map((i, index) => <tr key={index}>
                        <td>{tableSerialNumber(index, gramList.cur_page, gramList.length)}</td>
                        <td>{gramList && gramList.find(j => j.id === i.master_gram_id)?.title || 'NA'}</td>
                        <td>฿ {i?.price_gram_thb.toFixed(2)}</td>
                        <td>$ {i?.price_gram_usd.toFixed(2)}</td>
                        <td>
                           {i?.piece_action_usd == 0 ? <span className='text-success'><AiOutlineArrowUp /> Increment</span> : <span className='text-danger'><AiOutlineArrowDown /> Decrement</span>}
                        </td>
                        <td>{i?.piece_percentage_usd} %</td>
                        <td>{formatDate(i.updated_at)}</td>
                     </tr>)}
                  </tbody>
               </LTable>

               <div className='pagination'>
                  <Col className='text-end my-3 d-flex justify-content-end align-items-center fw-bold'>
                     <Button size='sm' style={{ width: 80 }} className={page == 1 ? 'disabled_btn' : ''} onClick={handlePreviousPage}>
                        Previous
                     </Button>
                     <Label className="px-3">Price Records</Label>
                     <Button size='sm' style={{ width: 80 }} className={page == pageCount && 'disabled_btn'} onClick={handleNextPage}>
                        Next
                     </Button>
                  </Col>
               </div>

            </Card.Body>
         </Card>


         <Modal show={show} size='xl' onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Add Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>

               <Form onSubmit={formik.handleSubmit} className="updatePrice">
                  <Row className="align-items-center border-bottom border-top">
                     <Col md={2} xs={12} className='py-3'>
                        <Label>Weight Range</Label>
                     </Col >
                     <Col className='py-3'>
                        <Label>Price Per Gram ฿ (THB)</Label>
                     </Col>
                     <Col className='py-3'>
                        <Label>Price Per Gram $ (USD) </Label>
                     </Col>
                     <Col className='border-start py-3'>
                        <Label>Edit (Per Piece Price)</Label>
                     </Col>
                     <Col className='py-3'>
                        <Label>Percentage Value (%) </Label>
                     </Col>
                  </Row>
                  {gramList && gramList.map((item, index) => (
                     <Row key={index} className="align-items-center border-bottom">
                        <Col md={2} xs={12} className='py-3'>
                           <h6 className="theme-color fw-bold">{item.title}</h6>
                        </Col>
                        <Col className='py-3'>

                           <CustomInput
                              placeholder="0"
                              type="text"
                              value={formik.values?.prices[index]?.price_gram_thb}
                              className="form-control"
                              onChange={(e) => {
                                 formik.setFieldValue(`prices[${index}].price_gram_thb`, e.target.value)
                                 formik.setFieldValue(`prices[${index}].master_gram_id`, item.id)
                              }}
                           />
                        </Col>

                        <Col className='py-3'>

                           <CustomInput
                              placeholder="0"
                              type="text"
                              value={formik.values?.prices[index]?.price_gram_usd}
                              className="form-control"
                              onChange={(e) => formik.setFieldValue(`prices[${index}].price_gram_usd`, e.target.value)}
                           />
                        </Col>
                        <Col className='border-start py-3'>
                           <select className="form-control" value={formik.values?.prices[index]?.piece_action_usd}
                              onChange={(e) => formik.setFieldValue(`prices[${index}].piece_action_usd`, e.target.value)}
                           >
                              <option value={0}>Increment</option>
                              <option value={1}>Decrement</option>
                           </select>
                        </Col>
                        <Col>
                           <CustomInput
                              placeholder="0"
                              type="text"
                              value={formik.values?.prices[index]?.piece_percentage_usd}
                              className="form-control"
                              onChange={(e) => formik.setFieldValue(`prices[${index}].piece_percentage_usd`, e.target.value)}
                           />
                        </Col>
                     </Row>
                  ))}
                  <Row className="mb-3">
                     <div className="text-end">
                        <Button className="px-5" size="lg" type="submit">
                           Save
                        </Button>
                     </div>
                  </Row>
               </Form>
            </Modal.Body>
         </Modal>
      </Pagelayout>
   );
}

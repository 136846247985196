import React from 'react';
import axios from 'axios';
import { logoutApp } from './Components/Utils';

const headers = {
    Accept: '*',
    Accept: 'application/json',
    'Content-type': 'application/json',
    // 'X-Client-Domain': DOMAIN,
    // 'x-url-path': PATHNAME,
};

const token = () => {
    if (localStorage.getItem("token")) {
        let tokenVal = localStorage.getItem("token").replace(/['"]+/g, "");
        return "Bearer " + tokenVal;
    }
};

let baseUrl = process.env.REACT_APP_BASE_URL;

function joinurl(baseURL, url) {
    return `${baseURL}/${url}`;
}

class Service {
    constructor() {
        this.domain = baseUrl;
        // this.domain = getUrl()
    }

    componentDidUpdate(prevProps) {
        headers['x-url-path'] = window.location.pathname;
    }

    request() {
        return { ...headers, Authorization: token() };
    }

    post(url, data) {
        console.log(joinurl(this.domain, url));

        const method = 'POST';


        // Create a FormData object if the data contains a file
        const formData = data instanceof FormData ? data : new FormData();
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                if (data[key] instanceof File) {
                    // If the value is a File (indicating a file upload), append it to FormData
                    formData.append(key, data[key]);
                } else {
                    formData.append(key, data[key]);
                }
            }
        }

        const headers = {
            ...this.request(),
            // Conditionally set Content-Type to multipart/form-data if the data contains a file
            'Content-Type': data instanceof FormData ? undefined : 'application/json',
        };
        return axios
            .post(joinurl(this.domain, url), formData, { headers })
            .then((res) => {
                const { data = {} } = res;
                return data;
            })
            .catch((error) => {
                // logoutApp()
                return error;
            });
    }

    get(url, id) {
        if (id) {
            url = `${url}/${id}`;
        }
        return axios.get(joinurl(this.domain, url), { headers: this.request() }).then((res) => {
            const { data = {} } = res;
            // console.log(res)
            return data;
        }).catch((error) => {
            // logoutApp()
            if (error?.response?.statusText == 'Unauthorized') logoutApp(true)
            return error
        });;
    }

    delete(url, data) {
        return axios
            .delete(joinurl(this.domain, url), { headers: this.request() })
            .then((res) => {
                const { data = {} } = res;
                return data;
            })
            .catch((error) => error);
    }

    put(url, data) {
        return axios
            .put(joinurl(this.domain, url), data, { headers: this.request() })
            .then((res) => {
                const { data = {} } = res;
                return data;
            })
            .catch((error) => error);
    }
    getAll() { }
}
export default Service;

// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

// Define an initial state
const initialState = {
  users: [],
  loading: false,
  error: null,
};

// Define the async thunk for fetching user list
export const fetchUsers = createAsyncThunk(
  "users/fetchUserList",
  async ({ page = 1, role_id = "" }) => {
    try {
      const params = new URLSearchParams({ page, role_id }).toString();
      const data = await doAPI.getData(`user/list?${params}`);
      return data;
    } catch (error) {
      throw Error("Failed to fetch user list");
    }
  }
);

// Create the user slice
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


// Export the reducer
export default userSlice.reducer;

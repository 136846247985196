import React from 'react'
import { Badge, Button, Card } from 'react-bootstrap'
import Pagelayout from '../../../Layouts/Pagelayout'
import CustomDatePicker from '../../../Components/CustomDatePicker'
import Label from '../../../Components/Label'
import CustomSelect from '../../../Components/CustomSelect'
import LTable from '../../../Components/LTable'
import { Link } from 'react-router-dom'

export default function Transfer() {
   return (
      <Pagelayout
         title="Inventory"
         breadcrumbs={
            [
               { title: 'Inventory', link: '/inventory' },
               { title: 'Transfer', link: '/inventory/products/transfer' },
            ]
         }
         actions={<Link className='btn btn-primary' to='/inventory/transfer/new-document' size='lg'>New Document</Link>}
      >
         <Card>
            <Card.Body>
               <div className='table_filter equal_space'>
                  <div className='table_filter_item align-items-center'>
                     <Label>Start</Label>
                     <CustomDatePicker
                        name="dob"
                        className="form-control"
                        // value={formik.values.dob}
                        onChange={(value) => console.log(value)}
                     // onBlur={formik.handleBlur}
                     />
                  </div>
                  <div className='table_filter_item align-items-center'>
                     <Label>End</Label> <CustomDatePicker
                        name="dob"
                        className="form-control"
                        // value={formik.values.dob}
                        onChange={(value) => console.log(value)}
                     // onBlur={formik.handleBlur}
                     />
                  </div>
                  <div className='table_filter_item'>
                     <CustomSelect
                        placeholder="Select Store"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[]}
                     />
                  </div>
                  <div className='table_filter_item'>
                     <input
                        placeholder="Search"
                        className='form-control'
                        type='text'
                     />
                  </div>
               </div>
               <LTable>
                  <thead>
                     <tr>
                        <th>Document Number</th>
                        <th>Document Date</th>
                        <th>Status</th>
                        <th>Store From</th>
                        <th>Store To</th>
                        <th>Total</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'warning'} >Pending</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'warning'} >Pending</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'success'} >Success</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'success'} >Success</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'success'} >Success</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'success'} >Success</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'warning'} >Pending</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'success'} >Success</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'warning'} >Pending</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'warning'} >Pending</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                     <tr>
                        <td>CWTOBW1609</td>
                        <td>16 Nov. 2023</td>
                        <td><Badge bg={'warning'} >Pending</Badge></td>
                        <td>Central Warehouse</td>
                        <td>Blue Wafers store</td>
                        <td>0.00</td>
                     </tr>
                  </tbody>
               </LTable>
            </Card.Body>
         </Card>
      </Pagelayout>
   )
}

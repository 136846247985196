import React, { useEffect, useState } from "react";
import Notification, { notify } from "../../Components/Notification";
import { Button, Col, Row, Form } from "react-bootstrap";
import Label from "../../Components/Label";
import { useFormik } from "formik";
import * as Yup from "yup";
import Service from "../../http";
import CustomInput from "../../Components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { BiLoader } from "react-icons/bi";
import CustomSelect from "../../Components/CustomSelect";
import CustomDatePicker from "../../Components/CustomDatePicker";
import doAPI from "../../api";
import {
  fetchCitiesByState,
  fetchCountries,
  fetchStatesByCountry,
} from "../../redux/LocationSlice";

export default function AddAddress({ userId, onFormSubmitSuccess }) {
  const dispatch = useDispatch();

  const services = new Service();
  const currency = useSelector((state) => state.currency)?.currencylist?.data;
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState([]);

  const countries = useSelector((state) =>
    state.location.countries.map((country) => ({
      value: country.id,
      label: country.name,
    }))
  );
  const states = useSelector((state) =>
    state.location.states.map((state) => ({
      value: state.id,
      label: state.name,
    }))
  );
  const cities = useSelector((state) =>
    state.location.cities.map((city) => ({
      value: city.id,
      label: city.name,
    }))
  );

  const validationSchema = Yup.object().shape({
    first_name: Yup.mixed().required("First name is required"),
    last_name: Yup.mixed().required("Family name is required"),
    email: Yup.mixed().required("Email is required"),
    address_type: Yup.mixed().required("Address type is required"),
    phone_number: Yup.mixed().required("Phone number is required"),
    address_line_1: Yup.mixed().required("Address 1 is required"),
    address_line_2: Yup.mixed().required("Address 2 is required"),
    zip_code: Yup.mixed().required("Zip code is required"),
    country_id: Yup.mixed().required("Country is required"),
    state_id: Yup.mixed().required("State is required"),
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      address_line_1: "",
      address_line_2: "",
      zip_code: "",
      address_type: "",
      default: 0,
      user_id: userId,
      country_id: null,
      state_id: null,
      city_id: null,
    },
    onSubmit: (values, { resetForm }) => {
      doAPI.postData("user/add-address", values).then((res) => {
        setIsLoading(true);
        if (res.status) {
          notify(res.message);
          onFormSubmitSuccess();
          setIsLoading(false);
        } else {
          notify(res.message[0]?.message[0]?.message, { variant: "error" });
        }
      });
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  // useEffect to fetch states based on selected country
  useEffect(() => {
    if (formik.values.country_id !== null) {
      dispatch(fetchStatesByCountry(formik.values.country_id));
    }
  }, [dispatch, formik.values.country_id]);

  // useEffect to fetch cities based on selected state
  useEffect(() => {
    if (formik.values.state_id !== null) {
      dispatch(fetchCitiesByState(formik.values.state_id));
    }
  }, [dispatch, formik.values.state_id]);

  useEffect(() => {
    formik.setFieldValue("user_id", userId);
  }, [userId]);
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        {console.log(formik.values)}
        <Notification />
        <Row>
          <Col md={6} className="mb-2">
            <Label aria-required>Frist Name</Label>
            <CustomInput
              type="text"
              name="first_name"
              placeholder="First Name"
              className="form-control"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.first_name && formik.errors.first_name ? (
              <div className="error">{formik.errors.first_name}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Last Name</Label>
            <CustomInput
              type="text"
              name="last_name"
              placeholder="Last Name"
              className="form-control"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.last_name && formik.errors.last_name ? (
              <div className="error">{formik.errors.last_name}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Email</Label>
            <CustomInput
              type="email"
              name="email"
              placeholder="Email"
              className="form-control"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Phone Number</Label>
            <CustomInput
              type="number"
              name="phone_number"
              placeholder="Phone Number"
              className="form-control"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phone_number && formik.errors.phone_number ? (
              <div className="error">{formik.errors.phone_number}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Address Type</Label>
            <CustomSelect
              className="w-100"
              value={formik.values.address_type}
              onBlur={formik.handleBlur}
              form={formik}
              onChange={(value) =>
                formik.setFieldValue("address_type", value.value)
              }
              options={[
                { label: "Shipping Address", value: "S" },
                { label: "Billing Address", value: "B" },
              ]}
            />
            {formik.touched.address_type && formik.errors.address_type ? (
              <div className="error">{formik.errors.address_type}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Address 1</Label>
            <CustomInput
              type="text"
              name="address_line_1"
              placeholder="Address 1"
              className="form-control"
              value={formik.values.address_line_1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.address_line_1 && formik.errors.address_line_1 ? (
              <div className="error">{formik.errors.address_line_1}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Address 2</Label>
            <CustomInput
              type="text"
              name="address_line_2"
              placeholder="Address 2"
              className="form-control"
              value={formik.values.address_line_2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.address_line_2 && formik.errors.address_line_2 ? (
              <div className="error">{formik.errors.address_line_2}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Zip Code</Label>
            <CustomInput
              type="text"
              name="zip_code"
              placeholder="Zip Code"
              className="form-control"
              value={formik.values.zip_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.zip_code && formik.errors.zip_code ? (
              <div className="error">{formik.errors.zip_code}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label aria-required>Select Country</Label>
            <CustomSelect
              className="w-100"
              value={formik.values.country_id}
              onBlur={formik.handleBlur} // pass onBlur handler from Formik form
              form={formik} // pass the formik instance to CustomSelect
              onChange={(value) =>
                formik.setFieldValue("country_id", value.value)
              }
              options={countries}
            />
            {formik.touched.country_id && formik.errors.country_id ? (
              <div className="error">{formik.errors.country_id}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label aria-required>Select State</Label>
            <CustomSelect
              className="w-100"
              value={formik.values.state_id}
              onBlur={formik.handleBlur} // pass onBlur handler from Formik form
              form={formik} // pass the formik instance to CustomSelect
              onChange={(value) =>
                formik.setFieldValue("state_id", value.value)
              }
              options={states}
            />
            {formik.touched.state_id && formik.errors.state_id ? (
              <div className="error">{formik.errors.state_id}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label>Select City</Label>
            <CustomSelect
              className="w-100"
              value={formik.values.city_id}
              onBlur={formik.handleBlur} // pass onBlur handler from Formik form
              form={formik} // pass the formik instance to CustomSelect
              onChange={(value) => formik.setFieldValue("city_id", value.value)}
              options={cities}
            />
          </Col>

          <Col md={12} className="mb-2">
            <Button
              type="submit"
              size="lg"
              className="px-md-5 w-100"
              variant="primary"
            >
              {isLoading ? <BiLoader className="loader" /> : "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

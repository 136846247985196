import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Login from "../Auth/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Sidebar from "../Components/Sidebar";
import Header from "../Includes/Header";

import StoreBalance from "../Pages/Inventory/StoreBalance/StoreBalance";
import ProductHistory from "../Pages/Inventory/StoreBalance/ProductHistory";
import StackAdjustment from "../Pages/Inventory/StackAdjustment/StackAdjustment";
import StackAdjustmentDetails from "../Pages/Inventory/StackAdjustment/StackAdjustmentDetail";
import NewDocument from "../Pages/Inventory/Transfer/NewDocument";
import Transfer from "../Pages/Inventory/Transfer/Transfer";
import PurchaseImport from "../Pages/Inventory/PurchanseImport/PurchaseImport";
import Purchase from "../Pages/Inventory/Purchase/Purchase";
import PurchaseDocument from "../Pages/Inventory/Purchase/PurchaseDocument";

import CouponList from "../Pages/CouponManagement/CouponList";
import OrdersList from "../Pages/Orders/OrdersList";
import ProductList from "../Pages/Products/ProductList";
import ProductImport from "../Pages/Products/Import/ProductImport";
import { useSelector } from "react-redux";
import AddNewProduct from "../Pages/Products/AddNewProduct";
import EditProduct from "../Pages/Products/EditProduct";
import CategoryList from "../Pages/Category/CategoryList";

import ProductSizes from "../Pages/Settings/ProductSizes";
import SetVat from "./../Pages/Settings/SetVat";
import ProductTags from "./../Pages/Settings/ProductTags";
import ProductColors from "../Pages/Settings/ProductColors";
import StockSetting from "../Pages/Settings/Stock/StockSetting";
import UpdatePrice from "../Pages/Settings/UpdatePrice/UpdatePrice";

import Newsletter from "../Pages/Newletter/Newsletter";
import Carousel from "../Pages/Carousel/Carousel";
import Testimonials from "../Pages/Testimonials/Testimonials";
import Theme from "../Pages/Theme/Theme";
import PriceMovementSetting from "../Pages/Settings/PriceMovement/PriceMovementSetting";
import CurrentStock from "../Pages/Settings/Stock/CurrentStock";
import UserList from "../Pages/Users/UserList";
import Invoice from "../Pages/Orders/Invoice";

import Profile from "../Pages/Profile/Profile";
import DraftOrders from "../Pages/Orders/DraftOrders";

import CourierCompanies from "../Pages/CourierCompanies/CourierCompanies"
import Reviews from "../Pages/Reviews/Reviews";


export default function RouteFile() {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const location = useLocation();
  return (
    <>
      {isAuthenticated === false ? (
        <div className="authPage">
          <Routes>
            <Route path="*" element={<Login />} exact />
          </Routes>
        </div>
      ) : (
        <div className="main">
          {location.pathname.includes("/order/invoice/") ? null : ( // Don't render the Sidebar for the "/order/invoice/:id" route
            <Sidebar />
          )}
          <div className="content">
            <Header />
            <Container>
              <Routes>
                <Route path="/" element={<Dashboard />} exact />
                <Route path="/dashboard" element={<Dashboard />} exact />
                <Route path="/newletter/list" element={<Newsletter />} exact />
                <Route path="/reviews" element={<Reviews />} exact />

                <Route path="/products" element={<ProductList />} exact />
                <Route path="/add-products" element={<AddNewProduct />} exact />
                <Route
                  path="products/edit-product/:id"
                  element={<EditProduct />}
                  exact
                />

                <Route
                  path="/products/import"
                  element={<ProductImport />}
                  exact
                />

                <Route
                  path="/inventory/store-balance"
                  element={<StoreBalance />}
                  exact
                />
                <Route
                  path="/inventory/product-history/:productId"
                  element={<ProductHistory />}
                  exact
                />
                <Route
                  path="/inventory/purchase-import"
                  element={<PurchaseImport />}
                  exact
                />
                <Route
                  path="/inventory/purchase"
                  element={<Purchase />}
                  exact
                />
                <Route
                  path="/inventory/purchase/:documentId"
                  element={<PurchaseDocument />}
                  exact
                />
                <Route
                  path="/inventory/stock-adjustment"
                  element={<StackAdjustment />}
                  exact
                />
                <Route
                  path="/inventory/stock-adjustment/:id"
                  element={<StackAdjustmentDetails />}
                  exact
                />
                <Route
                  path="/inventory/products/transfer"
                  element={<Transfer />}
                  exact
                />
                <Route
                  path="/inventory/transfer/new-document"
                  element={<NewDocument />}
                  exact
                />

                <Route path="/coupon/list" element={<CouponList />} exact />

                <Route
                  path="/order/draft-orders"
                  element={<DraftOrders />}
                  exact
                />

                <Route path="/order/list" element={<OrdersList />} exact />

                <Route
                  path="/order/invoice/:invoice_id"
                  element={<Invoice />}
                  exact
                  />
                    <Route
                  path="/courier-companies"
                  element={<CourierCompanies />}
                  exact
                />

                <Route
                  path="/products-sizes-setting"
                  element={<ProductSizes />}
                  exact
                />
                <Route path="/products-tags" element={<ProductTags />} exact />
                <Route
                  path="/products-colors"
                  element={<ProductColors />}
                  exact
                />

                <Route path="/user-list" element={<UserList />} exact />

                <Route path="/profile" element={<Profile />} exact />

                <Route path="/categories" element={<CategoryList />} exact />

                <Route path="/carousel/list" element={<Carousel />} exact />

                <Route
                  path="/testimonial/list"
                  element={<Testimonials />}
                  exact
                />
                <Route path="/theme/list" element={<Theme />} exact />

                <Route path="/vat-setting" element={<SetVat />} exact />
                <Route path="/stock-setting" element={<StockSetting />} exact />
                <Route path="/current-stock" element={<CurrentStock />} exact />
                <Route path="/update-price" element={<UpdatePrice />} exact />
                <Route
                  path="/price-movements"
                  element={<PriceMovementSetting />}
                  exact
                />
              </Routes>
            </Container>
          </div>
        </div>
      )}
    </>
  );
}

import React from 'react'
import Pagelayout from '../../../Layouts/Pagelayout'
import { Card } from 'react-bootstrap'
import ExcelReader from './ExcelReader'

export default function ProductImport() {
   return (
      <Pagelayout
         title="Product Import"
         breadcrumbs={
            [
               { title: 'Product', link: '/products' },
               { title: 'Product Import', link: '/product/import' },
            ]
         }>
         <Card>
            <Card.Body>
               <ExcelReader />
            </Card.Body>
         </Card>
      </Pagelayout>
   )
}

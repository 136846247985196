import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi'

const CustomInput = (props) => {
   const [showPwd, setShowPwd] = useState(false)

   const { onChange } = props;

   const handleOnChange = (event) => {
      onChange(event);
   };

   return <>
      {
         props.type === "password" ?
            <div className='passwordWrapper'>
               <input {...props}
                  type={showPwd ? 'text' : 'password'}
                  onChange={handleOnChange}
                  min={0}
               />
               <span
                  className="eyeicon"
                  onClick={() => showPwd !== true ? setShowPwd(true) : setShowPwd(false)}
               >
                  {showPwd === false ? <HiOutlineEyeOff /> : <HiOutlineEye />}
               </span>
            </div>
            : <input {...props} type={props.type} onChange={handleOnChange} />

      }
   </>;
};


export default CustomInput;

import React, { useState, useEffect } from 'react'
import { Accordion, useAccordionButton } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Image from './Image'
import { AiOutlineArrowRight, AiOutlineDashboard, AiOutlineMenu } from 'react-icons/ai'
import Logo from '../Components/logo'
import Logosm from '../Components/logosm'
import { useSelector } from 'react-redux'

export default function Sidebar() {

   const menu = [

      { title: 'Dashboard', icon: 'ic_dashboard.svg', link: '/dashboard' },
      { title: 'Newletters', icon: 'ic_mail.svg', link: '/newletter/list' },
      {
         title: 'Coupon Code', icon: 'ic_discount_and_coupons.svg', link: null, child: [
            { title: 'Coupon Code List', icon: '', link: '/coupon/list' },
         ]
      },

      {
         title: 'Order Management', icon: 'ic_orders.svg', link: null, child: [
            { title: 'Order List', icon: '', link: '/order/list' },
            { title: 'Draft Orders', icon: '', link: '/order/draft-orders' },
         ]
      },
      {
         title: 'Product Management', icon: 'ic_cart.svg', link: null, child: [
            { title: 'Product', icon: '', link: '/products' },
            { title: 'Reviews', icon: '', link: '/reviews' },
            { title: 'Manage Size', icon: '', link: '/products-sizes-setting' },
            { title: 'Manage Tags', icon: '', link: '/products-tags' },
            { title: 'Manage Colors', icon: '', link: '/products-colors' },
            { title: 'Consignment Records', icon: '', link: '/stock-setting' },
         ]
      },
      {
         title: 'Category', icon: 'ic_category.svg', link: null, child: [
            { title: 'Category List', icon: '', link: '/categories' },
         ]
      },
      {
         title: 'User Management', icon: 'ic_user.svg', link: null, child: [
            { title: 'User List', icon: '', link: '/user-list' },
         ]
      },
      {
         title: "Courier Companies",
         icon: "ic_dashboard.svg",
         link: "/courier-companies",
       },
   
      { title: 'Carousel', icon: 'ic_carousal.svg', link: '/carousel/list' },
      { title: 'Testimonials', icon: 'ic_chat.svg', link: '/testimonial/list' },
      { title: 'Theme', icon: 'ic_category.svg', link: '/theme/list' },

      {
         title: 'Inventory', icon: 'ic_kanban.svg', link: null, child: [
            { title: 'Purchase', icon: '', link: '/inventory/purchase' },
            { title: 'Transfer', icon: '', link: '/inventory/products/transfer' },
            // { title: 'Write-off', icon: '', link: '/inventory/products/write-off' },
            { title: 'Stock adjustment', icon: '', link: '/inventory/stock-adjustment' },
            // { title: 'Reorder', icon: '', link: '/inventory/products/reorder' },
            { title: 'Purchase Import', icon: '', link: '/inventory/purchase-import' },
            { title: 'Store Balance', icon: '', link: '/inventory/store-balance' },
            // { title: 'Transaction Report', icon: '', link: '/inventory/transaction-report' },
         ]
      },
     
      {
         title: 'Settings', icon: 'ic_cog.svg', link: null, child: [
            { title: 'VAT', icon: '', link: '/vat-setting' },
            // { title: 'Current Stock', icon: '', link: '/current-stock' },
            { title: 'Price Record', icon: '', link: '/update-price' },
            // { title: 'Price movements', icon: '', link: '/price-movements' },

         ]
      },
   ]

   const [showSidebar, setShowSidebar] = useState(true)
   // const [defaultActiveTab, setDefaultActiveTab] = useState(null)
   const toggleSidebar = () => {
      showSidebar ? setShowSidebar(false) : setShowSidebar(true)
   }

   const userinfo = useSelector((state) => state.user.user);


   // const decoratedOnClick = useAccordionButton(eventKey);

   // useEffect(() => {
   //    if (showSidebar) {
   //       decoratedOnClick(0)
   //    } else {
   //    }
   // }, [showSidebar]);

   return (
      <div onMouseEnter={() => setShowSidebar(false)}
         onMouseLeave={() => {
            setShowSidebar(true)
         }}>
         <button className={showSidebar ? 'minimize toggleSidebar' : 'toggleSidebar'} >
            {showSidebar ? <AiOutlineArrowRight /> : <AiOutlineMenu />}
         </button>
         <div

            className={showSidebar ? 'sidebar minimize' : 'sidebar'}>
            <div className='sidebar_wrapper'>

               <div className="nav">
                  <div className='sidebar_header'>
                     <div className='account_info position-relative'>
                        <Link to="/profile" className="stretched-link h-100 w-100" style={{ zIndex: 10, position: 'absolute', top: 0, left: 0, padding: 0 }}></Link>
                        <div className="profile_img_sm profile_img"><Image src={require('../public/images/android-chrome-512x512.png')} alt="android-chrome-192x192.png" /></div>
                        <div className='ps-3'><p className='mb-0 text-capitalize'>{userinfo?.first_name} {userinfo?.last_name}</p></div>
                     </div>
                  </div>
                  <Accordion as={'li'} >

                     {menu.map((i, index) => {
                        return (
                           <Accordion.Item className={i.link !== null && 'noChild'} eventKey={index} key={index}>
                              <Accordion.Header >
                                 {
                                    i.link !== null ?
                                       <Link className="nav-link" to={i.link}>
                                          <Image src={require(`../public/images/icons/${i.icon}`)} height={20} /><div className='text'>&nbsp; {i.title}</div></Link>
                                       :
                                       <Link className="nav-link" to={'#'}>
                                          <Image src={require(`../public/images/icons/${i.icon}`)} height={20} /><div className='text'> &nbsp; {i.title}</div></Link>
                                 }
                              </Accordion.Header>
                              {
                                 i.link === null &&
                                 <Accordion.Body>
                                    <div className=" show">
                                       <ul className="nav flex-column sub-menu">
                                          {i.child.map((j, index) => <li key={index} className="nav-item">
                                             <Link className="nav-link" to={j.link}>{j.title}</Link>
                                          </li>)}

                                       </ul>
                                    </div>
                                 </Accordion.Body>
                              }
                           </Accordion.Item>
                        )
                     })}
                  </Accordion>
               </div>
            </div>
         </div>
      </div>
   )
}

/* eslint-disable */
import React, { useEffect, useState } from "react";
import LTable from "../../Components/LTable";
import Currency from "../../Components/Currency";
import { amount } from "../../Components/Utils";
import { formatDate } from "../../Components/Utils";
import { Col, Row } from "react-bootstrap";
import Image from "../../Components/Image";

export default function ProductObj({ data, list }) {
  const [selectImg, setSelectImg] = useState("");

  const [currentItem, setCurrentItem] = useState(0);

  const [page, setPage] = useState(null);

  const [allProduct, setAllProducts] = useState(list);

  useEffect(() => {
    setAllProducts(list);
  }, [list]);

  const handleThumbnailClick = (index) => {
    setSelectImg(
      `${[{
            image_name: data.code_image_name,
            image_path: data.code_image_path,
          },...data?.product_images][index]?.image_path}/${[{
            image_name: data.code_image_name,
            image_path: data.code_image_path,
          },...data?.product_images][index]?.image_name}?resize=500`
    );
    setCurrentItem(index);
  };

  return (
    <>
      <Row>
        <Col md={12} className="text-center">
          <img
            className={"mb-3 rounded shadow mw-100"}
            height={400}
            src={
              selectImg ||
              (data.code_image_name!=='null'&&`${
                data &&
                [
                  {
                    image_name: data.code_image_name,
                    image_path: data.code_image_path,
                  },
                  ...data?.product_images,
                ].filter(j=>j.image_name!==null)[currentItem]?.image_path
              }/${
                data &&
                [
                  {
                    image_name: data.code_image_name,
                    image_path: data.code_image_path,
                  },
                  ...data?.product_images,
                ].filter(j=>j.image_name!==null)[currentItem]?.image_name+'?resize=500'
              }`)
            }
            style={{ objectFit: "cover" }}
          />
        </Col>
        <Col
          md={12}
          className="text-center d-flex align-items-center justify-content-center"
        >
          {[
            {
              image_name: data.code_image_name,
              image_path: data.code_image_path,
            },
            ...data?.product_images,
          ].filter(j=>j.image_name!==null).map((i, index) => (
            <div
              key={index}
              onClick={() => handleThumbnailClick(index)}
              className={`mx-2 my-3 ${index === currentItem ? "selected" : ""}`}
              style={{ height: 50 }}
            >
              <Image
                className={"rounded shadow"}
                height={50}
                width={50}
                src={i?.image_path + "/" + i?.image_name+'?resize=100'}
                style={{ objectFit: "cover" }}
              />
            </div>
          ))}
        </Col>

        <Col md={12}>
          <h5 className="fw-bold mb-3">{data?.name}</h5>
          <p className="mb-2">
            <b>Category -</b> {data?.category?.name}
          </p>
          <p className="mb-2">
            <b>SKU -</b> {data?.sku}
          </p>
          <p className="mb-2">
            <b>Created On -</b> {formatDate(data?.created_at)}
          </p>
          <p className="mb-2">
            <b>Updated On -</b> {formatDate(data?.updated_at)}
          </p>
          {/* <p className='mb-2'>Updated By <b> {userList?.userList?.rows&&userList?.userList?.rows.find(j=>data?.id==data?.updated_by)?.first_name}</b> on  <b>{formatDate(data?.updated_at)}</b></p> */}
          <p className="mb-2">
            <b>Full Description -</b>{" "}
            <div
              className="mb-0 description"
              dangerouslySetInnerHTML={{
                __html: data?.full_description,
              }}
            ></div>
          </p>
          <p className="mb-2">
            <b>Short Description -</b>{" "}
            <div
              className="mb-0 description"
              dangerouslySetInnerHTML={{
                __html: data?.short_description,
              }}
            ></div>
          </p>
        </Col>
      </Row>
      <hr />
      <h6 className="mb-3">Size and Price List</h6>
      <LTable>
        <thead>
          <tr>
            <th>Sn</th>
            <th>Stock</th>
            <th>Weight</th>
            <th>Length</th>
            <th>Barcode</th>
            <th>Size Name</th>
            <th>Prices</th>
          </tr>
        </thead>
        <tbody>
          {data?.sizes.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.stock}</td>
              <td>{item.weight}</td>
              <td>{item.length}</td>
              <td>{item.bar_code}</td>
              <td>{item.size_name.name}</td>
              <td>
                <ul className="fancyList">
                  {item.size_prices.map((price) => (
                    <li key={price.id}>
                      Gms- <Currency currency={price.currency_id} />{" "}
                      {amount(price.price_gram)} &nbsp;&nbsp;|&nbsp;&nbsp;
                      Piece- <Currency currency={price.currency_id} />{" "}
                      {amount(price.price_piece)} &nbsp;&nbsp;|&nbsp;&nbsp;
                      Discount - {amount(price.discount)}%
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </LTable>
    </>
  );
}

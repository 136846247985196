import React, { useEffect, useState } from 'react'
import Notification, { notify } from '../../Components/Notification'
import { Button, Col, Row, Form } from 'react-bootstrap'
import Label from '../../Components/Label'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import CustomInput from '../../Components/CustomInput'
import { BiLoader } from 'react-icons/bi'
import doAPI from '../../api'

export default function ChangePassword({ onFormSubmitSuccess }) {
   const [isLoading, setIsLoading] = useState(false)

   const PasswordSchema = Yup.object().shape({
      "password": Yup
         .string()
         .required('Please Enter your password'),
      "newPassword": Yup
         .string()
         .required('Please Enter your new password')
         .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Case Character"
         ),
      "confirmPassword": Yup
         .string()
         .required('Please Confirm your new password')
         .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
         .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Case Character"
         ),
   });


   const formik = useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
         password: "",
         newPassword: "",
         confirmPassword: ""
      },
      onSubmit: (values, { resetForm }) => {
         let formData = new FormData();
         for (const key in formik.values) {
            if (Object.hasOwnProperty.call(formik.values, key)) {
               formData.append(key, formik.values[key]);
            }
         }

         doAPI.putData('user/change-password', formData).then((res) => {
            setIsLoading(true)
            if (res.status) {
               notify(res.message);
               onFormSubmitSuccess();
               setIsLoading(false)
            } else {
               notify(res.message[0]?.message[0]?.message, { variant: 'error' });
            }
         })

      },
      validationSchema: PasswordSchema
   });

   return (
      <>
         <Form onSubmit={formik.handleSubmit}>
            <Notification />
            <Row>
               <Col md={12} className='mb-2'>
                  <Label aria-required>Password</Label>
                  <CustomInput
                     type='password'
                     className="form-control"
                     placeholder="Password"
                     name="password"
                     value={formik.values.password}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password ? (
                     <div className="error">{formik.errors.password}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-2'>
                  <Label aria-required>New Password</Label>
                  <CustomInput
                     type='password'
                     className="form-control"
                     placeholder="Password"
                     name="newPassword"
                     value={formik.values.newPassword}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                     <div className="error">{formik.errors.newPassword}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
               <Label aria-required>Confirm Password</Label>
                  <CustomInput
                     type='password'
                     className="form-control"
                     placeholder="Password"
                     name="confirmPassword"
                     value={formik.values.confirmPassword}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                     <div className="error">{formik.errors.confirmPassword}</div>
                  ) : null}
               </Col>

             
               <Col md={12} className='mb-2'>
                  <Button
                     type="submit"
                     size='lg'
                     className="px-md-5 w-100"
                     variant="primary">
                     {isLoading ? <BiLoader className='loader' /> : "Submit"}
                  </Button>
               </Col>
            </Row>
         </Form>
      </>
   )
}

// CustomCropper.js
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal, Button } from "react-bootstrap";
import Image from "./Image";
import { AiFillDelete } from "react-icons/ai";

const CustomCropper = ({ onImageCropped, fetchedImg, isMulti }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [imagePriview, setImagePriview] = useState(null);
  const [cropper, setCropper] = useState();
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
  };

  const handleCrop = () => {
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();

      // Convert the canvas to Blob
      croppedCanvas.toBlob((blob) => {
        // Create a File object from the Blob
        const croppedImage = new File([blob], "cropped-image.png", {
          type: "image/png",
        });
        setImagePriview(cropper.getCroppedCanvas().toDataURL());
        // Set the File object in the state
        setCroppedImageFile(croppedImage);
        onImageCropped(croppedImage);
      }, "image/png");
    }
  };

  const bytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2); // Convert bytes to megabytes with 2 decimal places
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop,  multiple: isMulti  });
  console.log(croppedImageFile?.size);
  return (
    <div className="customMultiUpload">
      <div
        {...getRootProps()}
        style={dropzoneStyles}
        className="contained-button-file"
      >
        <button type="button" class="btn btn-primary">
          Select Image
        </button>
        <input {...getInputProps()} />
      </div>

      {selectedFile && (
        <Modal show={selectedFile} onHide={() => setSelectedFile(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Crop Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Cropper
              style={{ height: 400, width: "100%" }}
              initialAspectRatio={1}
              src={URL.createObjectURL(selectedFile)}
              viewMode={1}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkCrossOrigin={true}
              onInitialized={(instance) => setCropper(instance)}
            />
            <button className="btn btn-priamry" onClick={handleCrop}>
              Crop Image
            </button>
          </Modal.Body>
        </Modal>
      )}
      {typeof croppedImageFile!=='object'&&fetchedImg !== "" && (
        <div>
          <div className="previewHolder mt-3">
            <div className="filePreview">
              <img src={fetchedImg} />
              {/* <div className="actionbtn">
                <button type="button">
                  <AiFillDelete />
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}

      {croppedImageFile && (
        <div>
          <div className="previewHolder mt-3">
            <div className="filePreview">
              <Image src={imagePriview} />
              <div className="actionbtn">
                <button type="button">
                  <AiFillDelete />
                </button>
              </div>
              <small>{bytesToMB(croppedImageFile.size)} MB</small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const dropzoneStyles = {
  // border: '2px dashed #ccc',
  borderRadius: "4px",
  padding: "0px",
  textAlign: "center",
  cursor: "pointer",
};

export default CustomCropper;

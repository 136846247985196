import React, { useEffect, useState } from 'react'
import Notification, { notify } from '../../Components/Notification'
import { Button, Col, Row, Form, Card, Dropdown, Modal } from 'react-bootstrap'
import Label from '../../Components/Label'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import Service from '../../http'
import CustomInput from '../../Components/CustomInput'
import { useDispatch, useSelector } from 'react-redux'
import { BiDotsVerticalRounded, BiLoader } from 'react-icons/bi'
import Pagelayout from '../../Layouts/Pagelayout'
import LTable from '../../Components/LTable'
import { MdDelete, MdEdit } from 'react-icons/md'
import { fetchTestimonialList } from '../../redux/testimonialSlice'
import Image from '../../Components/Image'
import AddTestimonial from './AddTestimonials';
import Swal from 'sweetalert2'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import RatingStars from './../../Components/RatingStars';
import EditTestimonial from './EditTestimonial'
import { tableSerialNumber } from '../../Components/Utils'
export default function Testimonials() {
   const services = new Service();
   const dispatch = useDispatch()

   const [testimonialData, setTestimonialData] = useState()
   const handleFormSubmitSuccess = () => {
      setShowTestimonialEditForm(false)
      setShowTestimonialAddForm(false)
      dispatch(fetchTestimonialList())
   }

   const [TestimonialAddForm, setShowTestimonialAddForm] = useState(false);
   const handleCloseTestimonialAddForm = () => setShowTestimonialAddForm(false);
   const handleShowTestimonialAddForm = () => setShowTestimonialAddForm(true);


   const [TestimonialEditForm, setShowTestimonialEditForm] = useState(false);
   const handleCloseTestimonialEditForm = () => setShowTestimonialEditForm(false);
   const handleShowTestimonialEditForm = (data) => {
      setTestimonialData(data)
      setShowTestimonialEditForm(true)
   };

   const testimonial = useSelector(state => state.testimonial)
   useEffect(() => {
      dispatch(fetchTestimonialList())
   }, [dispatch])

   console.log(testimonial?.testimoniallist.data)

   const handleDeleteTestimonial = (testimonialId) => {

      const options = {
         title: 'Delete Testimonials',
         text: 'Are you sure you want to delete this testimonials?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, delete it!',
      };

      // Use Swal.fire to show the SweetAlert dialog
      Swal.fire(options).then((result) => {
         if (result.isConfirmed) {
            // User confirmed, proceed with deletion
            services
               .put('testimonial/delete/' + testimonialId)
               .then((res) => {
                  if (res.status) {
                     notify(res.message);
                     dispatch(fetchTestimonialList());
                  } else {
                     notify(res.message[0]?.message);
                  }
               })
               .catch((error) => {
                  console.error('Error - ', error);
               });
         }
      });
   }
   return (
      <Pagelayout
         title="Testimonial"
         breadcrumbs={
            [
               { title: 'Dashboard', link: '/dashboard' },
               { title: 'Testimonial list', link: '#' },
            ]
         }
         actions={<Button onClick={() => handleShowTestimonialAddForm()}>Add Testimonial</Button>}
      >
         <Card>
            <Card.Body>
               <LTable isLoading={testimonial.status == 'pending'}>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Message</th>
                        <th>Rating</th>
                        <th>Action</th>
                     </tr>
                  </thead>
                  <tbody>
                     {testimonial?.testimoniallist.data && testimonial?.testimoniallist.data.map((i, index) => <tr key={index}>
                        <td>{tableSerialNumber(index, testimonial?.testimoniallist.cur_page, testimonial?.testimoniallist.data.length)}</td>

                        <td>
                           <Image src={`${i.image_path}/${i.image_name}`} height={50} width={50} />
                           &nbsp; {i.title}</td>
                        <td>{i.message}</td>
                        <td><RatingStars rating={i.rating} /></td>
                        <td>
                           <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                 <BiDotsVerticalRounded />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                 <Dropdown.Item onClick={() => handleShowTestimonialEditForm(i)}><MdEdit /> Edit</Dropdown.Item>
                                 <Dropdown.Item onClick={() => handleDeleteTestimonial(i.id)}><MdDelete /> Delete</Dropdown.Item>
                              </Dropdown.Menu>
                           </Dropdown>
                        </td>
                     </tr>)}
                  </tbody>
               </LTable>
            </Card.Body>
         </Card>


         <Modal show={TestimonialEditForm} onHide={handleCloseTestimonialEditForm}>
            <Modal.Header closeButton>
               <Modal.Title>Edit Testimonial</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <EditTestimonial onFormSubmitSuccess={handleFormSubmitSuccess} data={testimonialData} />
            </Modal.Body>
         </Modal>

         <Modal show={TestimonialAddForm} onHide={handleCloseTestimonialAddForm}>
            <Modal.Header closeButton>
               <Modal.Title>Add Testimonial</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <AddTestimonial onFormSubmitSuccess={handleFormSubmitSuccess} />
            </Modal.Body>
         </Modal>
      </Pagelayout>
   )
}


// orderStatusSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

// Define an initial state
const initialState = {
  status: null,
  loading: false,
  error: null,
};

// Define the async thunk for fetching order status
export const fetchOrderStatus = createAsyncThunk(
  "orderStatus/fetchOrderStatus",
  async ({ type = 'user'}) => {
     try {
      const params = new URLSearchParams({ type }).toString();

      const data = await doAPI.getData(`order/status?${params}`);
      return data;
    } catch (error) {
      throw Error("Failed to fetch order status");
    }
  }
);

// Create the slice
const orderStatusSlice = createSlice({
  name: "orderStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrderStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload;
      })
      .addCase(fetchOrderStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the async thunk for external usage
// export { fetchOrderStatus };

// Export the reducer
export default orderStatusSlice.reducer;

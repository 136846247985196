import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';
import { toast } from 'react-toastify';
import { logoutApp } from './../Components/Utils';

const services = new Service();

export const loginUser = createAsyncThunk('user/admin-login', async (credentials) => {
  try {
    const response = await services.post('user/admin-login', credentials);
    console.log({ response })

    if (response?.status === true) {
      toast.success(response?.message); // Display a success toast message
      console.log(response)
      localStorage.setItem('user', JSON.stringify({
        user: response,
        error: null,
        loading: false,
        isAuthenticated: true
      }))
      localStorage.setItem('token', JSON.stringify(response?.data?.access_token))
    } else {
      toast.error(response?.message[0]?.message)
    }

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

const initialState = {
  user: null,
  error: null,
  loading: false,
  isAuthenticated: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState: (state, action) => {
      return action.payload; // Set the state to the payload data
    },
    // Add the logout action to clear user data
    logoutUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      logoutApp() // Clear the token from localStorage
      toast.success('User has been logged out successfully.');
    },
    // Add any synchronous actions you might need here.
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isAuthenticated = false;

      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
        state.isAuthenticated = true;


        if (action.payload.access_token !== undefined) {
          // After a successful login, update the localStorage
          localStorage.setItem('user', JSON.stringify({
            user: action.payload,
            error: null,
            loading: false,
            isAuthenticated: true
          }))

          window.location.href = '/'
        }

      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.error = action.error.message;
        state.isAuthenticated = false;
        localStorage.removeItem('user');

      });
  },
});
export const { resetUserState, logoutUser } = userSlice.actions;

export default userSlice.reducer;

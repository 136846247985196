import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export default function Image({ src, className, height, width, style, imgPreview }) {
   const [showImage, setShowImage] = useState(false);

   const handleCloseImage = () => setShowImage(false);
   const handleShowImage = () => setShowImage(true);
   return (
      <>
         <img
            onClick={(event) => handleShowImage(event)}
            className={className}
            src={src !== undefined ? src : ''}
            height={height}
            width={width}
            alt={src && src.replace(/^.*[\\\/]/, '')}
            style={style}
         // type={`images/${ext[0]}`}
         />

         {imgPreview && <Modal show={showImage} size="lg" onHide={handleCloseImage}>
            <Modal.Header closeButton>
               <Modal.Title className="h5 m-0">Image Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 overflow-hidden">
               <img src={src !== undefined ? src : ''} className="w-100" alt={src && src.replace(/^.*[\\\/]/, '')} /></Modal.Body>
         </Modal>}
      </>
   );
}
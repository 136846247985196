import React, { useEffect, useState } from 'react'
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { useFormik } from "formik";
import * as Yup from 'yup';
import Service from '../../http'
import { fetchCoupons } from '../../redux/couponSlice'
import { useDispatch, useSelector } from 'react-redux'
import CustomInput from '../../Components/CustomInput';
import Notification, { notify } from '../../Components/Notification';
import CustomDatePicker from '../../Components/CustomDatePicker'
import Label from '../../Components/Label';
import CustomSelect from './../../Components/CustomSelect';
import Currency from './../../Components/Currency';
import { BiLoader } from 'react-icons/bi';
import { fetchCategories } from '../../redux/categorySlice';
import { fetchUsers } from '../../redux/usersSlice';
import { AiFillInfoCircle } from 'react-icons/ai';
import { fetchProductList } from './../../redux/productSlice';

export default function AddCoupon({ onSuccess }) {

   const services = new Service();
   const dispatch = useDispatch()
   const [userList, setUserList] = useState([])
   const [isLoading, setIsLoading] = useState(false);
   const [categoryList, setCategoryList] = useState([])
   const [productList, setProductList] = useState([])
   const [productSearchQuery, setProductSearchQuery] = useState('')
   const [categorySearchQuery, setCategorySearchQuery] = useState('')
   const [forAllCategories, setForAllCategories] = useState(true)

   const categories = useSelector((state) => state.category)?.categories;

   const products = useSelector((state) => state.product)?.productList?.rows;

   const users = useSelector((state) => state.user?.user);

   const fetchEntry = () => {
      dispatch(fetchUsers());
   };

   useEffect(() => {
      fetchEntry()
   }, [dispatch]);

   useEffect(() => {
      dispatch(fetchCategories());
   }, [dispatch, categorySearchQuery]);


   useEffect(() => {
      setUserList([]);
      users && [users].map(i => {
         const obj = {};
         obj.value = i.first_name + ' ' + i.family_name;
         obj.label = i.first_name + ' ' + i.family_name;
         setUserList(old => [...old, obj]);
      });
   }, [users]);

   useEffect(() => {
      setCategoryList([]);
      categories && categories.map(i => {
         const obj = {};
         obj.value = i.id;
         obj.label = i.name;
         setCategoryList(old => [...old, obj]);
      });

   }, [categories]);

   const formik = useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
         code: "",
         product_id: '',
         category_id: '',
         valid_from: new Date(),
         valid_to: new Date(),
         discount: null,
         min_order: null,
         max_discount: null,
         discount_type: null,
         maximum_used: null,
         is_active: 0,
      },
      onSubmit: (values, { resetForm }) => {
         setIsLoading(true);
         services &&
            services
               .post("coupon/create", values)
               .then((res) => {
                  if (res.status) {
                     notify(res.message)
                     dispatch(fetchCoupons({ page: 0, search: '' }));
                     onSuccess()
                  } else {
                     res?.response?.data?.message.map(i => notify(i.message))
                  };
                  setIsLoading(false);
               });

         resetForm();
      },
      validationSchema: Yup.object().shape({
         code: Yup.string().required('Code is required.'),
         valid_from: Yup.string().required('Valid from is required.'),
         valid_to: Yup.string().required('Valid to is required.'),
         discount: Yup.number().min(1, 'Discount must be greater than 0.').required('Discount is required.'),
         min_order: Yup.number().min(1, 'Min order must be greater than 0.').nullable().required('Min order is required.'),
         max_discount: Yup.number().min(1, 'Max discount must be greater than 0.').nullable().required('Max discount is required.'),
         discount_type: Yup.mixed().required('Discount type is required.'),
         maximum_used: Yup.number().min(1, 'Maximum used must be greater than 0.').nullable().required('Maximum used is required.'),
      })
   });

   useEffect(() => {
      dispatch(fetchProductList({ search: productSearchQuery, category: formik.values.category_id }));
   }, [productSearchQuery, formik.values.category_id]);

   useEffect(() => {
      if (forAllCategories == true) {
         formik.setFieldValue('category_id', 0)
         formik.setFieldValue('product_id', 0)
      } else {
         formik.setFieldValue('category_id', formik.values.category_id)
         formik.setFieldValue('product_id', formik.values.product_id)
      }
   }, [forAllCategories])

   useEffect(() => {
      products&&products.map((i,index)=>setProductList(old=>[...old,{label:i.name, value:i.id}]))
   },[products])


   return (
      <Form onSubmit={formik.handleSubmit}>
         <Notification />
         <Row>
            <Col md={12} className='mb-3'>
               <Label aria-required> Code

               </Label>
               <CustomInput name="code" className='form-control' type='text' placeholder='Code'
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.code && formik.errors.code ? (
                  <div className="error">{formik.errors.code}</div>
               ) : null}
            </Col>
            <Col md={12} className='mb-3'>
               <Label><input checked={forAllCategories} onChange={(event) => setForAllCategories(event.target.checked)} type="checkbox" /> Please select this option, if you want to apply this Coupon Code on all products in the Store.
               </Label>
            </Col>
            {/* {JSON.stringify(forAllCategories)} */}
            <Col md={6} className='mb-3'>
               <Label aria-required>Select Category</Label>
               <select
                  disabled={forAllCategories ? true : false}
                  className="form-control"
                  name='category_id'
                  value={formik.values.category_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               >
                  <option value={0}>Select Category</option>
                  {categories && categories.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
               </select>
             
            </Col>

            <Col md={6} className='mb-3'>
               <Label aria-required>
                  Select Product   <OverlayTrigger
                     placement={'top'}
                     overlay={
                        <Tooltip>
                           Pls do not select any individual product, if you wish to apply the Coupon Code to a particular Category completely.
                        </Tooltip>
                     }
                  >
                     <span><AiFillInfoCircle /></span>
                  </OverlayTrigger>
               </Label>
               <CustomSelect
                   isDisabled={forAllCategories ? true : false}
                  className={'w-100'}
                  placeholder="Select Product"
                  onInputChange={value => setProductSearchQuery(value)}
                  value={formik.values.parent_id}
                  onChange={(value) => {
                     formik.setFieldValue('parent_id', value.value)
                  }}
                  options={productList}
               />
            </Col>
            <Col md={6} className='mb-3'>
               <Label aria-required>Valid From</Label>
               <CustomDatePicker
                  name="valid_from"
                  className="form-control"
                  value={formik.values.valid_from}
                  onChange={(value) => formik.setFieldValue('valid_from', value)}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.valid_from && formik.errors.valid_from ? (
                  <div className="error">{formik.errors.valid_from}</div>
               ) : null}
            </Col>

            <Col md={6} className='mb-3'>
               <Label aria-required>Valid Till</Label>
               <CustomDatePicker
                  name="valid_to"
                  className="form-control"
                  value={formik.values.valid_to}
                  onChange={(value) => formik.setFieldValue('valid_to', value)}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.valid_to && formik.errors.valid_to ? (
                  <div className="error">{formik.errors.valid_to}</div>
               ) : null}
            </Col>

            <Col md={6} className='mb-3'>
               <Label aria-required>Discount Type</Label>
               <CustomSelect
                  className={'w-100'}
                  placeholder="Select Coupon Type"
                  value={formik.values.discount_type}
                  onChange={(value) => formik.setFieldValue('discount_type', value.value)}

                  options={[{ label: 'Percentage', value: 0 }, { label: 'Fixed Amount', value: 1 }]}
               />
               {formik.touched.discount_type && formik.errors.discount_type ? (
                  <div className="error">{formik.errors.discount_type}</div>
               ) : null}
            </Col>

            <Col md={6} className='mb-3'>
               <Label aria-required>Discount</Label>
               <div className='d-flex align-items-center input-group'>
                  <CustomInput name="discount" className='form-control' type='text' placeholder='Discount'
                     value={formik.values.discount}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  <h5 className='m-0 px-3'>{formik.values.discount_type == 1 ? <Currency /> : '%'}</h5>
               </div>
               {formik.touched.discount && formik.errors.discount ? (
                  <div className="error">{formik.errors.discount}</div>
               ) : null}
            </Col>


            <Col md={6} className='mb-3'>
               <Label aria-required>
                  Min Order Amount   <OverlayTrigger
                     placement={'top'}
                     overlay={
                        <Tooltip>
                           Minimum Orders amount whihc is required in the check out, for this Coupon to be applicable.
                        </Tooltip>
                     }
                  >
                     <span><AiFillInfoCircle /></span>
                  </OverlayTrigger>
               </Label>
               <CustomInput
                  name="min_order"
                  className='form-control'
                  type='number'
                  placeholder='Min Order Amount'
                  value={formik.values.min_order}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.min_order && formik.errors.min_order ? (
                  <div className="error">{formik.errors.min_discount}</div>
               ) : null}
            </Col>

            <Col md={6} className='mb-3'>

               <Label aria-required>
                  Max Discount Allowed <OverlayTrigger
                     placement={'top'}
                     overlay={
                        <Tooltip>
                           The maximum amount of discount that will be allowed, in case the percentage amount is in excess.
                        </Tooltip>
                     }
                  >
                     <span><AiFillInfoCircle /></span>
                  </OverlayTrigger>
               </Label>
               <CustomInput name="max_discount" className='form-control' type='number' placeholder='Max Discount Allowed'
                  value={formik.values.max_discount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.max_discount && formik.errors.max_discount ? (
                  <div className="error">{formik.errors.max_discount}</div>
               ) : null}
            </Col>
            <Col md={6} className='mb-3'>
               <Label aria-required>No. of Coupon</Label>
               <CustomInput name="maximum_used" className='form-control' type='text' placeholder='No. of Coupon'
                  value={formik.values.maximum_used}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.maximum_used && formik.errors.maximum_used ? (
                  <div className="error">{formik.errors.maximum_used}</div>
               ) : null}
            </Col>

            <Col md={6} className='mb-3'>
               <Label aria-required>
                  Select User  <OverlayTrigger
                     placement={'top'}
                     overlay={
                        <Tooltip>
                           Use this option, to select the user, to whom this discount would be applicable.
                        </Tooltip>
                     }
                  >
                     <span><AiFillInfoCircle /></span>
                  </OverlayTrigger>
               </Label>
               <CustomSelect
                  className={'w-100'}
                  placeholder="Select User"
                  value={formik.values.user}
                  onChange={(value) => formik.setFieldValue('user', value.value)}
                  options={userList}
               />
            </Col>

            <Col md={12} className='mb-3'>
               <Button
                  type="submit"
                  size='lg'
                  className="px-md-5 w-100"
                  variant="primary"
               >
                  {isLoading ? <BiLoader className='loader' /> : "Add Coupon"}
               </Button>
            </Col>
         </Row>
      </Form >
   )
}

// propertySlice.js
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
    currency_id: 0,
    selectedCurrency: {
        id: null,
        name: "",
        short_code: "",
        symbol: "",
        rate: null
    },
    // Add other properties as needed
};

const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        setSelectedCurrency: (state, action) => {
            state.currency_id = action?.payload?.id;
            state.selectedCurrency = action?.payload;
        },
        // Add other reducer functions as needed
    },
});

const persistConfig = {
    key: 'property',
    storage: storage,
};

const persistedReducer = persistReducer(persistConfig, propertySlice.reducer);

export const { setSelectedCurrency } = propertySlice.actions;
export default persistedReducer;

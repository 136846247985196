import React, { useEffect, useState } from 'react'
import Notification, { notify } from '../../Components/Notification'
import { Button, Col, Row, Form } from 'react-bootstrap'
import Label from '../../Components/Label'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import Service from '../../http'
import CustomInput from '../../Components/CustomInput'
import { useSelector } from 'react-redux'
import { BiLoader } from 'react-icons/bi'

export default function EditTestimonial({ onFormSubmitSuccess, data }) {
   const services = new Service();
   const currency = useSelector((state) => state.currency)?.currencylist?.data;
   const [isLoading, setIsLoading] = useState(false)

   const NewProductSizeSchema = Yup.object().shape({
      image: Yup.mixed().required('slider_image is required'),
      title: Yup.string().required('title is required'),
      message: Yup.string().required('content is required'),
      rating: Yup.string().required('link is required')

   });

   const formik = useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
         testimonial_id: 0,
         image: "",
         title: "",
         message: "",
         rating: ""
      },
      onSubmit: (values, { resetForm }) => {
      },
      validationSchema: NewProductSizeSchema
   });

   const submitForm = () => {
      let formData = new FormData();
      for (const key in formik.values) {
         if (Object.hasOwnProperty.call(formik.values, key)) {
            formData.append(key, formik.values[key]);
         }
      }

      services && services.post('testimonial/add-update', formData).then((res) => {
         setIsLoading(true)
         if (res.status) {
            notify(res.message);
            onFormSubmitSuccess();
            setIsLoading(false)
         } else {
            notify(res.message[0]?.message[0]?.message, { variant: 'error' });
         }
      })
   }

   useEffect(() => {
      formik.setValues({
         testimonial_id: data?.id,
         image: data?.image,
         title: data?.title,
         message: data?.message,
         rating: data?.rating
      })
   }, [data])
   console.log(formik.values)

   return (
      <>
         <Form onSubmit={formik.handleSubmit}>
            <Notification />
            <Row>
               <Col md={12} className='mb-3'>
                  <Label aria-required>Image</Label>
                  <CustomInput
                     type='file'
                     name="image"
                     placeholder='Image'
                     className='form-control'
                     onChange={(event) => {
                        formik.setFieldValue("image", event.currentTarget.files[0]);
                     }}
                     onBlur={formik.handleBlur}
                  />

                  {formik.touched.image && formik.errors.image ? (
                     <div className="error">{formik.errors.image}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Label aria-required>Title</Label>
                  <CustomInput type='text' name="title" placeholder='Title' className='form-control'
                     value={formik.values.title}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                     <div className="error">{formik.errors.title}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Label aria-required>Message</Label>
                  <CustomInput type='text' name="message" placeholder='Message' className='form-control'
                     value={formik.values.message}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.message && formik.errors.message ? (
                     <div className="error">{formik.errors.message}</div>
                  ) : null}
               </Col>


               <Col md={12} className='mb-3'>
                  <Label aria-required>Rating</Label>
                  <CustomInput type='number' name="rating" placeholder='Rating' className='form-control'
                     value={formik.values.rating}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.rating && formik.errors.rating ? (
                     <div className="error">{formik.errors.rating}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Button
                     // type="submit"
                     size='lg'
                     onClick={() => submitForm()}
                     className="px-md-5 w-100"
                     variant="primary">
                     {isLoading ? <BiLoader className='loader' /> : "Submit"}
                  </Button>
               </Col>
            </Row>
         </Form>
      </>
   )
}

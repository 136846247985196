import React, { useEffect, useState } from "react";
import Notification, { notify } from "../../Components/Notification";
import { Button, Col, Row, Form } from "react-bootstrap";
import Label from "../../Components/Label";
import { useFormik } from "formik";
import * as Yup from "yup";
import Service from "../../http";
import CustomInput from "../../Components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { BiLoader } from "react-icons/bi";
import CustomSelect from "../../Components/CustomSelect";



import CustomDatePicker from "../../Components/CustomDatePicker";
import doAPI from "../../api";
import { fetchCitiesByState, fetchCountries, fetchStatesByCountry } from "../../redux/LocationSlice";

export default function AddUser({ onFormSubmitSuccess }) {
   const dispatch = useDispatch()
  const services = new Service();
  const currency = useSelector((state) => state.currency)?.currencylist?.data;
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState([]);
   
   const countries = useSelector((state) =>
    state.location.countries.map((country) => ({
      value: country.id,
      label: country.name,
    }))
  );
  const states = useSelector((state) =>
    state.location.states.map((state) => ({
      value: state.id,
      label: state.name,
    }))
  );
  const cities = useSelector((state) =>
    state.location.cities.map((city) => ({
      value: city.id,
      label: city.name,
    }))
   );
   
  const NewProductSizeSchema = Yup.object().shape({
    first_name: Yup.mixed().required("First name is required"),
    family_name: Yup.mixed().required("Family name is required"),
    email: Yup.mixed().required("Email is required"),
    gender: Yup.mixed().required("Gender is required"),
    dob: Yup.mixed().required("Dob is required"),
    mobile: Yup.mixed().required("Mobile is required"),
    address: Yup.mixed().required("Address is required"),
    country_id: Yup.mixed().required("Country is required"),
    state_id: Yup.mixed().required("State is required"),
    city_id: Yup.mixed().required("City is required"),
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      role_id: 4,
      first_name: "",
      family_name: "",
      email: "",
      gender: "",
      dob: "",
      mobile: "",
      address: "",
      password: "Qwerty@123",
      confirmPassword: "Qwerty@123",
      country_id: 0,
      state_id: 0,
      city_id: 0,
    },
    onSubmit: (values, { resetForm }) => {
      let formData = new FormData();
      for (const key in formik.values) {
        if (Object.hasOwnProperty.call(formik.values, key)) {
          formData.append(key, formik.values[key]);
        }
      }

      doAPI.postData("user/register", formData).then((res) => {
        setIsLoading(true);
        if (res.status) {
          notify(res.message);
          onFormSubmitSuccess();
          setIsLoading(false);
        } else {
          notify(res.message[0]?.message[0]?.message, { variant: "error" });
        }
      });
    },
    validationSchema: NewProductSizeSchema,
  });

  useEffect(() => {
   dispatch(fetchCountries());
 }, [dispatch]);

 // useEffect to fetch states based on selected country
 useEffect(() => {
   if (formik.values.country_id !== null) {
     dispatch(fetchStatesByCountry(formik.values.country_id));
   }
 }, [dispatch, formik.values.country_id]);

 // useEffect to fetch cities based on selected state
 useEffect(() => {
   if (formik.values.state_id !== null) {
     dispatch(fetchCitiesByState(formik.values.state_id));
   }
 }, [dispatch, formik.values.state_id]);

  useEffect(() => {
    formik.setFieldValue("password", "Qwerty@123");
    formik.setFieldValue("confirmPassword", "Qwerty@123");
  }, []);

  console.log(states);
  console.log(cities);

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Notification />
        <Row>
          <Col md={6} className="mb-2">
            <Label aria-required>Frist Name</Label>
            <CustomInput
              type="text"
              name="first_name"
              placeholder="First Name"
              className="form-control"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.first_name && formik.errors.first_name ? (
              <div className="error">{formik.errors.first_name}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Family Name</Label>
            <CustomInput
              type="text"
              name="family_name"
              placeholder="Last Name"
              className="form-control"
              value={formik.values.family_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.family_name && formik.errors.family_name ? (
              <div className="error">{formik.errors.family_name}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Select Role</Label>
            <select
              className="form-control"
              name="role_id"
              value={formik.values.role_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value={null}>Select Role</option>
              <option value={2}>Admin</option>
              <option value={3}>User</option>
              <option value={4}>Manager</option>
              <option value={5}>Staff</option>
            </select>
            {formik.touched.role_id && formik.errors.role_id ? (
              <div className="error">{formik.errors.role_id}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Gender</Label>
            <select
              className="form-control"
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value={null}>Select Gender</option>
              <option value={"M"}>Male</option>
              <option value={"F"}>Female</option>
            </select>
            {formik.touched.gender && formik.errors.gender ? (
              <div className="error">{formik.errors.gender}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Email</Label>
            <CustomInput
              type="email"
              name="email"
              placeholder="Email"
              className="form-control"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Mobile</Label>
            <CustomInput
              type="number"
              name="mobile"
              placeholder="Mobile"
              className="form-control"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.mobile && formik.errors.mobile ? (
              <div className="error">{formik.errors.mobile}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label aria-required>Date of birth</Label>
            <CustomDatePicker
              name="dob"
              className="form-control"
              value={formik.values.dob}
              onChange={(value) => formik.setFieldValue("dob", value)}
              onBlur={formik.handleBlur}
            />
            {formik.touched.dob && formik.errors.dob ? (
              <div className="error">{formik.errors.dob}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label aria-required>Select Country</Label>
            <CustomSelect
              className="w-100"
              value={formik.values.country_id}
              onBlur={formik.handleBlur} // pass onBlur handler from Formik form
              form={formik} // pass the formik instance to CustomSelect
              onChange={(value) =>
                formik.setFieldValue("country_id", value.value)
              }
              options={countries}
            />
            {formik.touched.country_id && formik.errors.country_id ? (
              <div className="error">{formik.errors.country_id}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label aria-required>Select State</Label>
            <CustomSelect
              className="w-100"
              value={formik.values.state_id}
              onBlur={formik.handleBlur} // pass onBlur handler from Formik form
              form={formik} // pass the formik instance to CustomSelect
              onChange={(value) =>
                formik.setFieldValue("state_id", value.value)
              }
              options={states}
            />
            {formik.touched.state_id && formik.errors.state_id ? (
              <div className="error">{formik.errors.state_id}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label>Select City</Label>
            <CustomSelect
              className="w-100"
              value={formik.values.city_id}
              onBlur={formik.handleBlur} // pass onBlur handler from Formik form
              form={formik} // pass the formik instance to CustomSelect
              onChange={(value) => formik.setFieldValue("city_id", value.value)}
              options={cities}
            />
          </Col>

          <Col md={12} className="mb-2">
            <Label aria-required>Address</Label>
            <textarea
              rows="3"
              type="text"
              name="address"
              placeholder="Address"
              className="form-control"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            {formik.touched.address && formik.errors.address ? (
              <div className="error">{formik.errors.address}</div>
            ) : null}
          </Col>

          <Col md={12} className="mb-2">
            <Button
              type="submit"
              size="lg"
              className="px-md-5 w-100"
              variant="primary"
            >
              {isLoading ? <BiLoader className="loader" /> : "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

// colorSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../Components/Utils';

// Define the initial state for the color slice
const initialState = {
   colorlist: [],
   colorDetails: {},
   color: null,
   status: 'idle',
   error: null,
};
const baseurl = process.env.REACT_APP_BASE_URL

// Define an async thunk for fetching the color list
export const fetchColorList = createAsyncThunk('color/fetchList', async (_, { rejectWithValue }) => {
   try {
      const response = await fetch(`${baseurl}/color/list`);
      const data = await response.json();
      return data;
   } catch (error) {
      return rejectWithValue(error.message);
   }
});

// Define an async thunk for fetching color details by ID
export const fetchColorDetails = createAsyncThunk('color/fetchDetails', async (colorId, { rejectWithValue }) => {
   try {
      const response = await fetch(`${baseurl}/color/details/${colorId}`);
      const data = await response.json();
      return data;
   } catch (error) {
      return rejectWithValue(error.message);
   }
});


// Create the color slice
const colorSlice = createSlice({
   name: 'color',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchColorList.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(fetchColorList.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.colorlist = action.payload;
         })
         .addCase(fetchColorList.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload; // Now storing the error message in the state
         })

         .addCase(fetchColorDetails.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(fetchColorDetails.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.color = action.payload;
         })
         .addCase(fetchColorDetails.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload; // Now storing the error message in the state
         });
   },
});

export default colorSlice.reducer;

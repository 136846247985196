// productSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../http";

// Define an initial state
const initialState = {
  productList: {
    rows: [],
    sortOrder: "asc", 
    sortByDate:"asc"
  },
  loading: false,
  error: null,
};

// Create an instance of the Service class
const service = new Service();

export const fetchProductList = createAsyncThunk(
  "products/fetchProductList",
  async ({
    page = "",
    search = "",
    color = "",
    size = "",
    price = "",
    category = "",
    isActive = "",
    sku = "",
    name = "",
  }) => {
    try {
      const params = new URLSearchParams({
        page,
        search,
        color,
        category,
        size,
        price,
        isActive,
        sku,
        name,
      }).toString();
      const url = `product/list?${params}`;
      const data = await service.get(url);
      return data?.data;
    } catch (error) {
      throw Error("Failed to fetch product");
    }
  }
);

export const fetchProductListAll = createAsyncThunk(
  "products/fetchProductListAll",
  async ({
    page = "",
    search = "",
    color = "",
    size = "",
    price = "",
    category = "",
    isActive = "",
    sku = "",
    name = "",
  }) => {
    try {
      const params = new URLSearchParams({
        page,
        search,
        color,
        category,
        size,
        price,
        isActive,
        sku,
        name,
      }).toString();
      const url = `product/list-all?${params}`;
      const data = await service.get(url);
      return data?.data;
    } catch (error) {
      throw Error("Failed to fetch product");
    }
  }
);

// Create the slice
const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    sortByName: (state, action) => {
      const sortOrder = state.productList.sortOrder === "asc" ? "desc" : "asc";
      state.productList.sortOrder = sortOrder;

      state.productList.rows.sort((a, b) =>
        sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      );
    },
    sortByDate: (state, action) => {
      const sortByDate = state.productList.sortByDate === "asc" ? "desc" : "asc";
      state.productList.sortByDate = sortByDate;

      state.productList.rows.sort((a, b) =>
        sortByDate === "asc"
          ? a.created_at.localeCompare(b.created_at)
          : b.created_at.localeCompare(a.created_at)
      );
    },
    sortByStatus: (state, action) => {
      const sortOrder = state.productList.sortOrder === "asc" ? "desc" : "asc";
      state.productList.sortOrder = sortOrder;

      state.productList.rows.sort((a, b) =>
        sortOrder === "asc"
          ? a.is_active - b.is_active
          : b.is_active - a.is_active
      );
    },
    sortByColorId: (state, action) => {
      const sortOrder = state.productList.sortOrder === "asc" ? "desc" : "asc";
      state.productList.sortOrder = sortOrder;

      state.productList.rows.sort((a, b) =>
        sortOrder === "asc" ? a.color_id - b.color_id : b.color_id - a.color_id
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProductList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductList.fulfilled, (state, action) => {
        state.loading = false;
        state.productList = action.payload;
      })
      .addCase(fetchProductList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchProductListAll.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductListAll.fulfilled, (state, action) => {
        state.loading = false;
        state.productList = action.payload;
      })
      .addCase(fetchProductListAll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { sortByName,sortByDate, sortByStatus, sortByColorId } = productSlice.actions;

// Export the reducer
export default productSlice.reducer;

// reviewSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

const initialState = {
  reviews: [],
  review: null,
  status: "idle",
  error: null,
  response: null,
  formInitialValues: {
    rating: 0,
    review: "",
    product_id: null,
  },
};

// Define the base URL for the API
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Async thunk to post a new review
export const postReview = createAsyncThunk(
  "reviews/postReview",
  async (reviewData, { rejectWithValue }) => {
    try {
      const response = await doAPI.postData(`product/review`, reviewData);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to fetch all reviews with parameters
export const fetchAllReviews = createAsyncThunk(
  "reviews/fetchAllReviews",
  async (params, { rejectWithValue }) => {
    try {
      const { page, product_id, keywords, status } = params;
      const queryString = new URLSearchParams({
        page: page || 1,
        product_id: product_id || "",
        keywords: keywords || "",
        status: status || "",
      }).toString();

      const response = await doAPI.getData(`product/review?${queryString}`);
      return await response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to fetch a review by product id
export const fetchReviewByProductId = createAsyncThunk(
  "reviews/fetchReviewByProductId",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await doAPI.getData(
        `product/review-product/${productId}`
      );
      // const data = await response;
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to fetch a review by review id
export const fetchReviewById = createAsyncThunk(
  "reviews/fetchReviewById",
  async (reviewId, { rejectWithValue }) => {
    try {
      const response = await doAPI.getData(`product/review/${reviewId}`);
      // const data = await response;
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to update review status
export const updateReviewStatus = createAsyncThunk(
  "reviews/updateReviewStatus",
  async (statusData, { rejectWithValue }) => {
    try {
      const response = await doAPI.putData(
        `product/review/status-change`,
        statusData
      );
      const data = await response;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postReview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postReview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reviews.push(action.payload);
      })
      .addCase(postReview.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAllReviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllReviews.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reviews = action.payload;
      })
      .addCase(fetchAllReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchReviewByProductId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReviewByProductId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reviews = action.payload;
      })
      .addCase(fetchReviewByProductId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchReviewById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReviewById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.review = action.payload;
      })
      .addCase(fetchReviewById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateReviewStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateReviewStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Update review status logic here if needed
      })
      .addCase(updateReviewStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default reviewSlice.reducer;

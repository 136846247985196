import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import categoryReducer from "./categorySlice"; // Update the import path
import colorReducer from "./colorSlice"; // Update the import path

import productReducer from "./productSlice";
import themeReducer from "./themeSlice";
import userReducer from "./loginSlice";
import orderReducer from "./orderSlice";
import { localStorageMiddleware } from "../middleware/localStorageMiddleware";
import couponReducer from "./couponSlice";
import sizeReducer from "./sizeSlice";
import currencyReducer from "./currencySlice";
import propertyReducer from "./PropertySlice";
import newsletterReducer from "./newsletterSlice";
import carouselReducer from "./carouselSlice";
import testimonialReducer from "./testimonialSlice";
import consignmentReducer from "./consignmentSlice";
import priceActionReducder from "./priceActionSlice";
import vatReducer from "./vatSlice";
import userListReducer from "./usersSlice";
import userDetailsReducer from "./userDetailsSlice";
import userAddressListReducer from "./userAddressSlice";
import orderStatusReducer from "./orderStatusSlice";
import draftOrderReducer from "./draftOrderSlice";
import locationReducer from "./LocationSlice";
import courierCompaniesReducer from "./courierCompaniesSlice";
import assignCourierCompanyReducer from "./assignCourierCompanySlice";
import reviewReducer from "./reviewSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  product: productReducer,
  theme: themeReducer,
  user: userReducer,
  order: orderReducer,
  category: categoryReducer,
  color: colorReducer,
  coupons: couponReducer,
  sizes: sizeReducer,
  currency: currencyReducer,
  property: propertyReducer,
  newsletter: newsletterReducer,
  carousel: carouselReducer,
  testimonial: testimonialReducer,
  consignmentlist: consignmentReducer,
  priceAction: priceActionReducder,
  vatSetting: vatReducer,
  userList: userListReducer,
  userDetails: userDetailsReducer,
  userAddressList: userAddressListReducer,
  orderStatus: orderStatusReducer,
  draftOrders: draftOrderReducer,
  location: locationReducer,
  courierCompanies: courierCompaniesReducer,
  assignCourierCompany: assignCourierCompanyReducer,
  reviews: reviewReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(localStorageMiddleware),
  devTools: true,
});

const persistor = persistStore(store);

export { store, persistor };

import React, { useState, useEffect } from "react";
import Pagelayout from "./../../Layouts/Pagelayout";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Label from "./../../Components/Label";
import CustomSelect from "./../../Components/CustomSelect";
import CustomDatePicker from "./../../Components/CustomDatePicker";
import LTable from "./../../Components/LTable";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import CustomInput from "./../../Components/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import Notification, { notify } from "./../../Components/Notification";
import Service from "./../../http";
import { useNavigate } from "react-router-dom";
import { baseUrl, logoutApp, slugGeneration } from "./../../Components/Utils";
import CommonEditor from "./../../Components/CommonEditor";
import CustomMultiUpload from "./../../Components/CustomMultiUpload";
import AddSize from "./AddSize";
import { BiLoader } from "react-icons/bi";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsignment } from "./../../redux/consignmentSlice";
import TagSelector from "./../../Components/TagSelector";
import doAPI from "../../api";
import CustomCropper from "../../Components/CustomCropper";

export default function AddNewProduct() {
  const dispatch = useDispatch();
  const services = new Service();
  const [editorData, setEditorData] = useState("");
  const [categoryInfo, setCategoryInfo] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCodeFile, setSelectedCodeFile] = useState(null);

  const [productId, setProductId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productTags, setProductTags] = useState();
  const [generatedKeywords, setGeneratedKeywords] = useState([]);
  const [subCategoryInfo, setSubCategoryInfo] = useState([]);

  const [showSize, setShowSize] = useState(false);

  const handleCloseSize = () => setShowSize(false);
  const handleShowSize = (id) => {
    console.log(id, "Size modal is open");
    if (id !== null) {
      setProductId(id);
      setShowSize(true);
    }
  };

  const stocklist =
    useSelector((state) => state?.consignmentlist?.consignments?.rows) || [];

  // console.log(stocklist)
  useEffect(() => {
    dispatch(fetchConsignment());
  }, [dispatch]);

  const navigate = useNavigate();

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    short_description: Yup.string().required("Short Description is required"),
    full_description: Yup.string().required("Full Description is required"),
    slug: Yup.string().required("Slug is required"),
    sku: Yup.string().required("Sku is required"),
    category_id: Yup.number().required("category_id is required"),
    sub_category_id: Yup.number().required("sub_category_id is required"),
    color_id: Yup.number().required("color_id is required"),
    meta_name: Yup.string().required("meta_name is required"),
    meta_description: Yup.string().required("Meta Description is required"),
    meta_keywords: Yup.string().required("Meta Kwywords is required"),
    tag_id: Yup.mixed().required("Tags are required"),
    stock_id: Yup.mixed().required("Stock are required"),
    code_image: Yup.mixed().required("Image are required"),
  });

  const uploadImages = (id) => {
    console.log({ id });
    if (id !== null) {
      const token = () => {
        if (localStorage.getItem("token")) {
          let tokenVal = localStorage.getItem("token").replace(/['"]+/g, "");
          return "Bearer " + tokenVal;
        }
      };

      const baseUrl = process.env.REACT_APP_BASE_URL;
      const headers = {
        Accept: "*",
        "Content-Type": "multipart/form-data",
      };
      const formData = new FormData();
      formData.append("product_id", id);
      selectedFile.forEach((file) => {
        formData.append("product_images", file);
      });
      headers.Authorization = token();
      axios
        .post(baseUrl + "/product/images", formData, { headers })
        .then((res) => {
          console.log({ formData });
          if (res.status) {
            notify(res.message);
            // handleImageChange();
          } else {
            notify(res.message[0]?.message[0]?.message, { variant: "error" });
          }
        })
        .catch((error) => {
          console.error("Error uploading images:", error);
        });
    }
  };
  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      name: "",
      slug: "",
      product_id: 0,
      sku: "",
      short_description: "",
      full_description: "",
      category_id: null,
      sub_category_id: null,
      color_id: null,
      meta_name: "",
      meta_description: "",
      meta_keywords: "",
      stock_id: "",
      tag_id: [],
      code_image: null,
      cover_image: null,
    },
    onSubmit: (values, { resetForm }) => {},
    validationSchema: NewProductSchema,
  });
  console.log(formik.values);

  const submitForm = () => {
    if (selectedFile && selectedFile.length > 0) {
      // let formdata = new FormData();
      // for (const key in formik.values) {
      //   if (Object.hasOwnProperty.call(formik.values, key)) {
      //     formdata.append(key, formik.values[key]);
      //   }
      // }
      // formdata.append("code_image", selectedCodeFile[0]);
      formik.setFieldValue("tag_id", formik.values.tag_id.replace(/^,/, ""));
      doAPI
        .postWithImage("/product/add-update", formik.values)
        .then((res) => {
          res?.response?.data?.message.map((i) => notify(i.message));
          if (res.status) {
            console.log(res?.data?.data?.id);
            setIsFormSubmitted(true);
            notify(res.message);
            setShowSize(true);
            setProductId(res?.data?.data?.id);
            uploadImages(res?.data?.data?.id || productId);
            setCategoryId(res.data?.category_id);
          } else {
            res.message.map((i) => console.log(i.message));
            res.message.map((i) => notify(i.message));
          }
        })
        .catch((err) => {
          err?.response?.data?.message.map((i) => notify(i.message));
        });
    } else {
      notify("* Please Select Image", { variant: "error" });
    }
  };

  useEffect(() => {
    formik.setFieldValue("meta_keywords", generatedKeywords);
  }, [generatedKeywords]);

  useEffect(() => {
    formik.setFieldValue(
      "slug",
      slugGeneration(formik.values.name, formik.values.sku)
    );
  }, [formik.values.name, formik.values.sku]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleImageChange = (selectedFiles) => {
    if (selectedFiles) {
      setSelectedFile(selectedFiles);
    }
  };

  const handleImageCodeImage = (selectedFiles) => {
    if (selectedFiles) {
      setSelectedCodeFile(selectedFiles);
      formik.setFieldValue("code_image", selectedFiles[0]);
      formik.setFieldValue("cover_image", selectedFiles[0]);
    }
  };

  const [croppedImage, setCroppedImage] = useState(null);

  const handleImageCropped = (croppedImageFile) => {
    // Do something with the cropped image file
    console.log("Cropped Image File:", croppedImageFile);
    formik.setFieldValue("code_image", croppedImageFile);
    formik.setFieldValue("cover_image", croppedImageFile);
    // Set it in the state if needed
    setCroppedImage(croppedImageFile);
  };

  useEffect(() => {
    let isMounted = true;
    services.get("category/list").then((res) => {
      if (isMounted) {
        setCategoryInfo(res.data);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    services
      .get("category/sub-category/" + formik.values.category_id)
      .then((res) => {
        if (isMounted) {
          setSubCategoryInfo(res.data);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [formik.values.category_id]);

  useEffect(() => {
    services &&
      services.get("tag/list").then((res) => {
        setProductTags([]);
        res?.data.map((i) => {
          const newObj = { value: i.id, label: i.name };
          setProductTags((oldTags) => {
            const updatedTags = Array.isArray(oldTags) ? oldTags : [];
            return [...updatedTags, newObj];
          });
        });
      });
  }, []);

  useEffect(() => {
    let isMounted = true;
    services.get("color/list").then((res) => {
      if (isMounted) {
        setColorList(res.data);
      }
    });
  }, []);

  const handleTagsChange = (keywords) => {
    const updatedList = [];
    keywords && keywords.map((i) => updatedList.push(i.value));
    Array.isArray(updatedList) && formik.setFieldValue("tag_id", updatedList);
  };

  const AddTag = (id) => {
    formik.setFieldValue("tag_id", formik.values.tag_id + "," + id);
  };

  const onDeleteTag = (id) => {
    if (formik.values.tag_id.split(",").includes(id.toString())) {
      const updatedTags = formik.values.tag_id
        .split(",")
        .filter((tagId) => tagId !== id.toString())
        .join(",");
      formik.setFieldValue("tag_id", updatedTags);
    }
  };

  return (
    <Pagelayout
      title="Add New Product"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Products", link: "/products" },
        { title: "Create New Product", link: "#" },
      ]}
    >
      <Notification />
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={8}>
            <Card className="overflow-visible mb-4">
              <Card.Body>
                <Row>
                  <Col md={12} className="mb-3">
                    <Label aria-required>Product Name</Label>
                    <CustomInput
                      type="text"
                      name="name"
                      placeholder="Product Name"
                      className="form-control"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="error">{formik.errors.name}</div>
                    ) : null}
                  </Col>
                  <Col md={12} className="mb-3">
                    <h6 className="fw-bold">Slug - {formik.values.slug}</h6>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label aria-required>Short Description</Label>
                    <CommonEditor
                      value={formik.values.short_description}
                      onChange={(value) =>
                        formik.setFieldValue("short_description", value)
                      }
                    />

                    {formik.touched.short_description &&
                    formik.errors.short_description ? (
                      <div className="error">
                        {formik.errors.short_description}
                      </div>
                    ) : null}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label aria-required>Full Description</Label>
                    <CommonEditor
                      value={formik.values.full_description}
                      onChange={(value) =>
                        formik.setFieldValue("full_description", value)
                      }
                    />

                    {formik.touched.full_description &&
                    formik.errors.full_description ? (
                      <div className="error">
                        {formik.errors.full_description}
                      </div>
                    ) : null}
                  </Col>

                  <Col md={8} className="mb-3">
                    <Label aria-required>Images (max 5 Images)</Label>
                    <CustomMultiUpload
                      cropper={false}
                      isFormSubmitted={isFormSubmitted}
                      productId={productId}
                      multiple={true}
                      placeholder="Select files"
                      onChange={handleImageChange}
                      images={selectedFile} // Pass selectedFile as the 'images' prop
                    />
                  </Col>

                  <Col md={4} className="mb-3">
                    <Label aria-required>Physical Image</Label>

                    <CustomCropper
                      isMulti={false}
                      onImageCropped={handleImageCropped}
                    />
                    {/* <CustomMultiUpload
                      cropper={false}
                      isFormSubmitted={isFormSubmitted}
                      productId={productId}
                      multiple={true}
                      placeholder="Select files"
                      onChange={handleImageCodeImage}
                      images={selectedCodeFile} // Pass selectedFile as the 'images' prop
                    />
                     */}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label aria-required>Meta Name</Label>
                    <CustomInput
                      type="text"
                      name="meta_name"
                      placeholder="Meta Name"
                      className="form-control"
                      value={formik.values.meta_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.meta_name && formik.errors.meta_name ? (
                      <div className="error">{formik.errors.meta_name}</div>
                    ) : null}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label aria-required>Meta Description</Label>
                    <CommonEditor
                      value={formik.values.meta_description}
                      onChange={(value) =>
                        formik.setFieldValue("meta_description", value)
                      }
                    />

                    {formik.touched.name && formik.errors.name ? (
                      <div className="error">{formik.errors.name}</div>
                    ) : null}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label aria-required>Meta Keywords</Label>
                    <CustomInput
                      type="text"
                      name="meta_keywords"
                      placeholder="Meta Keywords"
                      className="form-control"
                      value={formik.values.meta_keywords}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.meta_keywords &&
                    formik.errors.meta_keywords ? (
                      <div className="error">{formik.errors.meta_keywords}</div>
                    ) : null}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label aria-required>Product tags</Label>
                    <TagSelector
                      tags={productTags}
                      value={formik.values.tag_id}
                      AddTag={AddTag}
                      onDeleteTag={onDeleteTag}
                    />
                    {formik.touched.tag_id && formik.errors.tag_id ? (
                      <div className="error">{formik.errors.tag_id}</div>
                    ) : null}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="overflow-visible mb-4">
              <Card.Body>
                <Row>
                  <Col md={6} className="mb-3">
                    <Label aria-required>Select Category</Label>
                    <select
                      name="category_id"
                      className="form-control"
                      onChange={(event) =>
                        formik.setFieldValue("category_id", event.target.value)
                      }
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>Select Category</option>
                      {categoryInfo &&
                        categoryInfo.map((i, index) => (
                          <option key={index} value={i.id}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.category_id && formik.errors.category_id ? (
                      <div className="error">{formik.errors.category_id}</div>
                    ) : null}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label aria-required>Select Sub Category</Label>
                    <select
                      name="category_id"
                      className="form-control"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "sub_category_id",
                          event.target.value
                        )
                      }
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>Select Sub Category</option>
                      {subCategoryInfo &&
                        subCategoryInfo.map((i, index) => (
                          <option key={index} value={i.id}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.sub_category_id &&
                    formik.errors.sub_category_id ? (
                      <div className="error">
                        {formik.errors.sub_category_id}
                      </div>
                    ) : null}
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label aria-required>Product SKU</Label>
                    <CustomInput
                      type="text"
                      name="sku"
                      placeholder="Product SKU"
                      className="form-control"
                      value={formik.values.sku}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.sku && formik.errors.sku ? (
                      <div className="error">{formik.errors.sku}</div>
                    ) : null}
                  </Col>

                  <Col md={6} className="mb-3">
                    <Label aria-required>Consignment</Label>
                    <select
                      name="stock_id"
                      className="form-control"
                      onChange={(event) =>
                        formik.setFieldValue("stock_id", event.target.value)
                      }
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>Select</option>
                      {stocklist &&
                        stocklist.map((i, index) => (
                          <option
                            selected={i.id === formik.values.stock_id}
                            key={index}
                            value={i.id}
                          >
                            {i.stock_title}
                          </option>
                        ))}
                    </select>
                    {formik.touched.stock_id && formik.errors.stock_id ? (
                      <div className="error">{formik.errors.stock_id}</div>
                    ) : null}
                  </Col>

                  <Col md={6} className="mb-3">
                    <Label aria-required>Select Color</Label>
                    <select
                      name="color_id"
                      className="form-control"
                      onChange={(event) =>
                        formik.setFieldValue("color_id", event.target.value)
                      }
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>Select Color</option>
                      {colorList &&
                        colorList.map((i, index) => (
                          <option key={index} value={i.id}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.color_id && formik.errors.color_id ? (
                      <div className="error">{formik.errors.color_id}</div>
                    ) : null}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Button
                      onClick={() => submitForm()}
                      type="submit"
                      size="lg"
                      className="px-md-5 w-100"
                      variant="primary"
                    >
                      {isLoading ? (
                        <BiLoader className="loader" />
                      ) : (
                        <>Add Product</>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>

      <Modal
        show={showSize}
        backdrop="static"
        keyboard={false}
        size="xl"
        onHide={handleCloseSize}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Size</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddSize
            categoryId={categoryId ? categoryId : categoryId}
            product_id={productId !== null ? productId : productId}
          />
        </Modal.Body>
      </Modal>
    </Pagelayout>
  );
}

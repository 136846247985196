import React, { useEffect, useState } from "react";
import Pagelayout from "../../Layouts/Pagelayout";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
} from "react-bootstrap";
import LTable from "../../Components/LTable";
import Currency from "../../Components/Currency";
import CustomSelect from "../../Components/CustomSelect";
import CustomDatePicker from "../../Components/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import Service from "../../http";
import { Link, useNavigate } from "react-router-dom";
import Image from "../../Components/Image";
import { MdEdit, MdOutlineDescription } from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  amount,
  formatDate,
  getCountryName,
  tableSerialNumber,
} from "../../Components/Utils";
import CustomInput from "../../Components/CustomInput";
import Pagination from "../../Components/Pagination";
// import FetchColorName from "../../Components/FetchColorName";
// import FetchSize from "../../Components/FetchSize";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import Label from "../../Components/Label";
import { fetchDraftOrders } from "../../redux/draftOrderSlice";
import CityStateCountryFetcher from "../../Components/CityStateCountryFetcher";
import doAPI from "../../api";
export default function DraftOrders() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [showItemList, setShowItemList] = useState(false);
  const [order__item, setOrder__item] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchId, setSearchId] = useState("");
  const [searchSku, setSearchSku] = useState("");
  const [searchType, setSearchType] = useState(1);
  const [filterStatus, setFilterStatus] = useState("");
  const [productDetails, setProductDetails] = useState(null);
  const handleCloseItemList = () => setShowItemList(false);

  const handleShowItemList = (id) => {
    doAPI.getData(`order/draft-detail/${id}`).then((response) => {
      setOrder__item(response?.data?.data);
      setShowItemList(true);
    });
  };

  const orderStatus = useSelector(
    (state) => state.orderStatus?.status?.data?.data
  );

  // const [orders, setOrders] = useState([]);

  const orders =
    useSelector((state) => state?.draftOrders?.orders?.data?.data) || [];

  const fetchEntry = () => {
    dispatch(
      fetchDraftOrders({
        page: page,
        shop: "",
        from_date:
          (fromDate !== "" && new Date(fromDate).toISOString().split("T")[0]) ||
          "",
        to_date:
          (toDate !== "" && new Date(toDate).toISOString().split("T")[0]) || "",
        orderId: searchId,
        sku: searchSku,
        status: filterStatus,
      })
    );
  };

  useEffect(() => {
    fetchEntry();
  }, [searchQuery, page, fromDate, toDate, searchId, searchSku, filterStatus]);

  const searchName = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(parseInt(newPage));
  };

  const handleGoToPage = (event) => {
    setPage(parseInt(event.target.value));
  };

  return (
    <Pagelayout
      title="Draft Order List"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Orders", link: "/order-list" },
      ]}
    >
      <Card className="overflow-visible">
        <Card.Body>
          <div className="table_filter equal_space_">
            <div className="table_filter_item align-items-center">
              <Label>From</Label>
              <CustomDatePicker
                name="dob"
                className="form-control"
                value={fromDate}
                onChange={(value) => setFromDate(value)}
                // onBlur={formik.handleBlur}
              />
            </div>
            <div className="table_filter_item align-items-center">
              <Label>To</Label>
              <CustomDatePicker
                name="dob"
                className="form-control"
                value={toDate}
                onChange={(value) => setToDate(value)}
                // onBlur={formik.handleBlur}
              />
            </div>
            <div className="table_filter_item">
              <select
                className="form-control"
                style={{ width: 180 }}
                onChange={(event) => setSearchType(event.target.value)}
              >
                <option value={1}>Search By Name</option>
                <option value={2}>Search By Order Id</option>
                <option value={3}>Search By SKU</option>
              </select>
              {searchType === 1 ? (
                <CustomInput
                  className="form-control"
                  onChange={(event) => searchName(event)}
                  type="search"
                  placeholder="Search Customer Name..."
                />
              ) : searchType === 2 ? (
                <CustomInput
                  className="form-control"
                  onChange={(event) => setSearchId(event.target.value)}
                  type="search"
                  placeholder="Search order id..."
                />
              ) : (
                <CustomInput
                  className="form-control"
                  onChange={(event) => setSearchSku(event.target.value)}
                  type="search"
                  placeholder="Search SKU..."
                />
              )}
            </div>
          </div>
          <LTable>
            <thead>
              <tr>
                <th>#</th>
                <th>Reference No.</th>
                <th>Items</th>
                <th>User Info</th>
                <th>Order On</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {orders?.result &&
                orders?.result.map((i, index) => {
                  const serialNumber = tableSerialNumber(
                    index,
                    orders?.cur_page,
                    orders?.result.length,
                    25
                  );
                  return (
                    <tr key={index}>
                      <td>{serialNumber}</td>
                      <td>{i?.reference_number}</td>
                      <td>
                        <Link onClick={() => handleShowItemList(i?.id)} to="">
                          Items
                        </Link>
                      </td>
                      <td>
                        <p className="fw-bold mb-0">{i?.user?.first_name} </p>
                        <small className="lh-base d-inline-block">
                          <AiOutlineMail className="h6 m-0" /> {i?.user?.email}
                        </small>
                      </td>

                      <td>{formatDate(i?.created_at)}</td>

                      <td>
                        <Currency />
                        {amount(i?.total_amount)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </LTable>
          <Pagination
            pagecount={orders?.total_pages}
            handleGoToPage={handleGoToPage}
            currentpage={page || 1}
            onPageChange={handlePageChange}
          />
        </Card.Body>
      </Card>

      <Modal show={showItemList} size="xl" onHide={handleCloseItemList}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {JSON.stringify(order__item)} */}
          <Row>
            <Col>
              <p className="mb-1">
                Payment Method - {order__item.orderPayment?.payment_type?.name}{" "}
                &nbsp;
                {order__item.orderPayment?.payment_type?.name === "Cash" && (
                  <Image
                    height="20"
                    src={require("../../public/images/icons/Cash.png")}
                  />
                )}
                {order__item.orderPayment?.payment_type?.name === "COD" && (
                  <Image
                    height="20"
                    src={require("../../public/images/icons/COD.png")}
                  />
                )}
                {order__item.orderPayment?.payment_type?.name ===
                  "Credit / Debit Card" && (
                  <Image
                    height="20"
                    src={require("../../public/images/icons/Credit  Debit Card.png")}
                  />
                )}
                {order__item.orderPayment?.payment_type?.name === "Paypal" && (
                  <Image
                    height="20"
                    src={require("../../public/images/icons/Paypal.png")}
                  />
                )}
              </p>
              <p className="mb-1">
                Order Status -&nbsp;
                {order__item?.orderStatus?.system_status === "Processing" && (
                  <Badge bg="warning">{"Processing"}</Badge>
                )}
                {order__item?.orderStatus?.system_status === "Shipped" && (
                  <Badge bg="primary">{"Shipped"}</Badge>
                )}
                {order__item?.orderStatus?.system_status === "In transit" && (
                  <Badge bg="success">{"In transit"}</Badge>
                )}
                {order__item?.orderStatus?.system_status === "Delivered" && (
                  <Badge bg="success">{"Delivered"}</Badge>
                )}
                {order__item?.orderStatus?.system_status ===
                  "Cancellation Requested" && (
                  <Badge bg="danger">{"Cancellation Requested"}</Badge>
                )}
                {order__item?.orderStatus?.system_status === "Cancelled" && (
                  <Badge bg="danger">{"Cancelled"}</Badge>
                )}
                {order__item?.orderStatus?.system_status === "Refund" && (
                  <Badge bg="dark">{"Refund"}</Badge>
                )}
              </p>
              <p className="mb-1">Order Note - {order__item?.order_notes}</p>
            </Col>
            <Col className="text-end">
              <p>Date - {formatDate(order__item?.created_at)}</p>
            </Col>
          </Row>
          <hr />
          <h6>Order Summery</h6>
          <LTable>
            <tbody>
              {order__item?.orderItems &&
                order__item?.orderItems.map((j, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td width={50}>
                      <Image
                        src={
                          j?.product?.product_images[0]?.image_path +
                          "/" +
                          j?.product?.product_images[0]?.image_name
                        }
                        height={50}
                        width={50}
                        style={{ objectFit: "cover" }}
                      />
                    </td>
                    <td>
                      <h6>
                        {j?.product?.name},<br />
                        <small className="fw-normal text-muted">
                          Category - {j?.product?.category?.name}
                          <br />
                          SKU - {j?.product?.sku} &nbsp;|&nbsp; Color -{" "}
                          {j.color} &nbsp;|&nbsp; Size - {j?.size}
                        </small>
                      </h6>
                    </td>
                    <td>Qty - {j?.quantity}</td>
                    <td>
                      <b>Price</b>
                      <br />
                      <Currency />
                      {amount(j?.total_price)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </LTable>
          <hr className="mt-0" />
          <h6>
            Copupon Amount - <Currency />
            {amount(order__item?.coupon_amount)}
          </h6>
          <h6>
            Shipping Charge - <Currency />
            {amount(order__item?.shipping_amount)}
          </h6>
          <h5>
            Total - <Currency />
            {amount(order__item?.total_amount)}
          </h5>
        </Modal.Body>
      </Modal>
    </Pagelayout>
  );
}

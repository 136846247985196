// sizesSlice.js

import { createSlice } from '@reduxjs/toolkit';
import doAPI from '../api';

const initialState = {
  sizes: [],
  loading: false,
  error: null,
};

const sizesSlice = createSlice({
  name: 'sizes',
  initialState,
  reducers: {
    sizesLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    sizesLoaded: (state, action) => {
      state.sizes = action.payload;
      state.loading = false;
      state.error = null;
    },
    sizesError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { sizesLoading, sizesLoaded, sizesError } = sizesSlice.actions;

// Asynchronous thunk action for fetching sizes from the API
export const fetchSizes = () => async (dispatch) => {
  dispatch(sizesLoading());
  const apiUrl = `size/size-name/list`;
  try {
    const response = await doAPI.getData(apiUrl);
    dispatch(sizesLoaded(response.data));
  } catch (error) {
    dispatch(sizesError(error.message || 'Failed to fetch sizes'));
  }
};

export default sizesSlice.reducer;

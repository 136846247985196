import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import Notification from "../../Components/Notification";
import LTable from "../../Components/LTable";
import Pagelayout from "../../Layouts/Pagelayout";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNewletterList,
  fetchNewsletterList,
} from "../../redux/newsletterSlice";
import {
  formatDate,
  getCountryName,
  tableSerialNumber,
} from "../../Components/Utils";
import PhoneNumberFormatter from "../../Components/PhoneNumberFormatter";
import Pagination from "../../Components/Pagination";
import { fetchCountries } from "../../redux/LocationSlice";

export default function Newsletter() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const newsletters = useSelector((state) => state.newsletter);

  useEffect(() => {
    dispatch(fetchNewsletterList(page));
  }, [dispatch, page]);

  const handlePageChange = (newPage) => {
    setPage(parseInt(newPage));
  };

  const handleGoToPage = (event) => {
    setPage(parseInt(event.target.value));
  };

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  const countries = useSelector((state) => state.location.countries);
  return (
    <Pagelayout
      title="Newletters"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Newsletter", link: "/newsletter/list" },
      ]}
    >
      <Notification />
      <Card>
        <Card.Body>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              Total Page - {newsletters?.newsletterList?.total_pages} &nbsp; |
              &nbsp; Items on page -{" "}
              {newsletters?.newsletterList?.rows &&
                newsletters?.newsletterList?.rows.length}{" "}
              &nbsp; | &nbsp; Total Items - {newsletters?.newsletterList?.count}
            </div>
            <Pagination
              pagecount={newsletters?.newsletterList?.total_pages}
              handleGoToPage={handleGoToPage}
              currentpage={page || 1}
              onPageChange={handlePageChange}
            />{" "}
          </div>
          <LTable isLoading={newsletters.loading}>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Country</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Business Name</th>
                <th>Start Date</th>
              </tr>
            </thead>
            <tbody>
              {newsletters?.newsletterList.rows &&
                newsletters?.newsletterList.rows.map((i, index) => {
                  const serialNumber = tableSerialNumber(
                    index,
                    page,
                    newsletters?.newsletterList?.count,
                    25
                  );
                  return (
                    <tr key={index}>
                      <td>{serialNumber}</td>
                      <td>
                        {i.first_name} {i.family_name}
                      </td>
                      <td>{i.country.name}</td>
                      <td>
                        {/* {i.mobile_number} */}
                        <PhoneNumberFormatter
                          phoneNumber={i.mobile_number}
                          region={countries&&countries.find(country=>country.id===i.country_id)?.iso2}
                        />
                        {/* {JSON.stringify(countries.find(country=>country.id===i.country_id).iso3)} */}
                      </td>
                      <td>{i.email} </td>
                      <td>{i.business_name} </td>
                      <td>{formatDate(i.created_at)} </td>
                    </tr>
                  );
                })}
            </tbody>
          </LTable>
          <Pagination
            pagecount={newsletters?.newsletterList?.total_pages}
            handleGoToPage={handleGoToPage}
            currentpage={page || 1}
            onPageChange={handlePageChange}
          />
        </Card.Body>
      </Card>
    </Pagelayout>
  );
}

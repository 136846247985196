import React, { useEffect, useState } from 'react'
import Notification, { notify } from '../../Components/Notification'
import { Button, Col, Row, Form } from 'react-bootstrap'
import Label from '../../Components/Label'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import Service from '../../http'
import CustomInput from '../../Components/CustomInput'
import { useSelector } from 'react-redux'
import { BiLoader } from 'react-icons/bi'

export default function AddTheme({ onFormSubmitSuccess }) {
   const services = new Service();
   const currency = useSelector((state) => state.currency)?.currencylist?.data;
   const [isLoading, setIsLoading] = useState(false)

   const NewProductSizeSchema = Yup.object().shape({
      theme_name: Yup.string().required('Theme Name is required'),
      version: Yup.string().required('Version is required'),
      primary: Yup.string().required('Primary color is required'),
      secondary: Yup.string().required('Secondary color is required'),
      background: Yup.string().required('Background color is required')
   });

   const formik = useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
         theme_name: "",
         version: "",
         primary: "",
         secondary: "",
         background: ""
      },
      onSubmit: (values, { resetForm }) => {


      },
      validationSchema: NewProductSizeSchema
   });

   const submitForm = () => {
      let formData = new FormData();
      for (const key in formik.values) {
         if (Object.hasOwnProperty.call(formik.values, key)) {
            formData.append(key, formik.values[key]);
         }
      }

      services && services.post('theme/add', formData).then((res) => {
         setIsLoading(true)
         if (res.status) {
            notify(res.message);
            onFormSubmitSuccess();
            setIsLoading(false)
         } else {
            notify(res.message[0]?.message[0]?.message, { variant: 'error' });
         }
      })
   }

   console.log(formik.values)

   return (
      <>
         <Form onSubmit={formik.handleSubmit}>
            <Notification />
            <Row>
               theme_name: "",
               version: "",
               primary: "",
               secondary: "",
               background: ""

               <Col md={12} className='mb-3'>
                  <Label aria-required>Theme Name</Label>
                  <CustomInput type='text' name="theme_name" placeholder='Theme Name' className='form-control'
                     value={formik.values.theme_name}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.theme_name && formik.errors.theme_name ? (
                     <div className="error">{formik.errors.theme_name}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Label aria-required>Version</Label>
                  <CustomInput type='text' name="version" placeholder='Version' className='form-control'
                     value={formik.values.version}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.version && formik.errors.version ? (
                     <div className="error">{formik.errors.version}</div>
                  ) : null}
               </Col>

               <Col xs={4} className='mb-3'>
                  <Label aria-required>Primary</Label>
                  <CustomInput type='color' name="primary" placeholder='Primary Color' className='form-control'
                     value={formik.values.primary}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.primary && formik.errors.primary ? (
                     <div className="error">{formik.errors.primary}</div>
                  ) : null}
               </Col>

               <Col xs={4} className='mb-3'>
                  <Label aria-required>Secondary</Label>
                  <CustomInput type='color' name="secondary" placeholder='Secondary Color' className='form-control'
                     value={formik.values.secondary}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.secondary && formik.errors.secondary ? (
                     <div className="error">{formik.errors.secondary}</div>
                  ) : null}
               </Col>

               <Col xs={4} className='mb-3'>
                  <Label aria-required>Background</Label>
                  <CustomInput type='color' name="background" placeholder='Background Color' className='form-control'
                     value={formik.values.background}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.background && formik.errors.background ? (
                     <div className="error">{formik.errors.background}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Button
                     // type="submit"
                     size='lg'
                     onClick={() => submitForm()}
                     className="px-md-5 w-100"
                     variant="primary">
                     {isLoading ? <BiLoader className='loader' /> : "Submit"}
                  </Button>
               </Col>
            </Row>
         </Form>
      </>
   )
}

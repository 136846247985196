import React, { useEffect, useState } from 'react'
import Service from '../../../http';
import { useDispatch, useSelector } from 'react-redux';
import Notification, { notify } from '../../../Components/Notification';
import { Button, Card, Dropdown, Modal } from 'react-bootstrap';
import Pagelayout from '../../../Layouts/Pagelayout';
import LTable from '../../../Components/LTable';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { MdDelete, MdEdit } from 'react-icons/md';
import { fetchConsignment, fetchConsignmentList } from '../../../redux/consignmentSlice';
import { formatDate, tableSerialNumber } from '../../../Components/Utils';
import AddConsignment from './AddConsignment';
import { amount } from './../../../Components/Utils';

export default function ConsignmentSetting() {
  const services = new Service();

  const dispatch = useDispatch()
  const consignmentlist = useSelector(state => state.consignmentlist)
  useEffect(() => {
    dispatch(fetchConsignment())
  }, [dispatch])

  const [ConsignmentAddForm, setShowConsignmentAddForm] = useState(false);
  const handleCloseConsignmentAddForm = () => setShowConsignmentAddForm(false);
  const handleShowConsignmentAddForm = () => setShowConsignmentAddForm(true);

  const handleFormSubmitSuccess = () => {
    handleCloseConsignmentAddForm(false)
  }

  const currency = useSelector((state) => state.currency)?.currencylist?.data;

  return (
    <Pagelayout
      title="Consignment Records"
      breadcrumbs={
        [
          { title: 'Dashboard', link: '/dashboard' },
          { title: 'Consignment List', link: '#' },
        ]
      }
      actions={<Button onClick={() => handleShowConsignmentAddForm()}>Add Consignment</Button>}
    >
      <Card>
        <Card.Body>
          <LTable
            isLoading={consignmentlist.status == 'pending'}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Consignment Id</th>
                <th>Total Consignment Cost</th>
                <th>Total Consignment Price (Per Kg)</th>
                <th>Total Consignment Weight (Kg)</th>
                <th>Consignment Received</th>
              </tr>
            </thead>
            <tbody>
              {consignmentlist?.consignments?.rows && consignmentlist?.consignments?.rows.map((i, index) => <tr key={index}>
                <td>{tableSerialNumber(index, consignmentlist?.consignments.cur_page, consignmentlist?.consignments?.rows.length)}</td>
                <td>{i.stock_title}</td>
                <td>{i.currency?.symbol} {amount(i.stock_cost)}</td>
                <td>{i.currency?.symbol} {amount(i.stock_price)}</td>
                <td>{i.stock_weight} KG</td>
                <td>{formatDate(i.created_at)}</td>

              </tr>)}
            </tbody>
          </LTable>
        </Card.Body>
      </Card>


      <Modal show={ConsignmentAddForm} onHide={handleCloseConsignmentAddForm}>
        <Modal.Header closeButton>
          <Modal.Title>Add Consignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddConsignment onFormSubmitSuccess={() => {
            setShowConsignmentAddForm(false)
            dispatch(fetchConsignment())
          }} />
        </Modal.Body>
      </Modal>

    </Pagelayout>

  )
}

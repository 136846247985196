import React, { useEffect, useRef, useState } from 'react';
import Label from '../../Components/Label';
import CustomInput from '../../Components/CustomInput';
import Service from '../../http';
import { Button, Card, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import Notification, { notify } from '../../Components/Notification';
import { FiMinus } from 'react-icons/fi'; import { Link, useNavigate } from 'react-router-dom';
import { Formik, useFormik, Form, FieldArray, Field } from 'formik';
import { BiCopy, BiLoader, BiTrash } from 'react-icons/bi';
import { fetchCurrencyList } from '../../redux/currencySlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVATInfo } from '../../redux/vatSlice';
import doAPI from '../../api';
import { AiOutlinePlus } from 'react-icons/ai';

export default function EditSize({ product_id, categoryId, sizeData, closepopup }) {
   const formRef = useRef();
   const services = new Service();
   const navigate = useNavigate()
   const dispatch = useDispatch();
   const [weightByIndex, setWeightByIndex] = useState([])
   const [sizeLimit, setSizeLimit] = useState(1)
   const [loadingWeight, setLoadingWeight] = useState(null)
   const [isLoading, setIsLoading]= useState(false)
   const [storeSizeVal, setStoreSizeVal] = useState([])
   const [sizeList, setSizeList] = useState([]);
   const [masterGramList, setMasterGramList] = useState([]);
   const colorList = useSelector((state) => state.color?.colorlist?.data);

   const currency = useSelector((state) => state.currency?.currencylist?.data);

   useEffect(() => {
      dispatch(fetchCurrencyList());
      dispatch(fetchVATInfo());
   }, [dispatch]);

   const vatInfo = useSelector((state) => state?.vatSetting?.vatInfo);

   const validationSchema = Yup.object().shape({
      sizes: Yup.array().of(
         Yup.object().shape({
            stock: Yup.number().required('Stock is required'),
            weight: Yup.number().required('Weight is required'),
            length: Yup.number().required('Length is required'),
            bar_code: Yup.string().required('Barcode is required'),
            size_name_id: Yup.number().required('Size Name ID is required'),
            master_gram_id: Yup.number().required('Master Gram ID is required'),
            color_id: Yup.number().required("Color is required"),

            size_prices: Yup.array().of(
               Yup.object().shape({
                  price_gram: Yup.number().required('Price per Gram is required'),
                  price_piece: Yup.number().required('Price per Piece is required'),
                  discount: Yup.number().required('Discount is required'),
                  currency_id: Yup.number().required('Currency ID is required'),
                  vat_gram: Yup.number().required('VAT per Gram is required'),
                  vat_piece: Yup.number().required('VAT per Piece is required'),
                  vat_percentage: Yup.number().required('VAT Percentage is required'),
               })
            ),
         })
      ),
   });

   const [initialValues, setInitialValues] = useState({
      sizes: [
         {
            id: 0,
            stock: 0,
            weight: 0,
            length: 0,
            bar_code: '',
            size_name_id: 0,
            master_gram_id: 0,
            is_deleted: 0,
            color_id: 0,
            size_prices: [
               {
                  price_gram: 0,
                  price_piece: 0,
                  discount: 0,
                  currency_id: 1,
                  vat_gram: 0,
                  vat_piece: 0,
                  vat_percentage: 0,
               },
               {
                  price_gram: 0,
                  price_piece: 0,
                  discount: 0,
                  currency_id: 2,
                  vat_gram: 0,
                  vat_piece: 0,
                  vat_percentage: 0,
               },
            ],
         },
      ],
   });

   useEffect(() => {
      // Populate sizes from the API response

      if (sizeData) {
         const updatedSizes = sizeData.map(i => ({
            id: i?.id || 0,
            stock: i?.stock || 0,
            weight: i?.weight || 0,
            length: i?.length || 0,
            bar_code: i?.bar_code || null,
            size_name_id: i?.size_name_id || 0,
            master_gram_id: i?.master_gram_id || 0,
            color_id: i?.color_id || 0,
            is_deleted: 0,
            size_prices: i?.size_prices.map(j => ({
               price_gram: j.price_gram,
               price_piece: j.price_piece,
               discount: j.discount,
               currency_id: j.currency_id || 1,
               vat_gram: j.vat_gram || 0,
               vat_piece: j.vat_piece || 0,
               vat_percentage: j.vat_percentage || 0,
            })),
         }));

         setInitialValues({ product_id, sizes: updatedSizes });
      }
   }, [sizeData, setInitialValues]);


   const fetchEntry = () => {
      services && services.get('master/list-gram').then((res) => {
         setMasterGramList(res.data.rows);
      });
   };


   useEffect(() => { fetchEntry() }, []);

   const handleSubmit = async (values, formikBag) => {

      try {
         // Make the API call to update the data
         const res = await doAPI.putData('size/update', values);
         setIsLoading(true)
         if (res.status) {
            notify(res.message);
            setTimeout(() => {
               navigate('/products');
               window.location.reload();
               setIsLoading(false)
            }, 300);
         } else {
            // Handle error
            console.error(res.message);
         }
      } catch (error) {
         console.error('API request failed:', error.response.data.message);
         error.response.data.message.map(i=>notify(i.message))
      };
   };

   useEffect(() => {
      services && services.get('size/size-name/list?page=1&status=1&category_id=' + categoryId).then((res) => {
         setSizeList(res.data);
         setSizeLimit(res.data.length)
      });
   }, [categoryId]);

   const fetchWeightEntry = async (weight, index) => {
      setLoadingWeight(index)
      const response = await doAPI.getData('master/gram-price?weight=' + weight);
      if (response.status===200) {
         setLoadingWeight(null)
      }
      setWeightByIndex(old => {
         const newArray = [...old];
         const existingIndex = newArray.findIndex(item => item && item.index === index);

         if (existingIndex !== -1) {
            newArray[existingIndex] = response.data?.data;
         } else {
            newArray[index] = response.data?.data;
         }
         return newArray;
      });
   };

   useEffect(() => {
      sizeData.map((i, index) => fetchWeightEntry(i.weight, index))
   }, [])


   return (
      <>
         <Formik
            innerRef={formRef}
            initialValues={{
               product_id,
               sizes: sizeData && sizeData,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikBag) => handleSubmit(values, formikBag)}
         >

            {({ values, setFieldValue }) => (
               <Form className='sizeForm'>
                  {setStoreSizeVal(values)}
                  <FieldArray name="sizes">
                     {({ push, remove }) => (
                        <div>
                           {values.sizes.filter(d=>d.is_deleted==0).map((size, index) => (<Card className='mb-3 border'>
                              <Card.Body>
                                 <Row className='gx-2 mb-3' key={index}>
                                 {loadingWeight==index&&<div className='customLoadingScreen'>Loading...</div>}
                                    <Col style={{ minWidth: 120 }} lg={2} className='mb-2 w-10'>
                                       <Label>Size</Label>
                                       <Field className="form-control" as="select" name={`sizes[${index}].size_name_id`} placeholder="Size Name">
                                          <option value={0}>Select Size</option>
                                          {sizeList && sizeList.map((i, index) => <option key={index} value={i.id} selected={i.id == values.sizes[index]?.size_name_id}>{i.name}</option>)}
                                       </Field>
                                    </Col>
                                    <Col style={{ minWidth: 120 }} lg={2} className='mb-2 w-10'>
                                       <Label>Stock</Label>
                                       <Field className="form-control" type="text" name={`sizes[${index}].stock`} placeholder="Stock" />
                                    </Col>
                                    <Col style={{ minWidth: 120 }} lg={2} className='mb-2 w-10'>
                                       <Label>Weight</Label>
                                       <Field
                                          className="form-control"
                                          type="text"
                                          name={`sizes[${index}].weight`}
                                          placeholder="Weight"
                                          onChange={(e) => {
                                             fetchWeightEntry(e.target.value, index);
                                             weightByIndex[index]?.id > 0 && setFieldValue(`sizes[${index}].master_gram_id`, weightByIndex[index]?.id);
                                             setFieldValue(`sizes[${index}].weight`, e.target.value);

                                          }}
                                       />
                                    </Col>
                                    {/* {weightByIndex[index]?.id} */}
                                    <Col style={{ minWidth: 120 }} lg={2} className='mb-2 w-10'>
                                       <Label>Weight Range</Label>
                                       <Field disabled className="form-control" type="text" value={weightByIndex[index]?.title} placeholder="Weight Range"
                                       />
                                    </Col>

                                    <Col className='mb-2 w-10'>
                                       <Label>Bar Code</Label>
                                       <Field className="form-control" required type="text" name={`sizes[${index}].bar_code`} placeholder="Bar Code"
                                          onChange={(e) => {
                                             setFieldValue(`sizes[${index}].bar_code`, e.target.value);
                                             setFieldValue(`sizes[${index}].master_gram_id`, weightByIndex[index]?.id);
                                          }} />
                                    </Col>

                                    <Col className="mb-2 w-10">
                            <Label>Color</Label>
                            <Field
                              className="form-control"
                              as="select"
                              name={`sizes[${index}].color_id`}
                              placeholder="Size Name"
                            >
                              <option value={0}>Select Color</option>
                              {colorList &&
                                colorList.map((i, index) => (
                                  <option
                                    key={index}
                                    value={i.id}
                                    selected={
                                      i.id == values.sizes[index]?.color_id
                                    }
                                  >
                                    {i.name}
                                  </option>
                                ))}
                            </Field>
                          </Col>

                                    {size.size_prices.map((price, priceIndex) => (

                                       <Col lg={6} className=' mt-0'>
                                          <Row className='gx-2' key={priceIndex}>
                                             <Col style={{ minWidth: 100 }} className='mb-2'>
                                                <Label>Price / Gm ({currency.find(k => k.id == values.sizes[index]?.size_prices[priceIndex]?.currency_id)?.short_code})</Label>

                                                <Field
                                                   disabled
                                                   className="form-control"
                                                   type="text"
                                                   value={
                                                      weightByIndex[index]?.setting_prices && weightByIndex[index]?.setting_prices.find(k => k.currency_id == values.sizes[index]?.size_prices[priceIndex]?.currency_id).price_gram
                                                         ? weightByIndex[index]?.setting_prices.find(k => k.currency_id == values.sizes[index]?.size_prices[priceIndex]?.currency_id).price_gram
                                                         : values.sizes[index].size_prices[priceIndex].price_gram}
                                                   name={`sizes[${index}].size_prices[${priceIndex}].price_gram`} placeholder="Price per Gram"

                                                />

                                             </Col>
                                             <Col style={{ minWidth: 100 }} className='mb-2'>
                                                <Label>Price / Piece ({currency.find(k => k.id == values.sizes[index]?.size_prices[priceIndex]?.currency_id)?.short_code})</Label>
                                                <Field min={0} className="form-control" type="text" name={`sizes[${index}].size_prices[${priceIndex}].price_piece`} placeholder="Price per Piece"
                                                   onBlur={(e) => {
                                                      const currentValue = e.target.value;
                                                      if (currentValue && currentValue.indexOf('.') === -1) {
                                                         // Append ".00" to the value
                                                         const updatedValue = `${currentValue}.00`;
                                                         setFieldValue(`sizes[${index}].size_prices[${priceIndex}].price_piece`, updatedValue);
                                                         e.target.value = updatedValue; // Update the input field value
                                                      }
                                                   }}
                                                />
                                             </Col>
                                             <Col style={{ minWidth: 100 }} className='mb-2'>
                                                <Label>Discount ({currency.find(k => k.id == values.sizes[index]?.size_prices[priceIndex]?.currency_id)?.short_code})</Label>
                                                <Field min={0} className="form-control" type="text" name={`sizes[${index}].size_prices[${priceIndex}].discount`} placeholder="Discount"
                                                   onBlur={(e) => {
                                                      // Check if the value is not empty and doesn't already contain a decimal point
                                                      const currentValue = e.target.value;
                                                      if (currentValue && currentValue.indexOf('.') === -1) {
                                                         // Append ".00" to the value
                                                         const updatedValue = `${currentValue}.00`;
                                                         setFieldValue(`sizes[${index}].size_prices[${priceIndex}].discount`, updatedValue);
                                                         e.target.value = updatedValue; // Update the input field value
                                                      }
                                                   }}
                                                />
                                             </Col>

                                             {vatInfo && vatInfo.find(j => j.currency_id == values.sizes[index]?.size_prices[priceIndex]?.currency_id)?.is_vat > 0 && <>
                                                <Col lg={2} sm={3} xs={6} className='mb-2'>
                                                   <Label>VAT Per Gram</Label>
                                                   <Field className="form-control" type="text" name={`sizes[${index}].size_prices[${priceIndex}].vat_gram`} placeholder="VAT per Gram"
                                                      onBlur={(e) => {
                                                         // Check if the value is not empty and doesn't already contain a decimal point
                                                         const currentValue = e.target.value;
                                                         if (currentValue && currentValue.indexOf('.') === -1) {
                                                            // Append ".00" to the value
                                                            const updatedValue = `${currentValue}.00`;
                                                            setFieldValue(`sizes[${index}].size_prices[${priceIndex}].vat_gram`, updatedValue);
                                                            e.target.value = updatedValue; // Update the input field value
                                                         }
                                                      }}
                                                   />
                                                </Col>
                                                <Col lg={2} sm={3} xs={6} className='mb-2'>
                                                   <Label>VAT Per Piece</Label>
                                                   <Field className="form-control" type="text" name={`sizes[${index}].size_prices[${priceIndex}].vat_piece`} placeholder="VAT per Piece"
                                                      onBlur={(e) => {
                                                         // Check if the value is not empty and doesn't already contain a decimal point
                                                         const currentValue = e.target.value;
                                                         if (currentValue && currentValue.indexOf('.') === -1) {
                                                            // Append ".00" to the value
                                                            const updatedValue = `${currentValue}.00`;
                                                            setFieldValue(`sizes[${index}].size_prices[${priceIndex}].vat_piece`, updatedValue);
                                                            e.target.value = updatedValue; // Update the input field value
                                                         }
                                                      }}
                                                   />
                                                </Col>
                                                <Col lg={2} sm={3} xs={6} className='mb-2'>
                                                   <Label>VAT %</Label>
                                                   <Field className="form-control" type="text" name={`sizes[${index}].size_prices[${priceIndex}].vat_percentage`} placeholder="VAT %"
                                                      onBlur={(e) => {
                                                         // Check if the value is not empty and doesn't already contain a decimal point
                                                         const currentValue = e.target.value;
                                                         if (currentValue && currentValue.indexOf('.') === -1) {
                                                            // Append ".00" to the value
                                                            const updatedValue = `${currentValue}.00`;
                                                            setFieldValue(`sizes[${index}].size_prices[${priceIndex}].vat_percentage`, updatedValue);
                                                            e.target.value = updatedValue; // Update the input field value
                                                         }
                                                      }}
                                                   />
                                                </Col>
                                             </>}
                                          </Row>
                                       </Col>

                                    ))}

                                 </Row>
                                 <Button
                                    className='removebtn'
                                    variant="danger"
                                    size="sm"
                                    onClick={() => {
                                       setFieldValue(`sizes[${index}].is_deleted`, 1);
                                    }}
                                 >
                                    <BiTrash />
                                 </Button>
                                 <Button
                                    className='copybtn'
                                    variant="primary"
                                    size="sm"
                                    onClick={() => {
                                       setFieldValue(`sizes[${index + 1}]`, values.sizes[index]);
                                       setFieldValue(`sizes[${index + 1}].id`, 0);
                                    }}
                                 >
                                    <BiCopy />
                                 </Button>
                              </Card.Body>
                           </Card>
                           ))}
                           <Button
                              disabled={values.sizes.length === sizeLimit}
                              size="lg" type="button" onClick={() => push({
                                 stock: 0,
                                 weight: 0,
                                 length: 0,
                                 bar_code: '',
                                 size_name_id: 0,
                                 master_gram_id: 0,
                                 is_deleted: 0,
                                 id: 0,
                                 color_id:0,

                                 size_prices: [
                                    {
                                       price_gram: 0,
                                       price_piece: 0,
                                       discount: 0,
                                       currency_id: 1,
                                       vat_gram: 0,
                                       vat_piece: 0,
                                       vat_percentage: 0,
                                    },
                                    {
                                       price_gram: 0,
                                       price_piece: 0,
                                       discount: 0,
                                       currency_id: 2,
                                       vat_gram: 0,
                                       vat_piece: 0,
                                       vat_percentage: 0,
                                    },
                                 ],
                              })}>
                              <AiOutlinePlus /> Add Size
                           </Button>
                        </div>
                     )}
                  </FieldArray>
                  <Col className='text-end' style={{ marginTop: -40 }}>
                     <Button size="lg" type="submit"> {isLoading ? <BiLoader className='loader' /> : "Save"}</Button>
                  </Col>

               </Form>
            )}
         </Formik >
      </>
   )
}

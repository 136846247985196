import React, { useEffect, useRef, useState } from "react";
import Pagelayout from "../../Layouts/Pagelayout";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  NavDropdown,
  Overlay,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import LTable from "../../Components/LTable";
import Currency from "../../Components/Currency";
import CustomSelect from "../../Components/CustomSelect";
import CustomDatePicker from "../../Components/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import Service from "../../http";
import { Link } from "react-router-dom";
import Image from "../../Components/Image";
import { MdOutlineDescription, MdOutlineLocalShipping } from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  amount,
  formatDate,
  getCountryName,
  tableSerialNumber,
} from "../../Components/Utils";
import CustomInput from "../../Components/CustomInput";
import Pagination from "../../Components/Pagination";
// import FetchColorName from "../../Components/FetchColorName";
// import FetchSize from "../../Components/FetchSize";
import { AiOutlineClose, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import Label from "../../Components/Label";
import { fetchOrders } from "../../redux/orderSlice";
import { fetchOrderStatus } from "../../redux/orderStatusSlice";
import doAPI from "../../api";
import Notification, { notify } from "../../Components/Notification";
import { HiChevronDown } from "react-icons/hi";
import {
  clearResponse,
  fetchCourierCompanies,
} from "../../redux/courierCompaniesSlice";
// import { fetchOrders } from './../../redux/orderSlice';

import AddCompany from "../CourierCompanies/AddCompany";
import AssignCompany from "../CourierCompanies/AssignCompany";
import OrderStatusTracking from "./OrderStatusTracking";

export default function OrdersList() {
  const dispatch = useDispatch();
  const services = new Service();
  const [showItemList, setShowItemList] = useState(false);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);

  const [order__item, setOrder__item] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [searchId, setSearchId] = useState("");
  const [searchSku, setSearchSku] = useState("");
  const [searchType, setSearchType] = useState(1);
  const [filterStatus, setFilterStatus] = useState("");
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [showAssignCompany, setShowAssignCompany] = useState(null);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    // Set toDate to today's date
    const today = new Date();
    setToDate(today);

    // Calculate 1 month ago
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    // Set fromDate to 1 month ago
    setFromDate(oneMonthAgo);

    // Add 1 day to toDate
    const nextDay = new Date(today);
    nextDay.setDate(nextDay.getDate() + 1);
    setToDate(nextDay);
  }, []);

  const [showAddCompany, setShowAddCompany] = useState(null);
  const target = useRef(null);

  const courierCompanies = useSelector((state) => state.courierCompanies);

  const assignCourierCompanyState = useSelector(
    (state) => state.assignCourierCompany
  );

  useEffect(() => {
    dispatch(fetchCourierCompanies(1));
    dispatch(fetchOrderStatus());
  }, [dispatch]);

  const handleClosePaymentInfo = () => setShowPaymentInfo(false);

  const handleShowPaymentInfo = (data) => {
    setPaymentInfo(data?.orderPayment);
    setShowPaymentInfo(true);
  };

  const handleCloseItemList = () => setShowItemList(false);

  const handleShowItemList = (id) => {
    services &&
      services.get(`order/${id}`).then((res) => {
        setOrder__item(res?.data);
      });
    setShowItemList(true);
  };

  const orderStatus = useSelector(
    (state) => state.orderStatus?.status?.data?.data
  );

  useEffect(() => {
    // Dispatch the async thunk when the component mounts
    dispatch(fetchOrderStatus({ type: "user" }));
  }, [dispatch]);

  // const [orders, setOrders] = useState([]);

  const orders = useSelector((state) => state?.order?.orders?.data?.data) || [];

  const fetchEntry = () => {
    dispatch(
      fetchOrders({
        page: page,
        shop: "",
        from_date:
          fromDate !== "" ? new Date(fromDate).toISOString().split("T")[0] : "",
        to_date:
          toDate !== "" ? new Date(toDate).toISOString().split("T")[0] : "",
        orderId: searchId,
        sku: searchSku,
        status: filterStatus,
      })
    );
  };

  useEffect(() => {
    fetchEntry();
  }, [searchQuery, page, fromDate, toDate, searchId, searchSku, filterStatus]);

  const searchName = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(parseInt(newPage));
  };

  const handleGoToPage = (event) => {
    setPage(parseInt(event.target.value));
  };

  const updateStatus = (order_id, status_id) => {
    doAPI
      .putData("order/status", {
        order_id,
        status_id,
      })
      .then((res) => {
        notify("Order status has been changed");
        fetchEntry();
      });
  };

  const assignToOrder = (id) => {
    console.log(id);
  };

  useEffect(() => {
    if (courierCompanies?.response !== null) {
      notify(courierCompanies?.response?.message);
      dispatch(fetchCourierCompanies(1));
      setShowAddCompany(null);

      setTimeout(() => {
        dispatch(clearResponse());
      }, 1000);
    }
  }, [courierCompanies?.response]);

  useEffect(() => {
    if (assignCourierCompanyState?.response?.status) dispatch(clearResponse());
  }, [assignCourierCompanyState?.response]);

  return (
    <Pagelayout
      title="Order List"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Orders", link: "/order-list" },
      ]}
    >
      <Notification />
      <Card className="overflow-visible">
        <Card.Body>
          <div className="table_filter equal_space_">
            <div className="table_filter_item align-items-center">
              <Label>From</Label>
              <CustomDatePicker
                name="dob"
                className="form-control"
                value={fromDate}
                onChange={(value) => setFromDate(value)}
                // onBlur={formik.handleBlur}
              />
            </div>
            <div className="table_filter_item align-items-center">
              <Label>To</Label>
              <CustomDatePicker
                name="dob"
                className="form-control"
                value={toDate}
                onChange={(value) => setToDate(value)}
                // onBlur={formik.handleBlur}
              />
            </div>
            <div className="table_filter_item">
              <select
                className="form-control"
                style={{ width: 180 }}
                onChange={(event) => setSearchType(event.target.value)}
              >
                <option value={1}>Search By Name</option>
                <option value={2}>Search By Order Id</option>
                <option value={3}>Search By SKU</option>
              </select>
              {searchType == 1 ? (
                <CustomInput
                  className="form-control"
                  onChange={(event) => searchName(event)}
                  type="search"
                  placeholder="Search Customer Name..."
                />
              ) : searchType == 2 ? (
                <CustomInput
                  className="form-control"
                  onChange={(event) => setSearchId(event.target.value)}
                  type="search"
                  placeholder="Search order id..."
                />
              ) : (
                <CustomInput
                  className="form-control"
                  onChange={(event) => setSearchSku(event.target.value)}
                  type="search"
                  placeholder="Search SKU..."
                />
              )}
            </div>
            <div className="table_filter_item ms-md-auto">
              <select
                className="form-control"
                style={{ width: 180 }}
                onChange={(event) => setFilterStatus(event.target.value)}
              >
                {orderStatus &&
                  orderStatus.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.title}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <LTable>
            <thead>
              <tr>
                <th>#</th>
                <th className="text-nowrap">Ref No.</th>
                <th>Items</th>
                <th>Billing Info</th>
                <th>Shipping Info</th>
                <th className="text-center">Courier Company</th>
                <th className="text-center">Order On</th>
                <th className="text-center">Payment Mode</th>
                <th>Amount</th>
                <th className="text-center">Status</th>
                <th className="text-center">Payment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orders?.result &&
                orders?.result
                  .filter(
                    (j) =>
                      (j.billing_address?.first_name || "")
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      j.billing_address.last_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      (
                        (j.billing_address?.first_name || "") +
                        " " +
                        j.billing_address.last_name
                      )
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                  )
                  .map((i, index) => {
                    const serialNumber = tableSerialNumber(
                      index,
                      orders?.cur_page,
                      orders?.result.length,
                      25
                    );
                    return (
                      <tr key={index}>
                        <td>{serialNumber}</td>
                        <td>{i?.reference_number}</td>
                        <td>
                          <Link onClick={() => handleShowItemList(i?.id)} to="">
                            Items
                          </Link>
                        </td>
                        <td>
                          <p className="fw-bold mb-0">
                            {i?.billing_address?.first_name || ""}{" "}
                            {i?.billing_address?.last_name || ""}
                          </p>
                          <small className="lh-base d-inline-block">
                            <AiOutlineMail className="h6 m-0" />{" "}
                            {i?.billing_address?.email} &nbsp; | &nbsp;
                            <AiOutlinePhone className="h6 m-0" />
                            {i?.billing_address?.phone_number}
                          </small>
                          <br />
                          <small className="lh-base d-inline-block">
                            <FiMapPin /> {i?.billing_address?.address_line_1},{" "}
                            {i?.billing_address?.address_line_2},{" "}
                            {i?.billing_address?.country?.name}
                            {/* <CityStateCountryFetcher
                              id={i?.billing_address?.country?.name}
                              type="country"
                            /> */}
                            , {i?.billing_address?.zip_code}
                          </small>
                        </td>
                        <td>
                          <p className="fw-bold mb-0">
                            {i?.shipping_address?.first_name || ""}{" "}
                            {i?.shipping_address?.last_name || ""}
                          </p>
                          <small className="lh-base d-inline-block">
                            <AiOutlineMail className="h6 m-0" />{" "}
                            {i?.shipping_address?.email} &nbsp; | &nbsp;
                            <AiOutlinePhone className="h6 m-0" />
                            {i?.shipping_address?.phone_number}
                          </small>
                          <br />
                          <small className="lh-base d-inline-block">
                            <FiMapPin /> {i?.shipping_address?.address_line_1},{" "}
                            {i?.shipping_address?.address_line_2},{" "}
                            {i?.shipping_address?.country?.name}
                            {/* <CityStateCountryFetcher
                              id={i?.shipping_address?.country?.id}
                              type="country"
                            /> */}
                            , {i?.shipping_address?.zip_code}
                          </small>
                        </td>
                        <td>
                          {/* {assignCompany !== i.id ? ( */}
                          {i?.tracking_no !== null && (
                            <div className="d-block text-center">
                              <b>{i?.logistics_company?.company_name}</b>
                              <br />({i?.tracking_no})
                            </div>
                          )}
                          {i?.tracking_no === null && (
                            <div className="text-center">
                              <Button
                                disabled={
                                  i.order_status_id === 7 ||
                                  i.order_status_id === 5 ||
                                  i.order_status_id === 4 ||
                                  i.order_status_id === 6
                                }
                                size="sm"
                                onClick={() => setShowAssignCompany(i.id)}
                              >
                                Assign
                              </Button>
                            </div>
                          )}
                          <>
                            <div className="position-relative">
                              {showAssignCompany === i.id && (
                                <Card
                                  className="tableInnerCard shadow-lg border"
                                  // onClick={() => {
                                  //   setShowAssignCompany(null);
                                  // }}
                                >
                                  <Card.Body>
                                    <div className="d-flex">
                                      <h5 className="mb-2 flex-grow-1 fw-bold">
                                        Assign Company
                                      </h5>{" "}
                                      <AiOutlineClose
                                        className="cursor-pointer"
                                        onClick={() =>
                                          setShowAssignCompany(null)
                                        }
                                      />
                                    </div>
                                    <AssignCompany
                                      isFormSubmit={() =>
                                        setShowAssignCompany(null)
                                      }
                                      orderId={i.id}
                                    />
                                  </Card.Body>
                                </Card>
                              )}
                            </div>

                            {/* <select
                                className="form-control input-sm"
                                style={{ width: 140 }}
                              >
                                <option value={null}>Select Courier</option>
                                {courierCompanies?.courierCompanies?.data?.rows.map(
                                  (i) => (
                                    <option value={i.id} key={i.id}>
                                      {i.company_name}
                                    </option>
                                  )
                                )}
                              </select> */}

                            {/* <Link
                                ref={target}
                                onClick={() => setShowAddCompany(i.id)}
                                to={"#"}
                                className="fw-bold cursor-pointer text-black text-nowrap d-block"
                              >
                                Add new company
                              </Link>
                              <div className="position-relative">
                                {showAddCompany === i.id && (
                                  <Card
                                    className="tableInnerCard shadow-lg border"
                                    onClick={() => {
                                      setShowAddCompany(null);
                                    }}
                                  >
                                    <Card.Body>
                                      <div className="d-flex">
                                        <h5 className="mb-2 flex-grow-1 fw-bold">
                                          Add company
                                        </h5>{" "}
                                        <AiOutlineClose
                                          className="cursor-pointer"
                                          onClick={() =>
                                            setShowAddCompany(null)
                                          }
                                        />
                                      </div>

                                      <AddCompany
                                        isFormSubmit={() => {
                                          setShowAddCompany(null);
                                        }}
                                      />
                                    </Card.Body>
                                  </Card>
                                )}
                              </div> */}
                          </>
                        </td>
                        <td className="text-center">
                          {formatDate(i?.created_at)}
                        </td>
                        <td className="text-center text-nowrap">
                          {i?.orderPayment?.payment_type?.name} &nbsp;
                          {i?.orderPayment?.payment_type?.name ===
                            "Bank / QR" && (
                            <Image
                              height="20"
                              src={require("../../public/images/icons/qrcode.png")}
                            />
                          )}
                          {i?.orderPayment?.payment_type?.name === "Cash" && (
                            <Image
                              height="20"
                              src={require("../../public/images/icons/Cash.png")}
                            />
                          )}
                          {i?.orderPayment?.payment_type?.name === "COD" && (
                            <Image
                              height="20"
                              src={require("../../public/images/icons/COD.png")}
                            />
                          )}
                          {i?.orderPayment?.payment_type?.name ===
                            "Credit / Debit Card" && (
                            <Image
                              height="20"
                              src={require("../../public/images/icons/Credit  Debit Card.png")}
                            />
                          )}
                          {i?.orderPayment?.payment_type?.name === "Paypal" && (
                            <Image
                              height="20"
                              src={require("../../public/images/icons/Paypal.png")}
                            />
                          )}
                        </td>
                        <td>
                          <Currency />
                          {amount(i?.total_amount)}
                        </td>
                        <td>
                          <NavDropdown
                            align={{ lg: "end" }}
                            title={
                              orderStatus &&
                              orderStatus.map((status) => {
                                if (status.id === i?.orderStatus?.id) {
                                  return (
                                    <span>
                                      {" "}
                                      <Badge
                                        bg={
                                          status.id === 1
                                            ? "primary"
                                            : status.id === 2
                                            ? "info"
                                            : status.id === 3
                                            ? "warning"
                                            : status.id === 4
                                            ? "info"
                                            : status.id === 5
                                            ? "success"
                                            : "danger"
                                        }
                                      >
                                        {status.title}
                                      </Badge>{" "}
                                      <HiChevronDown />
                                    </span>
                                  );
                                }
                              })
                            }
                          >
                            {orderStatus &&
                              orderStatus.map((j) => (
                                <NavDropdown.Item
                                  key={j.id}
                                  href="#"
                                  onClick={() => updateStatus(i.id, j.id)}
                                >
                                  {j.title}
                                </NavDropdown.Item>
                              ))}
                          </NavDropdown>
                        </td>
                        <td className="text-nowrap">
                          <Link
                            to={"#"}
                            onClick={() => handleShowPaymentInfo(i)}
                          >
                            Payment Info
                          </Link>
                          {/* {i?.orderPayment?.is_success === 1 ? (
                            <Badge bg="success">{"Paid"}</Badge>
                          ) : (
                            <Badge bg="warning">{"Not Paid"}</Badge>
                          )} */}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <BiDotsVerticalRounded />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => assignToOrder(i.id)}
                                as={Link}
                                to="#"
                              >
                                <MdOutlineLocalShipping /> Assign Courier
                                Service
                              </Dropdown.Item>
                              <Dropdown.Item
                                // target="_blank"
                                href={`/order/invoice/${i?.id}`}
                              >
                                <MdOutlineDescription /> View Invoice
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </LTable>
          <Pagination
            pagecount={orders?.total_pages}
            handleGoToPage={handleGoToPage}
            currentpage={page || 1}
            onPageChange={handlePageChange}
          />
        </Card.Body>
      </Card>

      <Modal show={showPaymentInfo} size="md" onHide={handleClosePaymentInfo}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {paymentInfo?.orderPaymentReceipt !== null && (
            <div className="text-center mb-3">
              <Image
                src={paymentInfo?.orderPaymentReceipt?.receipt_image}
                className="w-100"
                style={{
                  height: 300,
                  objectFit: "contain",
                  background: "#ddd",
                }}
              />
            </div>
          )}
          <div className="details">
            <Table striped hover>
              <tbody>
                <tr>
                  <td>Transaction ID:</td>
                  <td>{paymentInfo?.id}</td>
                </tr>
                <tr>
                  <td>Amount:</td>
                  <td>
                    <Currency /> {amount(paymentInfo?.amount)}
                  </td>
                </tr>
                <tr>
                  <td>Payment Type:</td>
                  <td>
                    {paymentInfo?.payment_type?.name === "Bank / QR" && (
                      <Image
                        height="20"
                        src={require("../../public/images/icons/qrcode.png")}
                      />
                    )}
                    {paymentInfo?.payment_type?.name === "Cash" && (
                      <Image
                        height="20"
                        src={require("../../public/images/icons/Cash.png")}
                      />
                    )}
                    {paymentInfo?.payment_type?.name === "COD" && (
                      <Image
                        height="20"
                        src={require("../../public/images/icons/COD.png")}
                      />
                    )}
                    {paymentInfo?.payment_type?.name ===
                      "Credit / Debit Card" && (
                      <Image
                        height="20"
                        src={require("../../public/images/icons/Credit  Debit Card.png")}
                      />
                    )}
                    {paymentInfo?.payment_type?.name === "Paypal" && (
                      <Image
                        height="20"
                        src={require("../../public/images/icons/Paypal.png")}
                      />
                    )}{" "}
                    {paymentInfo?.payment_type?.name}
                  </td>
                </tr>
                <tr>
                  <td>Transaction Date/Time:</td>
                  <td>
                    <Currency /> {formatDate(paymentInfo?.created_at)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showItemList} size="xl" onHide={handleCloseItemList}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p className="mb-1">
                Payment Method - {order__item.orderPayment?.payment_type?.name}{" "}
                &nbsp;
                {order__item.orderPayment?.payment_type?.name === "Cash" && (
                  <Image
                    height="20"
                    src={require("../../public/images/icons/Cash.png")}
                  />
                )}
                {order__item?.id}
                {order__item.orderPayment?.payment_type?.name === "COD" && (
                  <Image
                    height="20"
                    src={require("../../public/images/icons/COD.png")}
                  />
                )}
                {order__item.orderPayment?.payment_type?.name ===
                  "Credit / Debit Card" && (
                  <Image
                    height="20"
                    src={require("../../public/images/icons/Credit  Debit Card.png")}
                  />
                )}
                {order__item.orderPayment?.payment_type?.name === "Paypal" && (
                  <Image
                    height="20"
                    src={require("../../public/images/icons/Paypal.png")}
                  />
                )}
              </p>
              <p className="mb-1">
                Status -
                {orderStatus &&
                  orderStatus.map(
                    (status) =>
                      status?.id === order__item?.order_status_id && (
                        <React.Fragment key={status.id}>
                          <Badge
                            bg={
                              status.id === 1
                                ? "primary"
                                : status.id === 2
                                ? "info"
                                : status.id === 3
                                ? "warning"
                                : status.id === 4
                                ? "info"
                                : status.id === 5
                                ? "success"
                                : "danger"
                            }
                          >
                            {status.title}
                          </Badge>
                        </React.Fragment>
                      )
                  )}
              </p>
              <p className="mb-1">Order Note - {order__item?.order_notes}</p>
            </Col>
            <Col className="text-end">
              <p>Date - {formatDate(order__item?.created_at)}</p>
            </Col>
          </Row>
          <hr />
          <OrderStatusTracking
            paymentMethod={order__item.orderPayment?.payment_type?.name}
            currentStatus={order__item?.orderStatus?.system_status}
          />
          <hr />
          <h6>Order Summery</h6>
          <LTable>
            <tbody>
              {order__item?.orderItems &&
                order__item?.orderItems.map((j, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td width={50}>
                      <Image
                        src={
                          j?.product?.product_images[0]?.image_path +
                          "/" +
                          j?.product?.product_images[0]?.image_name
                        }
                        height={50}
                        width={50}
                        style={{ objectFit: "cover" }}
                      />
                    </td>
                    <td>
                      <h6>
                        {j?.product?.name},<br />
                        <small className="fw-normal text-muted">
                          Category - {j?.product?.category?.name}
                          <br />
                          SKU - {j?.product?.sku} &nbsp;|&nbsp; Color -{" "}
                          {j.color} &nbsp;|&nbsp; Size - {j?.size}
                        </small>
                      </h6>
                    </td>
                    <td>Qty - {j?.quantity}</td>
                    <td>
                      <b>Price</b>
                      <br />
                      <Currency />
                      {amount(j?.total_price)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </LTable>
          <hr className="mt-0" />
          <h6>
            Copupon Amount - <Currency />
            {amount(order__item?.coupon_amount)}
          </h6>
          <h6>
            Shipping Charge - <Currency />
            {amount(order__item?.shipping_amount)}
          </h6>
          <h5>
            Total - <Currency />
            {amount(order__item?.total_amount)}
          </h5>
        </Modal.Body>
      </Modal>
    </Pagelayout>
  );
}

// priceActionSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';

const services = new Service();

// Async thunk for fetching priceAction and subPriceAction
export const fetchPriceAction = createAsyncThunk('priceAction/fetchPriceAction', async () => {
   try {
      const response = await services.get('setting/price');
      return response.data;
   } catch (error) {
      throw error;
   }
});

// Initial state
const initialState = {
   priceAction: [],
   status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
   error: null,
};

// Create a slice
const priceActionSlice = createSlice({
   name: 'priceAction',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchPriceAction.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(fetchPriceAction.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.priceAction = action.payload;
         })
         .addCase(fetchPriceAction.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
         });
   },
});

export default priceActionSlice.reducer;

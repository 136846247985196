import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'; // Assuming you're using Bootstrap components
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

export default function Pagination({ pagecount, currentpage = 1, onPageChange, handleGoToPage }) {

   const [inputValue, setInputValue] = useState(currentpage)
   const handlePreviousPage = () => {
      if (currentpage > 1) {
         setInputValue(currentpage - 1)
         onPageChange(currentpage - 1);
      }
   };

   const handleNextPage = () => {
      if (currentpage < pagecount) {
         setInputValue(currentpage + 1)
         onPageChange(currentpage + 1);
      }
   };

   const handleInputPageChange = (e) => {
      setInputValue(e.target.value)
      if (inputValue > pagecount) {
         setInputValue(pagecount)
      }
      handleGoToPage(e)
   }

   useEffect(() => {
      setInputValue(currentpage)
   },[currentpage])

   return (
      <div className='pagination'>
         <Col className='text-end'>
            <Button size='sm' className={currentpage === 1 ? 'disabled_btn me-2' : 'me-2'} onClick={handlePreviousPage}>
               <AiOutlineArrowLeft />
            </Button>
            <span className='gotoPageInput'><input type='number' max={pagecount} onChange={handleInputPageChange} value={inputValue} /></span>
            <Button size='sm' className={currentpage === pagecount && 'disabled_btn'} onClick={handleNextPage}>
               <AiOutlineArrowRight />
            </Button>
         </Col>
      </div>
   );
}
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "../Components/Image";
import { logoutUser } from "../redux/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchCurrencyList } from "../redux/currencySlice";
import { useEffect, useState } from "react";
import { setSelectedCurrency } from "../redux/PropertySlice";
import ChangePassword from "../Pages/Profile/ChangePassword";
import { Modal } from "react-bootstrap";
import doAPI from "../api";

function BasicExample() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    doAPI.postData("user/logout", {}).then((res) => {
      if (res.status) {
        dispatch(logoutUser());
      }
    });
  };

  const [ChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const handleCloseChangePasswordForm = () => setShowChangePasswordForm(false);
  const handleShowChangePasswordForm = () => {
    setShowChangePasswordForm(true);
  };

  useEffect(() => {
    dispatch(fetchCurrencyList());
  }, [dispatch]);

  const getCurrencyIdFromLogin = useSelector((state) => state.user).user
    ?.currency_id;

  const currency = useSelector((state) => state.currency);

  const selectedCurrency = useSelector((state) => state.property);

  const [currencyState, setCurrencyState] = useState("Select Currency");
  useEffect(() => {
    dispatch(fetchCurrencyList());
  }, [dispatch, getCurrencyIdFromLogin]);

  useEffect(() => {
    if (currency?.currencylist?.data) {
      dispatch(
        setSelectedCurrency(
          currency?.currencylist?.data &&
            currency?.currencylist?.data.find(
              (i) => i.id == getCurrencyIdFromLogin
            )
        )
      );
      localStorage.setItem(
        "currency",
        JSON.stringify(
          currency?.currencylist?.data &&
            currency?.currencylist?.data.find(
              (i) => i.id == getCurrencyIdFromLogin
            )
        )
      );
      // console.log(currency?.currencylist?.data && currency?.currencylist?.data.find(i => i.id == getCurrencyIdFromLogin));
      setCurrencyState(
        `${
          currency?.currencylist?.data &&
          currency?.currencylist?.data.find(
            (i) => i.id == getCurrencyIdFromLogin
          )?.symbol
        } ${
          currency?.currencylist?.data &&
          currency?.currencylist?.data.find(
            (i) => i.id == getCurrencyIdFromLogin
          )?.name
        }`
      );
    }
  }, [dispatch, getCurrencyIdFromLogin, currency?.currencylist?.data]);

  const handleCurrencyChange = (selectedCurrency) => {
    dispatch(setSelectedCurrency(selectedCurrency));
    localStorage.setItem("currency", JSON.stringify(selectedCurrency));
    setCurrencyState(`${selectedCurrency.symbol} ${selectedCurrency.name}`);
  };

  return (
    <header>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown
                align={{ lg: "end" }}
                title={currencyState}
                id="basic-nav-dropdown"
              >
                {currency?.currencylist?.data?.map((item, index) => (
                  <NavDropdown.Item
                    key={index}
                    onClick={() => handleCurrencyChange(item)}
                  >
                    {item.symbol} {item.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown
                className="no-arrow"
                align={{ lg: "end" }}
                title={
                  <>
                    <div className="profile_img_sm profile_img">
                      <Image src="./android-chrome-192x192.png" />
                    </div>
                  </>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/profile">
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  onClick={handleShowChangePasswordForm}
                >
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={ChangePasswordForm} onHide={handleCloseChangePasswordForm}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangePassword onFormSubmitSuccess={handleCloseChangePasswordForm} />
        </Modal.Body>
      </Modal>
    </header>
  );
}

export default BasicExample;

import React, { useEffect, useState } from 'react'
import Notification, { notify } from '../../Components/Notification'
import { Button, Col, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Label from '../../Components/Label'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import Service from '../../http'
import CustomInput from '../../Components/CustomInput'
import { useSelector } from 'react-redux'
import { BiLoader } from 'react-icons/bi'
import { AiFillInfoCircle } from 'react-icons/ai'

export default function EditVatSetting({ data, onFormSubmitSuccess }) {
   const services = new Service();
   const currency = useSelector((state) => state.currency)?.currencylist?.data;
   const [isLoading, setIsLoading] = useState(false)
   const NewProductSizeSchema = Yup.object().shape({
      is_vat: Yup.number().required('Is Vat Applicable field is required'),
      vat: Yup.number().required('Vat is required'),
      currency_id: Yup.number().required('Currency is required'),
   });

   const formik = useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
         is_vat: data?.is_vat,
         vat: data?.vat,
         currency_id: data?.currency_id
      },
      onSubmit: (values, { resetForm }) => {
         services && services.post('product/setting/set-vat', values).then((res) => {
            setIsLoading(true)
            if (res.status) {
               notify(res.message);
               console.log(res.message)
               setTimeout(() => {
                  onFormSubmitSuccess();
               }, 1000);
               setIsLoading(false)
            } else {
               notify(res.message[0]?.message[0]?.message, { variant: 'error' });
            }
         })
      },
      validationSchema: NewProductSizeSchema
   });

   const currentCurrency = currency.find(i => i.id == formik.values.currency_id).symbol + ' ' + currency.find(i => i.id == formik.values.currency_id).name
   useEffect(() => {
      formik.setValues({
         is_vat: data?.is_vat || 0,
         vat: data?.vat || 0,
         currency_id: data?.currency_id || 1
      })
   }, [data])

   return (
      <>
         <Form onSubmit={formik.handleSubmit}>
            <Notification />
            <Row>
               { }
               <Col md={12} className='mb-3'>
                  <Label aria-required>Currency</Label>
                  <CustomInput className='form-control'
                     value={currentCurrency}
                     readOnly
                  />
                  {formik.touched.currency_id && formik.errors.currency_id ? (
                     <div className="error">{formik.errors.currency_id}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Label aria-required>Status</Label>
                  <select name="is_vat" onChange={(event) => formik.setFieldValue('is_vat', parseInt(event.target.value))}
                     className='form-control'>
                     <option selected={formik.values.is_vat == 1} value="1">Yes</option>
                     <option selected={formik.values.is_vat == 0} value="0">No</option>
                  </select>
                  {formik.touched.is_vat && formik.errors.is_vat ? (
                     <div className="error">{formik.errors.is_vat}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Label aria-required>
                     VAT Amount ({currency.find(i => i.id == formik.values.currency_id).symbol})   <OverlayTrigger
                        placement={'top'}
                        overlay={
                           <Tooltip>
                              The User can select what %age of VAT will be levied on the products during check out. If this value is left at 0 and in deactivated mode, then no VAT will be charged during check out on either the website or POS.
                           </Tooltip>
                        }
                     >
                        <span><AiFillInfoCircle /></span>
                     </OverlayTrigger>
                  </Label>
                  <Label aria-required></Label>
                  <CustomInput type='number' name="vat" placeholder='Enter Vat' className='form-control'
                     value={formik.values.vat}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.vat && formik.errors.vat ? (
                     <div className="error">{formik.errors.vat}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Button type="submit"
                     size='lg'
                     className="px-md-5 w-100"
                     variant="primary">
                     {isLoading ? <BiLoader className='loader' /> : "Submit"}
                  </Button>
               </Col>
            </Row>
         </Form>
      </>
   )
}

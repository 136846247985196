import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Service from "../../http";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../Components/CustomInput";
import Notification, { notify } from "../../Components/Notification";
import Label from "../../Components/Label";
import { BiLoader } from "react-icons/bi";
import { fetchCategories } from "../../redux/categorySlice";

export default function EditCategory({ onSuccess, category_id }) {
  const services = new Service();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryDetail, setCategoryDetail] = useState();
  const categories = useSelector((state) => state.category.categories.data);
  useEffect(() => {
    // Dispatch the fetchCategories async thunk when the component mounts
    dispatch(fetchCategories());
  }, [dispatch]);

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      category_id: 0,
      name: "",
      slug: "",
      image: null,
    },
    onSubmit: (values, { resetForm }) => {
      // let formData = new FormData();
      // for (const key in values) {
      //    if (Object.hasOwnProperty.call(values, key)) {
      //       formData.append(key, values[key])
      //    }
      // }
      setIsLoading(true);
      values.slug = values.slug.toLowerCase().replace(/\s+/g, "-");

      services &&
        services.post("category/add-update", values).then((res) => {
          if (res.status) {
            notify(res.message);
            dispatch(fetchCategories());
            onSuccess();
          } else {
            res?.response?.data?.message.map((i) => notify(i.message));
          }
          setIsLoading(false);
        });

      resetForm();
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required."),
      slug: Yup.string().required("Slug is required."),
      // image: Yup.mixed().required('Image is required.'),
      category_id: Yup.number().required("Category is required."),
    }),
  });

  useEffect(() => {
    setCategoryDetail(categories.find((i) => i.id === category_id));
    formik.setValues({
      category_id: categoryDetail?.id,
      name: categoryDetail?.name,
      slug: categoryDetail?.slug,
      image: null,
    });
  }, [categories]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Notification />
      <Row>
        <Col md={12} className="mb-3">
          <Label aria-required>Category Name</Label>
          <CustomInput
            name="name"
            className="form-control"
            type="text"
            placeholder="Category Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="error">{formik.errors.name}</div>
          ) : null}
        </Col>

        <Col md={12} className="mb-3">
          <Label aria-required>Slug Name</Label>
          <CustomInput
            name="slug"
            className="form-control"
            type="text"
            placeholder="Slug Name"
            value={formik.values.slug}
            onChange={(event) => {
              const formattedSlug = event.target.value
                .toLowerCase()
                .replace(/\s+/g, "-");
              formik.handleChange({
                target: {
                  name: "slug",
                  value: formattedSlug,
                },
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.slug && formik.errors.slug ? (
            <div className="error">{formik.errors.slug}</div>
          ) : null}
        </Col>

        {/* <Col md={6} className='mb-3'>
               <Label aria-required>Select Image</Label>
               <CustomInput
                  className="form-control"
                  name="image"
                  onChange={(event) => getDataUrl(event.target.files)}
                  type="file"
               />
               {formik.touched.image && formik.errors.image ? (
                  <div className="error">{formik.errors.image}</div>
               ) : null}
            </Col> */}

        <Col md={12} className="mb-3">
          <Button
            type="submit"
            size="lg"
            className="px-md-5 w-100"
            variant="primary"
          >
            {isLoading ? <BiLoader className="loader" /> : "Edit Category"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

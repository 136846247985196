import React from 'react'
import { AiFillStar, } from 'react-icons/ai';

export default function RatingStars({ rating }) {
  const maxRating = 5;
  const filledStars = Math.round(rating);

  const starsArray = Array.from({ length: maxRating }, (_, index) => {
    if (index < filledStars) {
      return <AiFillStar className='text-warning' key={index} />;
    } else {
      return <AiFillStar key={index} />;
    }
  });

  return <>{starsArray}</>;
}

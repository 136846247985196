import React, { useEffect, useState } from 'react'
import Pagelayout from '../../Layouts/Pagelayout'
import { Button, Card, Col, Dropdown, Form, Modal } from 'react-bootstrap'
import Service from '../../http';
import LTable from '../../Components/LTable';
import { BiDotsVerticalRounded, BiLoader } from 'react-icons/bi';
import { formatDate, tableSerialNumber } from '../../Components/Utils';
import CustomInput from '../../Components/CustomInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Notification, { notify } from '../../Components/Notification';
import { Link } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import Label from '../../Components/Label';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../redux/categorySlice';
import EditSize from './Size/EditSize';
import AddSize from './Size/AddSize';

export default function ProductSizes() {
  const dispatch = useDispatch()
  const services = new Service();
  const [isLoading, setIsLoading] = useState(false)
  const [sizeList, setSizeList] = useState([])
  const [sizeData, setSizeData] = useState()

  const categories = useSelector((state) => state.category.categories); // Use the appropriate slice name
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const [showSizeAddForm, setShowSizeAddForm] = useState(false)
  const handleCloseSizeAddForm = () => setShowSizeAddForm(false);
  const handleShowSizeAddForm = () => {
    setShowSizeAddForm(true)
  };

  const [showSizeEditForm, setShowSizeEditForm] = useState(false)
  const handleCloseSizeEditForm = () => setShowSizeEditForm(false);
  const handleShowSizeEditForm = (data) => {
    setSizeData(data)
    setShowSizeEditForm(true)
  };

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      category_id: 0,
      name: ""
    },
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);

      services &&
        services
          .post("size/size-name/add", values)
          .then((res) => {
            if (res.status) {
              notify(res.message)
              fetchEntry()
              handleCloseSizeAddForm()
            } else {
              notify(res.message[0].message)
            };
            setIsLoading(false);
          });

      resetForm();
    },
    validationSchema: Yup.object().shape({
      category_id: Yup.number().required('Category is required.'),
      name: Yup.string().required('Name is required.'),
    })
  });

  const fetchEntry = () => {
    services &&
      services.get(`size/size-name/list`).then((res) => {
        setSizeList(res?.data)
      });
  };

  const formSubmit = () => {
    fetchEntry()
    handleCloseSizeEditForm()
    handleCloseSizeAddForm()
  }

  useEffect(() => { fetchEntry() }, [])

  const deleteEntry = (id) => {
    services &&
      services.put("product/delete/" + id)
        .then((res) => {
          if (res.status) {
            notify(res.message);
            fetchEntry();
          } else {
            notify(res.message[0]?.message);
          }
        })
        .catch((error) => {
          console.error('Error - ', error);
        });
  }


  const handleStatusChange = (id, isActive) => {
    services &&
      services.put("size/size-name/status/", {
        is_active: isActive == 1 ? 0 : 1,
        size_name_id: id
      })
        .then((res) => {
          if (res.status) {
            notify(res.message);
            fetchEntry();
          } else {
            notify(res.message[0]?.message);
          }
        })
        .catch((error) => {
          console.error('Error - ', error);
        });
  };

  return (
    <Pagelayout
      title="Size List"
      breadcrumbs={
        [
          { title: 'Dashboard', link: '/dashboard' },
          { title: 'Products', link: '/products' },
          { title: 'Size', link: '#' }
        ]
      }
      actions={<Button onClick={handleShowSizeAddForm}>Add Size</Button>}>
      <Notification />
      <Card className='overflow-visible'>
        <Card.Body>

          <LTable>
            <thead>
              <tr>
                <th width={50}>#</th>
                <th>Category</th>
                <th>Name</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sizeList && sizeList.map((i, index) => <tr key={index}>
                <td>{tableSerialNumber(index, sizeList.cur_page, sizeList.length)}</td>
                <td>{i.category?.name}</td>
                <td>{i.name}</td>
                <td>{formatDate(i.updated)}</td>
                <td>
                  <label
                    onClick={() => handleStatusChange(i.id, i.is_active)}
                    className={i.is_active === 1 ? 'custom_check active' : 'custom_check'}
                  >
                    <div className='custom_check_wrapper'>
                      <span></span>
                      <label>
                        {i.is_active === 1 ? 'Active' : 'Deactivated'}
                      </label>
                    </div>
                  </label>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <BiDotsVerticalRounded />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} onClick={() => deleteEntry(i.id)} to="#"><MdDelete /> Delete</Dropdown.Item>
                      <Dropdown.Item as={Link} onClick={() => handleShowSizeEditForm(i)} to="#"><MdEdit /> Edit</Dropdown.Item>

                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>)}

            </tbody>
          </LTable>

        </Card.Body>
      </Card>

      <Modal show={showSizeAddForm} onHide={handleCloseSizeAddForm}>
        <Modal.Header closeButton>
          <Modal.Title>Add Size</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddSize formSubmit={() => formSubmit()} />
        </Modal.Body>
      </Modal>

      <Modal show={showSizeEditForm} onHide={handleCloseSizeEditForm}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Size</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditSize formSubmit={() => formSubmit()} sizeData={sizeData} />
        </Modal.Body>
      </Modal>

    </Pagelayout>
  )
}
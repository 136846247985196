import React, { useEffect, useState } from 'react'
import Image from '../../Components/Image'
import Pagelayout from '../../Layouts/Pagelayout'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { AiOutlineUser } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserDetails } from '../../redux/userDetailsSlice'
import { fetchAddressList } from './../../redux/userAddressSlice';
import EditProfile from './EditProfile'
import ChangePassword from './ChangePassword';

export default function Profile() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails?.userDetails?.data);

  useEffect(() => {
    dispatch(fetchUserDetails());
  }, [dispatch]);

  const [ChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const handleCloseChangePasswordForm = () => setShowChangePasswordForm(false);
  const handleShowChangePasswordForm = () => {
    setShowChangePasswordForm(true)
  };

  return (
    <Pagelayout
      title={`My Profile`}
    >
      <Row className=' gx-2 gx-md-3'>
        <Col lg={3} xs={12} className="mb-md-3 mb-2">
          <Card>
            <Card.Body>
              <div className='profileBg'>
              <div className='circleImg mx-auto'>
                <AiOutlineUser className='h3 m-0' />
              </div>
              <h5 className='text-center my-3 fw-bold'>{userDetails?.first_name} {userDetails?.family_name},
                <small>({userDetails?.gender})</small>
                </h5>
                </div>
              <hr />
              <table>
                <tbody>
                  <tr>
                    <td>Email</td>
                    <td>-</td>
                    <td>{userDetails?.email}</td>
                  </tr>
                  <tr>
                    <td>Mobile</td>
                    <td>-</td>
                    <td>+{userDetails?.country?.phone_code} {userDetails?.mobile}</td>
                  </tr>
                  <tr>
                    <td>Currency</td>
                    <td>-</td>
                    <td>{userDetails?.country?.currency_name} <b>({userDetails?.country?.currency_symbol})</b></td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className='text-center'>
                <Button onClick={handleShowChangePasswordForm}>Change Password</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={9} xs={12} className="mb-md-3 mb-2">
          <Card>
            <Card.Body>
              <EditProfile onFormSubmitSuccess={()=>dispatch(fetchUserDetails())} userData={userDetails} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={ChangePasswordForm} onHide={handleCloseChangePasswordForm}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangePassword onFormSubmitSuccess={handleCloseChangePasswordForm} />
        </Modal.Body>
      </Modal>
    </Pagelayout>
  )
}

// currencySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../Components/Utils';

// Define the initial state for the currency slice
const initialState = {
   currencylist: [],
   currencyDetails: {},
   currency: null,
   status: 'idle',
   error: null,
};
const baseurl = process.env.REACT_APP_BASE_URL

// Define an async thunk for fetching the currency list
export const fetchCurrencyList = createAsyncThunk('currency/fetchList', async (_, { rejectWithValue }) => {
   try {
      const response = await fetch(`${baseurl}/currency/list`);
      const data = await response.json();
      return data;
   } catch (error) {
      return rejectWithValue(error.message);
   }
});


// Create the currency slice
const currencySlice = createSlice({
   name: 'currency',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchCurrencyList.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(fetchCurrencyList.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.currencylist = action.payload;
         })
         .addCase(fetchCurrencyList.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload; // Now storing the error message in the state
         })
   },
});

export default currencySlice.reducer;

// Capitalize the first letter of each word in the input string
export const capitalizeFirstLetter = (string) => {
    return string.split(/\s+/).map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export const getColor = (colorId) => {
    return colorId
}

// Format a date string to the format "Day Month, Year" (e.g., "03 Aug, 2023")
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const addLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value;
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = addLeadingZero(date.getDate());
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = addLeadingZero(date.getMinutes());
    const seconds = addLeadingZero(date.getSeconds());

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format

    const timeString = `${addLeadingZero(hours)}:${minutes}:${seconds} ${ampm}`;

    return `${day} ${month}, ${year} ${timeString}`;
};

export function amount(number, separator = ',') {
    return number && number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export function formatNumberWithSymbols(number) {
    const symbols = ["", "K", "M", "B", "T"];
  
    let index = 0;
    while (number >= 1000 && index < symbols.length - 1) {
      number /= 1000;
      index++;
    }
  
    return `${number.toFixed(2)}${symbols[index]}`;
  }

// Get an array of weeks in the given year
export function getWeeksOfYear(year) {
    const weeks = [];
    let date = new Date(year, 0, 1); // Start with January 1st of the given year

    // Find the first Monday of the year
    while (date.getDay() !== 1) {
        date.setDate(date.getDate() + 1);
    }

    const options = { month: 'short', day: '2-digit', year: 'numeric' };

    while (date.getFullYear() == year) {
        weeks.push({
            label: date.toLocaleDateString('en-US', options),
            value: date.toISOString().split('T')[0] // Get the date part of ISO string (YYYY-MM-DD)
        });

        date.setDate(date.getDate() + 7); // Move to the next week (Monday of the next week)
    }

    return weeks;
}

// Calculate the current date and get the year value three months later
const currentDate = new Date();
currentDate.setMonth(currentDate.getMonth() + 3);
const yearValueThreeMonthsLater = currentDate.getFullYear();

// Create an array of years from the current year to three months later
export function createRangeWithForLoop(start) {
    const range = [];
    for (let i = start; i <= yearValueThreeMonthsLater; i++) {
        range.push({ label: i + '', value: i });
    }
    return range;
}

// Convert a UTC date-time string to local date and time
export function convertToLocaleDateTime(utcDateTimeString) {
    // Create a new Date object using the UTC date string
    const dateObj = new Date(utcDateTimeString);

    // Convert the date to the local timezone
    const localDate = dateObj.toLocaleDateString();
    const localTime = dateObj.toLocaleTimeString();

    // Return the local date and time as an object
    return {
        localDate: localDate,
        localTime: localTime
    };
}


export function removeDuplicates(inputString) {
    const inputArray = inputString.split(',');

    const uniqueArray = [...new Set(inputArray)];

    const resultArray = uniqueArray.filter(item => item.trim() !== '');

    const resultString = resultArray.join(',');

    return resultString;
}


export function convertDateRange(dateRange) {
    const [startDateStr, endDateStr] = dateRange.split(' - ');

    const startDate = new Date(startDateStr);

    const endDate = new Date(endDateStr);

    const startDay = startDate.getDate();

    const startMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(startDate);

    const endDay = endDate.getDate();

    const endMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(endDate);

    const formattedDateRange = `${startDay} ${startMonth} - ${endDay} ${endMonth}`;

    return formattedDateRange;
}

export function formatTimeWithSeconds(inputTime) {
    // Ensure the input time has a valid format (HH:mm)
    if (/^\d{2}:\d{2}$/.test(inputTime)) {
        const currentTime = new Date();
        const [hours, minutes] = inputTime.split(":");
        currentTime.setHours(hours);
        currentTime.setMinutes(minutes);
        currentTime.setSeconds(0); // Set seconds to 0
        const formattedTime = currentTime.toLocaleTimeString("en-US", {
            hour12: false, // Ensure 24-hour format
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
        return formattedTime;
    } else {
        return "Invalid time format";
    }
}

export function calculateDiscountedPrice(price, discountPercentage) {

    // Calculate the discounted price.
    const discountedPrice = price - (price * discountPercentage) / 100;

    // Return the discounted price.
    return parseFloat(discountedPrice);
}

export function logoutApp(nonaction) {
    nonaction &&
      window.confirm(
        "Important Notice: Your session has timed out. Please log in for continued access."
      );
    localStorage.getItem("user") !== undefined && localStorage.removeItem("user");
    localStorage.getItem("token") !== undefined &&
      localStorage.removeItem("token");
    console.log("Logout");
    window.location.href = "/login";
  }


export const slugGeneration = (name, sku) => {
    // Convert the name to lowercase and replace spaces with hyphens
    const formattedName = name && name.toLowerCase().replace(/[^a-z0-9]+/ig, '-');
    const formattedSku = sku && sku.toLowerCase().replace(/[^a-z0-9]+/ig, '-');

    // Concatenate the formatted name and SKU with hyphens
    const result = `${formattedName}-${formattedSku}`;

    return result;
}

export const getImageSizeInKB = (imageUrl) => {
    return fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
            return Math.round(blob.size / 1024); // Convert from bytes to kilobytes
        })
        .catch(error => {
            console.log('Error:', error);
        });
}


export function tableSerialNumber(index, currentPage = 1, itemsOnPage, defaultEntryCount = 20) {
    return (currentPage - 1) * (currentPage > 1 ? defaultEntryCount : itemsOnPage) + (index + 1);
}

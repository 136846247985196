import React from 'react'
import { Button, Card } from 'react-bootstrap'
import Pagelayout from '../../../Layouts/Pagelayout'
import LTable from '../../../Components/LTable'
import Label from '../../../Components/Label'
import CustomDatePicker from '../../../Components/CustomDatePicker'
import CustomSelect from '../../../Components/CustomSelect'

export default function ProductHistory() {
   return (
      <Pagelayout
         title="Inventory"
         breadcrumbs={
            [
               { title: 'Inventory', link: '/inventory' },
               { title: 'Store Balance', link: '/inventory/store-balance' },
            ]
         }>
         <Card>
            <Card.Body>
               <div className='table_filter equal_space'>
                  <div className='table_filter_item align-items-center'>
                     <Label>Start</Label>
                     <CustomDatePicker
                        name="dob"
                        className="form-control"
                        // value={formik.values.dob}
                        onChange={(value) => console.log(value)}
                     // onBlur={formik.handleBlur}
                     />
                  </div>
                  <div className='table_filter_item align-items-center'>
                     <Label>End</Label> <CustomDatePicker
                        name="dob"
                        className="form-control"
                        // value={formik.values.dob}
                        onChange={(value) => console.log(value)}
                     // onBlur={formik.handleBlur}
                     />
                  </div>
                  <div className='table_filter_item'>
                     <CustomSelect
                        placeholder="Select Product"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[]}
                     />
                  </div>
                  <div className='table_filter_item ms-auto'>
                     <CustomSelect
                        placeholder="Select Store"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[]}
                     />
                  </div>
               </div>
               <h5 className='my-3'>Store Name - Blue Waters store</h5>
               <LTable>
                  <thead>
                     <tr>
                        <th>Store {'>'} time</th>
                        <th>Action</th>
                        <th>Quantity</th>
                        <th>Net Value</th>
                        <th>Total</th>
                        <th>Store Balance</th>
                        <th>Store Price</th>
                        <th>Store Sum</th>
                        <th>Document</th>
                        <th>Name</th>
                     </tr>
                  </thead>
               </LTable>
            </Card.Body>
         </Card>
      </Pagelayout>
   )
}

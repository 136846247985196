import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Label from "../../Components/Label";
import CustomInput from "../../Components/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BiLoader } from "react-icons/bi";
import doAPI from "../../api";
import { addCourierCompany } from "../../redux/courierCompaniesSlice";
import { useDispatch } from "react-redux";

export default function AddCompany({isFormSubmit}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Company Name is required"),
    tracking_link: Yup.string().required("Tracking Link is required"),
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      company_name: "",
      tracking_link: "",
    },
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      dispatch(addCourierCompany(values));
      // doAPI.postData("logistics-company/add", values).then((response) => {
      //   if (response.status) {
      //     setIsLoading(false);
      //     resetForm();
      //   } else {
      //     setIsLoading(true);
      //   }
      // });
    },
    validationSchema: validationSchema,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={12} className="mb-2">
          <Label aria-required>Company Name</Label>
          <CustomInput
            type="text"
            className="form-control"
            placeholder="Company Name"
            name="company_name"
            value={formik.values.company_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.company_name && formik.errors.company_name ? (
            <div className="error">{formik.errors.company_name}</div>
          ) : null}
        </Col>

        <Col md={12} className="mb-2">
          <Label aria-required>Tracking Link</Label>
          <CustomInput
            type="text"
            className="form-control"
            placeholder="Tracking Link"
            name="tracking_link"
            value={formik.values.tracking_link}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.tracking_link && formik.errors.tracking_link ? (
            <div className="error">{formik.errors.tracking_link}</div>
          ) : null}
        </Col>

        <Col md={12} className="mb-2">
          <Button
            type="submit"
            // onClick={()=>submitForm()}
            size="lg"
            className="px-md-5"
            variant="primary"
          >
            {isLoading ? <BiLoader className="loader" /> : "Submit"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

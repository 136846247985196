// PhoneNumberFormatter.js

import React from "react";
import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from "google-libphonenumber";
import { Tooltip } from "react-bootstrap";

const PhoneNumberFormatter = ({ phoneNumber, region }) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, region);

    if (parsedNumber && phoneUtil.isValidNumber(parsedNumber)) {
      // console.log(phoneUtil.format(parsedNumber, PNF.INTERNATIONAL));
      return <span>{phoneUtil.format(parsedNumber, PNF.INTERNATIONAL)}</span>;
    }
  } catch (error) {
    return (
      console.log(error)
    );
  }

  return (
    <span title="Invalide Phone Number" style={{ opacity: 0.6 }}>
      {phoneNumber}
    </span>
  );
};

export default PhoneNumberFormatter;

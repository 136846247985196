import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import ProductObj from "./ProductObj";
import { fetchProductListAll } from "../../redux/productSlice";
import { useDispatch } from "react-redux";

export default function ItemDetails({ data, list }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(null);
  const [currentItem, setCurrentItem] = useState(0);

  const [allProduct, setAllProducts] = useState(list);

  useEffect(() => {
    setAllProducts(list);
  }, [list]);

  const handleKeyPress = (event) => {
    if (event.key === "ArrowLeft") {
      setPage((prevItem) => Math.max(prevItem - 1, 0));
    } else if (event.key === "ArrowRight") {
      setPage((prevItem) => {
        Math.min(prevItem + 1, allProduct.length - 1);
        if (allProduct.length === 20) {
          dispatch(
            fetchProductListAll({
              page,
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentItem]);

  return (
    <>
      <div className="modalNavigation">
        <button
          className="arrowBtn"
          onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
        >
          <AiOutlineArrowLeft />
        </button>
        <button
          className="arrowBtn"
          onClick={() =>
            setPage((prevPage) =>
              Math.min(prevPage + 1, allProduct && allProduct.length - 1)
            )
          }
        >
          <AiOutlineArrowRight />
        </button>
      </div>

      {page !== null && allProduct[page]
        ? [allProduct[page]].map((j) => {
            return <ProductObj key={j.id} data={j} />;
          })
        : allProduct
            .filter((i) => i.id === data.id)
            .map((j) => {
              return <ProductObj key={j.id} data={j} />;
            })}

      <style jsx>{`
        .description,
        .description * {
          margin: 0px;
        }
        .modalNavigation {
          position: absolute;
          top: -48px;
          right: calc(50% - 50px);
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .modalNavigation > button {
          border-radius: 100%;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-width: 0px;
          background: var(--color1);
          color: #fff;
          box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.3);
        }
      `}</style>
    </>
  );
}

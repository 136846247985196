import React from 'react'
import Pagelayout from '../../../Layouts/Pagelayout'
import { Button, Card, Dropdown, DropdownButton } from 'react-bootstrap'
import LTable from '../../../Components/LTable'
import CustomSelect from '../../../Components/CustomSelect'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { MdOutlineHistory } from 'react-icons/md'
import { HiOutlineNewspaper } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import CustomDatePicker from '../../../Components/CustomDatePicker'

export default function CouponList() {

   return (
      <Pagelayout
         title="Inventory"
         breadcrumbs={
            [
               { title: 'Inventory', link: '/inventory' },
               { title: 'Store Balance', link: '/inventory/store-balance' },
            ]
         }>
         <Card>
            <Card.Body>
               <div className='table_filter equal_space'>
               <div className='table_filter_item align-items-center'>
                     <CustomDatePicker
                        name="dob"
                        className="form-control"
                        // value={formik.values.dob}
                        onChange={(value) => console.log(value)}
                     // onBlur={formik.handleBlur}
                     />
                  </div>

                  <div className='table_filter_item'>
                     <input className='form-control' placeholder='search' type='search' />
                  </div>
                  <div className='table_filter_item ms-auto'>
                     <CustomSelect
                        placeholder="Select Product Group"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[{ label: 'Boys', value: 2 }, { label: 'Girls', value: 2 }, { label: 'Kids', value: 3 }, { label: 'Equipments', value: 4 }]}
                     />
                  </div>
                  <div className='table_filter_item ms-auto'>
                     <CustomSelect
                        placeholder="Select Quantity"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[{ label: 'Greater Than 0', value: 2 }, { label: 'Equal to 0', value: 2 }, { label: 'Less than 0', value: 3 }]}
                     />
                  </div>
            
                  <div className='table_filter_item'>
                     <CustomSelect
                        placeholder="Select Store"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[]}
                     />
                  </div>
                        
                  <div className='table_filter_item'>
                     <Button className='w-100' size='lg'>Current Stock</Button>
           
                     <Button className='w-100' variant='dark' size='lg'>Delete Wo Balance</Button>
                  </div>
               </div>
               <h5 className='my-3'>Store Name - Blue Waters store</h5>
               <LTable>
                  <thead>
                     <tr>
                        <th>Product Name</th>
                        <th>Product Number</th>
                        <th>Barcode</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                        <th></th>
                     </tr>
                     <tr className='secondTHead'>
                        <th colSpan={3}>Total</th>
                        <th>4284.000</th>
                        <th>274748.00</th>
                        <th>575059.880</th>
                        <th></th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>Lorem ipsum dolor sit amet consectetur</td>
                        <td>10283</td>
                        <td>656565656565</td>
                        <td>4.000 PCS</td>
                        <td>327.381 PCS</td>
                        <td>1309.524</td>
                        <td>
                           <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                 <BiDotsVerticalRounded />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                 <Dropdown.Item as={Link} to="/inventory/product-history/1"><MdOutlineHistory /> product History</Dropdown.Item>
                                 <Dropdown.Item href="#/action-2"><HiOutlineNewspaper /> Adjust Inventory</Dropdown.Item>

                              </Dropdown.Menu>
                           </Dropdown>
                        </td>
                     </tr>
                     <tr>
                        <td>Lorem ipsum dolor sit amet consectetur</td>
                        <td>10283</td>
                        <td>656565656565</td>
                        <td>4.000 PCS</td>
                        <td>327.381 PCS</td>
                        <td>1309.524</td>
                        <td>
                           <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                 <BiDotsVerticalRounded />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                 <Dropdown.Item as={Link} to="/inventory/product-history/1"><MdOutlineHistory /> product History</Dropdown.Item>
                                 <Dropdown.Item href="#/action-2"><HiOutlineNewspaper /> Adjust Inventory</Dropdown.Item>

                              </Dropdown.Menu>
                           </Dropdown>
                        </td>
                     </tr>
                  </tbody>
               </LTable>
            </Card.Body>
         </Card>
      </Pagelayout>
   )
}

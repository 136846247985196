import React, { useEffect, useState } from "react";
import Notification, { notify } from "../../Components/Notification";
import { Button, Col, Row, Form, Card, Dropdown, Modal } from "react-bootstrap";
import Label from "../../Components/Label";
import { useFormik } from "formik";
import * as Yup from "yup";
import Service from "../../http";
import CustomInput from "../../Components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { BiDotsVerticalRounded, BiLoader } from "react-icons/bi";
import Pagelayout from "../../Layouts/Pagelayout";
import LTable from "../../Components/LTable";
import { MdDelete, MdEdit } from "react-icons/md";
import Image from "../../Components/Image";
import Swal from "sweetalert2";
import { fetchUsers } from "../../redux/usersSlice";
import AddUser from "./AddUser";
import Pagination from "../../Components/Pagination";
import EditUser from "./EditUser";
import { tableSerialNumber } from "../../Components/Utils";
import AddAddress from "./AddAddress";
import doAPI from "../../api";

export default function UserList() {
  const services = new Service();
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState();
  const [page, setPage] = useState(1);

  const handleFormSubmitSuccess = () => {
    setShowUserAddForm(false);
    dispatch(fetchUsers({ role_id: role, page: page }));
    window.location.reload();
  };

  const [UserEditForm, setShowUserEditForm] = useState(false);
  const handleCloseUserEditForm = () => setShowUserEditForm(false);
  const handleShowUserEditForm = (data) => {
    setUserData(data);
    setShowUserEditForm(true);
};

  const [UserAddForm, setShowUserAddForm] = useState(false);
  const handleCloseUserAddForm = () => setShowUserAddForm(false);
  const handleShowUserAddForm = (data) => {
    setShowUserAddForm(true);
  };

  const [AddAddressForm, setShowAddAddressForm] = useState(false);
  const handleCloseAddAddressForm = () => setShowAddAddressForm(false);
  const handleShowAddAddressForm = (id) => {
    setUserId(id);
    setShowAddAddressForm(true);
  };

  const handleChangePassword = (id) => {
    doAPI.putData("user/generate-password/" + id, {}).then((res) => {
      if (res.status) {
        fetchEntries()
        notify("New Password has been generated.");
      }
    });
  };

  const userList = useSelector((state) => state.userList?.users?.data?.data);

  console.log(userList);

  const fetchEntries = () => {
    dispatch(fetchUsers({ role_id: role, page: page }));

  }
  useEffect(() => {
    fetchEntries()
  }, [dispatch, role, page]);

  const handleDeleteUser = (userId) => {
    const options = {
      title: "Delete User",
      text: "Are you sure you want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    };

    // Use Swal.fire to show the SweetAlert dialog
    Swal.fire(options).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with deletion
        services
          .put("user/delete/" + userId)
          .then((res) => {
            if (res.status) {
              notify(res.message);
              fetchEntries()
            } else {
              notify(res.message[0]?.message);
            }
          })
          .catch((error) => {
            console.error("Error - ", error);
          });
      }
    });
  };
  const handlePageChange = (newPage) => {
    console.log(newPage)
    setPage(parseInt(newPage));
  };
  const handleGoToPage = (event) => {
    console.log(event.target.value)
    setPage(parseInt(event.target.value));
  };

  const handleStatusChange = (userId, isActive) => {
    services &&
      services
        .put("user/change-status", {
          user_id: userId,
          status_id: isActive == 1 ? 0 : 1,
        })
        .then((res) => {
          fetchEntries()
          if (res.status) {
            notify(res.message);
          } else {
            notify(res.message[0]?.message);
          }
        })
        .catch((error) => {
          console.error("Error - ", error);
        });
  };

  const handlePriceStatusChange = (userId, is_show_price) => {
    console.log(userId, is_show_price);
    services &&
      services
        .put("user/manage-show-price", {
          user_id: userId,
          is_show_price: is_show_price ,
        })
        .then((res) => {
          fetchEntries()
          if (res.status) {
            notify(res.message);
          } else {
            notify(res.message[0]?.message);
          }
        })
        .catch((error) => {
          console.error("Error - ", error);
        });
  };

  return (
    <Pagelayout
      title="User"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "User list", link: "#" },
      ]}
      actions={
        <div className="d-flex align-items-center">
          <select
            className="form-control"
            onChange={(event) => setRole(event.target.value)}
          >
            <option value={null}>Select Role</option>
            {/* <option selected={role === 2} value={2}>
              Admin
            </option> */}
            <option selected={role === 3} value={3}>
              User
            </option>
            <option selected={role === 4} value={4}>
              Manager
            </option>
            <option selected={role === 5} value={5}>
              Staff
            </option>
            <option selected={role === 6} value={6}>
              Wholesaler
            </option>
          </select>{" "}
          &nbsp;&nbsp;
          <Button onClick={() => handleShowUserAddForm()}>Add User</Button>
        </div>
      }
    >
      <Notification />
      <Card>
        <Card.Body>
          <LTable >
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Password</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Country</th>
                <th>Currency</th>
                <th>Show Price</th>
                <th>Role</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userList?.rows &&
                userList?.rows.map((i, index) => {
                  const serialNumber = tableSerialNumber(
                    index,
                    page,
                    userList?.rows.length,
                    10
                  );
                  return (
                    <tr key={index}>
                      <td>
                        {serialNumber}
                      </td>
                      <td>
                        <span className="fw-bold">
                          {i.first_name} {i.family_name},
                        </span>{" "}
                        <small>({i.gender == "M" ? "Male" : "Female"})</small>
                      </td>
                      <td>{i.original_pass}</td>
                      <td>{i.email}</td>
                      <td>
                        +{i.country?.phone_code} {i?.mobile}
                      </td>
                      <td>{i.country?.name}</td>
                      <td>
                        {i.country?.currency_name}{" "}
                        <b>{i.country?.currency_symbol}</b>
                      </td>
                      <td>
                        {i.user_role?.title === "Wholesaler" && <label
                          onClick={() =>
                            handlePriceStatusChange(
                              i.id,
                              i.is_show_price === 1 ? 0 : 1
                            )
                          }
                          className={
                            i.is_show_price === 1
                              ? "custom_check active"
                              : "custom_check"
                          }
                        >
                          <div className="custom_check_wrapper">
                            <span></span>
                            <label>{i.is_show_price === 1 ? "On" : "Off"}</label>
                          </div>
                        </label>}
                      </td>
                      <td>{i.user_role?.title}</td>
                      <td>
                        <label
                          onClick={() => handleStatusChange(i.id, i.status_id)}
                          className={
                            i.status_id === 1
                              ? "custom_check active"
                              : "custom_check"
                          }
                        >
                          <div className="custom_check_wrapper">
                            <span></span>
                            <label>
                              {i.status_id === 1 ? "Active" : "Deactivated"}
                            </label>
                          </div>
                        </label>
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <BiDotsVerticalRounded />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleShowUserEditForm(i)}
                            >
                              <MdEdit /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleShowAddAddressForm(i)}
                            >
                              <MdEdit /> Add Address
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleChangePassword(i.id)}
                            >
                              <MdEdit /> Change Password
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDeleteUser(i.id)}>
                              <MdDelete /> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </LTable>
          <Pagination
            pagecount={userList?.total_pages}
            handleGoToPage={handleGoToPage}
            currentpage={page || 1}
            onPageChange={handlePageChange}
          />

        </Card.Body>
      </Card>

      <Modal show={UserEditForm} size="lg" onHide={handleCloseUserEditForm}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditUser
            userData={userData}
            onFormSubmitSuccess={handleFormSubmitSuccess}
          />
        </Modal.Body>
      </Modal>

      <Modal show={UserAddForm} size="lg" onHide={handleCloseUserAddForm}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddUser onFormSubmitSuccess={handleFormSubmitSuccess} />
        </Modal.Body>
      </Modal>

      <Modal show={AddAddressForm} size="lg" onHide={handleCloseAddAddressForm}>
        <Modal.Header closeButton>
          <Modal.Title>Add Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddAddress
            userId={userId}
            onFormSubmitSuccess={handleCloseAddAddressForm}
          />
        </Modal.Body>
      </Modal>
    </Pagelayout>
  );
}

import React, { useEffect, useState } from "react";
import Ltable from "../../Components/LTable";
import Pagelayout from "../../Layouts/Pagelayout";
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Service from "../../http";
import Image from "../../Components/Image";
import Currency from "../../Components/Currency";
import Notification, { notify } from "./../../Components/Notification";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductList,
  fetchProductListAll,
  sortByColorId,
  sortByName,
  sortByDate,
  sortByStatus,
} from "../../redux/productSlice";
import { Link, useParams } from "react-router-dom";
import { BiDotsVerticalRounded, BiFilter } from "react-icons/bi";
import { MdDelete, MdEdit, MdSearch } from "react-icons/md";
import Pagination from "../../Components/Pagination";
import RightSidebar from "../../Components/RightSidebar";
import {
  AiOutlineArrowDown,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineArrowUp,
  AiOutlineClose,
  AiOutlineSearch,
} from "react-icons/ai";
import RangeSlider from "../../Components/RangeSlider";
import Label from "../../Components/Label";
import { fetchCategories } from "../../redux/categorySlice";
import CustomInput from "../../Components/CustomInput";
import { amount, formatDate, tableSerialNumber } from "../../Components/Utils";
import { fetchColorList } from "./../../redux/colorSlice";
import EditSize from "./EditSize";
import ItemDetails from "./ItemDetails";
import { IoMdEye } from "react-icons/io";
import Swal from "sweetalert2";

export default function ProductList() {
  const services = new Service();

  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState("");

  const [size, setSize] = useState("");

  const [sizeList, setSizeList] = useState([]);

  const [price, setPrice] = useState("");

  const [category, setCategory] = useState("");

  const [color, setColor] = useState("");

  const [status, setStatus] = useState("");

  const [toggleSidebar, setToggleSidebar] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [skuSearchQuery, setSkuSearchQuery] = useState("");

  const [page, setPage] = useState(1);

  const productList = useSelector((state) => state.product)?.productList;

  const selectedCurrency = useSelector(
    (state) => state.property.selectedCurrency
  )?.id;

  const categories = useSelector((state) => state.category?.categories?.data);

  const colorList = useSelector((state) => state.color.colorlist.data);

  const [productInfo, setProductInfo] = useState([]);

  const [showDetails, setShowDetails] = useState(false);

  const handleCloseDetails = () => setShowDetails(false);

  const handleShowDetails = (productInfo) => {
    setProductInfo(productInfo);
    setShowDetails(true);
  };

  const [showSize, setShowSize] = useState(false);

  const handleCloseSize = () => setShowSize(false);

  const handleShowSize = (productInfo) => {
    setProductInfo(productInfo);
    setShowSize(true);
  };

  const fetchEntry = () => {
    dispatch(
      fetchProductListAll({
        page,
        search: searchQuery,
        category,
        price,
        size,
        color,
        status,
        sku: skuSearchQuery,
        isActive,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchColorList());
  }, [dispatch, color]);

  useEffect(() => {
    // Dispatch the fetchCategories async thunk when the component mounts
    dispatch(fetchCategories());
  }, [dispatch]);

  const fetchSizeEntry = () => {
    services &&
      services.get("size/size-name/list").then((res) => {
        setSizeList(res.data);
      });
  };
  useEffect(() => {
    fetchSizeEntry();
  }, [dispatch]);

  const handlePageChange = (newPage) => {
    setPage(parseInt(newPage));
  };

  const handleGoToPage = (event) => {
    setPage(parseInt(event.target.value));
  };

  const handleSortByName = () => {
    dispatch(sortByName());
  };

  const handleSortByDate = () => {
    dispatch(sortByDate());
  };
  const handleSortByStatus = () => {
    dispatch(sortByStatus());
  };

  const handleSortByColorId = () => {
    dispatch(sortByColorId());
  };

  useEffect(() => {
    fetchEntry();
  }, [
    dispatch,
    size,
    price,
    category,
    page,
    color,
    searchQuery,
    status,
    skuSearchQuery,
    isActive,
  ]);

  const handleStatusChange = (couponId, isActive) => {
    services &&
      services
        .put("product/status/" + couponId, {
          is_active: isActive === true ? 0 : 1,
        })
        .then((res) => {
          if (res.status) {
            notify(res.message);
            fetchEntry();
          } else {
            notify(res.message[0]?.message);
            fetchEntry();
          }
        })
        .catch((error) => {
          console.error("Error - ", error);
        });
  };

  const [isAlertVisible, setAlertVisible] = useState(false);
  const [alertOptions, setAlertOptions] = useState({});

  const showSweetAlert = (options) => {
    setAlertOptions(options);
    setAlertVisible(true);
  };
  const handleConfirm = () => {
    if (alertOptions.onConfirm) {
      alertOptions.onConfirm();
    }
    setAlertVisible(false);
  };

  const handleCancel = () => {
    setAlertVisible(false);
  };

  const deleteProduct = (productId) => {
    Swal.fire({
      title: "Delete Product",
      text: "Are you sure you want to delete this product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Call your delete product function here
        services
          .put("product/delete/" + productId)
          .then((res) => {
            if (res.status) {
              notify(res.message);
              fetchEntry();
            } else {
              notify(res.message[0]?.message);
            }
          })
          .catch((error) => {
            console.error("Error - ", error);
          });
      }
    });
  };

  const handleRangeValueChange = (newValues) => {
    setPrice(newValues);
  };

  const searchName = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchSku = (event) => {
    setSkuSearchQuery(event.target.value);
  };

  const getSizeFunc = (event) => {
    console.log(event.target.value);
  };

  const clearFilter = () => {
    setCategory("");
    setPrice("");
    setSearchQuery("");
    setSkuSearchQuery("");
    setSize("");
  };

  const handleSearchClick = (event) => {
    const thSearchable = event.currentTarget.closest(".th_searchable");
    if (thSearchable) {
      const input =
        thSearchable.querySelector("input") ||
        thSearchable.querySelector("select");
      const title = thSearchable.querySelector(".thTitle");
      const icon = thSearchable.querySelector(".icon");

      if (input && title) {
        input.classList.toggle("show-input");
        title.classList.toggle("show-thTitle");
        icon.classList.toggle("toggleIcon");
      }
    }
  };

  return (
    <Pagelayout
      title="Product List"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Products", link: "/products" },
      ]}
      actions={
        <>
          <Link className="btn btn-primary btn-lg" to={"/add-products"}>
            Add Product
          </Link>{" "}
          &nbsp;
          {/* <Link className='btn btn-primary btn-lg' to={'/products/import'}>Import Product</Link> */}
        </>
      }
    >
      <RightSidebar
        className={!toggleSidebar ? "close" : ""}
        onCloseSidebar={() =>
          toggleSidebar ? setToggleSidebar(false) : setToggleSidebar(true)
        }
      >
        <div className="header">
          <h6 className="m-0 flex-grow-1 pe-3">Filters</h6>
          <AiOutlineClose onClick={() => setToggleSidebar(false)} />
        </div>
        <div className="body">
          <div className="px-3">
            <Label className="mb-3">Price Range</Label>
            <RangeSlider rangeValue={handleRangeValueChange} />
          </div>
          <hr />
          <ul>
            <li>
              <Link onClick={() => setCategory("")}>All</Link>
            </li>
            {categories &&
              categories.length > 0 &&
              categories.map((i, index) => (
                <li key={index}>
                  <Link onClick={() => setCategory(i.slug)}>{i.name}</Link>
                </li>
              ))}
          </ul>
          <div className="p-3">
            <Button className="w-100" onClick={() => clearFilter()}>
              Clear Filter
            </Button>
          </div>
        </div>
      </RightSidebar>
      <Notification />
      <Card>
        <Card.Body>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              Total Page - {productList?.total_pages} &nbsp; | &nbsp; Items on
              page - {productList?.rows.length} &nbsp; | &nbsp; Total Items -{" "}
              {productList?.count}
            </div>
            <Pagination
              pagecount={productList?.total_pages}
              handleGoToPage={handleGoToPage}
              currentpage={page || 1}
              onPageChange={handlePageChange}
            />
          </div>
          <Ltable isLoading={productList?.loading}>
            <thead>
              <tr>
                <th>#</th>
                <th colSpan={2}>
                  <div className="th_searchable">
                    <span
                      className="thTitle show-thTitle "
                      onClick={handleSortByName}
                    >
                      <span>
                        {productList?.sortOrder === "asc" ? (
                          <AiOutlineArrowUp />
                        ) : (
                          <AiOutlineArrowDown />
                        )}
                      </span>
                      &nbsp; Product
                    </span>
                    <input
                      onChange={(event) => searchName(event)}
                      type="search"
                      placeholder="Search Product..."
                    />
                    <span className="icon" onClick={handleSearchClick}>
                      <AiOutlineSearch className="searchIcon" />
                      <AiOutlineClose
                        className="closeIcon"
                        onClick={() => setSearchQuery("")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <div className="th_searchable">
                    <span className="thTitle show-thTitle "> SKU</span>
                    <input
                      onChange={(event) => searchSku(event)}
                      type="search"
                      placeholder="Search Product..."
                    />
                    <span className="icon" onClick={handleSearchClick}>
                      <AiOutlineSearch className="searchIcon" />
                      <AiOutlineClose
                        className="closeIcon"
                        onClick={() => setSkuSearchQuery("")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <div className="th_searchable">
                    <span className="thTitle show-thTitle ">Color</span>
                    <select onChange={(event) => setColor(event.target.value)}>
                      <option value="">All</option>
                      {colorList &&
                        colorList.length > 0 &&
                        colorList.map((i, index) => (
                          <option key={index} value={i.id}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                    <span className="icon" onClick={handleSearchClick}>
                      <AiOutlineSearch className="searchIcon" />
                      <AiOutlineClose
                        className="closeIcon"
                        onClick={() => setColor("")}
                      />
                    </span>
                  </div>
                </th>

                <th>
                  <div className="th_searchable">
                    <span className="thTitle show-thTitle ">Category</span>
                    <select
                      onChange={(event) => setCategory(event.target.value)}
                    >
                      <option value="">All</option>
                      {categories &&
                        categories.length > 0 &&
                        categories.map((i, index) => (
                          <option key={index} value={i.slug}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                    <span className="icon" onClick={handleSearchClick}>
                      <AiOutlineSearch className="searchIcon" />
                      <AiOutlineClose
                        className="closeIcon"
                        onClick={() => setCategory("")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <div className="th_searchable">
                    <span className="thTitle show-thTitle ">Size</span>
                    <select onChange={(event) => setSize(event.target.value)}>
                      <option value="">All</option>
                      {sizeList &&
                        sizeList.map((i, index) => (
                          <option key={index} value={i.name}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                    <span className="icon" onClick={handleSearchClick}>
                      <AiOutlineSearch className="searchIcon" />
                      <AiOutlineClose
                        className="closeIcon"
                        onClick={() => setSize("")}
                      />
                    </span>
                  </div>
                </th>
                <th>Stocks</th>
                <th>Weight</th>
                <th>Price Per Gram</th>
                <th className="text-nowrap">Selling Price</th>
                <th>Discount</th>
                <th>
                  <div className="th_searchable">
                    <span className="thTitle show-thTitle ">Status</span>
                    <select
                      onChange={(event) => setIsActive(event.target.value)}
                    >
                      <option value="">All</option>
                      <option value={1}>Active</option>
                      <option value={0}>Deactive</option>
                      {/* {sizeList &&
                        sizeList.map((i, index) => (
                          <option key={index} value={i.name}>
                            {i.name}
                          </option>
                        ))} */}
                    </select>
                    <span className="icon" onClick={handleSearchClick}>
                      <AiOutlineSearch className="searchIcon" />
                      <AiOutlineClose
                        className="closeIcon"
                        onClick={() => setSize("")}
                      />
                    </span>
                  </div>
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {productList?.rows !== undefined &&
                productList?.rows.map((i, index) => {
                  const serialNumber = tableSerialNumber(
                    index,
                    productList?.cur_page,
                    productList?.rows.length,
                    20
                  );
                  return (
                    <tr key={index}>
                      <td>{serialNumber}</td>
                      <td width={50}>
                        <Image
                          height={50}
                          width={50}
                          className="shadow-sm enlarge_img"
                          style={{ objectFit: "cover", borderRadius: 10 }}
                          src={
                            i?.code_image_path !== null
                              ? `${i?.code_image_path}/${i?.code_image_name}?resize=250,250`
                              : `${i?.product_images[0]?.image_path}/${i?.product_images[0]?.image_name}?resize=250,250`
                          }
                          loading="lazy"
                        />
                      </td>
                      <td>
                        <div
                          onClick={() => handleShowDetails(i)}
                          style={{ width: 180 }}
                        >
                          {i.name}{" "}
                          {i.is_new !== 0 && (
                            <>
                              ,<Badge varient={"success"}>New</Badge>
                            </>
                          )}
                        </div>
                      </td>
                      <td>{i?.sku}</td>
                      <td>{i?.color?.name}</td>
                      <td>
                        {categories &&
                          categories.find((j) => j.id === i.category_id)?.name}
                      </td>
                      <td>
                        {i.sizes.length !== 0 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip style={{ maxWidth: 200 }} id="tooltip">
                                <b>Sizes</b>

                                <div>
                                  {i.sizes.map((j) => (
                                    <span key={j.id} className="comma">
                                      {j.size_name?.name !== undefined &&
                                        j.size_name?.name}
                                      
                                    </span>
                                  ))}
                                </div>
                              </Tooltip>
                            }
                          >
                            <Link to={"#"}>Sizes</Link>
                          </OverlayTrigger>
                        ) : (
                          <em className="text-danger">No Sizes</em>
                        )}
                      </td>
                      <td>
                        {i.sizes.length !== 0 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip style={{ maxWidth: 200 }} id="tooltip">
                                <b>Stocks</b>
                                <div>
                                  {i.sizes.map((j, index) => (
                                    <span key={index} className="comma">
                                     {j.stock !== undefined && j.stock}
                                      
                                    </span>
                                  ))}
                                </div>
                              </Tooltip>
                            }
                          >
                            <Link to={"#"}>Stocks</Link>
                          </OverlayTrigger>
                        ) : (
                          <em className="text-danger">No Stocks</em>
                        )}
                      </td>
                      <td>
                        {i.sizes.length !== 0 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip style={{ maxWidth: 200 }} id="tooltip">
                                <b>Weight</b>
                                <div>
                                  {i.sizes.map((j) => (
                                    <span key={j.weight} className="comma">
                                  {j.weight} Gms
                                    </span>
                                  ))}
                                </div>
                              </Tooltip>
                            }
                          >
                            <Link to={"#"}>Weight</Link>
                          </OverlayTrigger>
                        ) : (
                          <em className="text-danger">No Weight</em>
                        )}
                      </td>

                      <td style={{ whiteSpace: "pre" }}>
                        {i.sizes.length !== 0 ? (
                          <>
                            <Currency />{" "}
                            {amount(
                              i.sizes[0]?.size_prices?.find(
                                (i) => i.currency_id === selectedCurrency
                              )?.price_gram
                            )}
                          </>
                        ) : (
                          <em className="text-danger">No Price</em>
                        )}
                      </td>
                      <td style={{ whiteSpace: "pre" }}>
                        {i.sizes.length !== 0 ? (
                          <>
                            Gms - <Currency />{" "}
                            {amount(
                              i.sizes[0]?.size_prices?.find(
                                (i) => i.currency_id === selectedCurrency
                              )?.price_gram *
                                parseFloat(i.sizes.map((j) => j.weight))
                            )}
                            <br />
                            Piece - <Currency />{" "}
                            {amount(
                              i.sizes[0]?.size_prices?.find(
                                (i) => i.currency_id === selectedCurrency
                              )?.price_piece
                            )}
                          </>
                        ) : (
                          <em className="text-danger">No Price</em>
                        )}
                      </td>
                      <td>
                        {i.sizes.length !== 0 ? (
                          <>
                            {" "}
                            {amount(
                              i.sizes[0]?.size_prices?.find(
                                (i) => i.currency_id === selectedCurrency
                              )?.discount
                            ) || 0}
                            %
                          </>
                        ) : (
                          <em className="text-danger">No Discount</em>
                        )}
                      </td>
                      <td
                        style={{
                          pointerEvents: i.sizes.length === 0 ? "none" : "auto",
                        }}
                      >
                        {i.is_active}
                        <label
                          onClick={() => handleStatusChange(i.id, i.is_active)}
                          className={
                            i.is_active === true
                              ? "custom_check active"
                              : "custom_check"
                          }
                        >
                          <div className="custom_check_wrapper">
                            <span></span>
                            <label>
                              {i.is_active === true ? "Active" : "Deactivated"}
                            </label>
                          </div>
                        </label>
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <BiDotsVerticalRounded />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              as={Link}
                              to={"/products/edit-product/" + i.id}
                            >
                              <MdEdit /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleShowDetails(i)}
                              href="#"
                            >
                              <IoMdEye /> View Detail
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleShowSize(i)}
                              href="#"
                            >
                              <MdEdit /> Edit Sizes / Price
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => deleteProduct(i.id)}
                              href="#"
                            >
                              <MdDelete /> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Ltable>
          <Pagination
            pagecount={productList?.total_pages}
            handleGoToPage={handleGoToPage}
            currentpage={page || 1}
            onPageChange={handlePageChange}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showSize}
        backdrop="static"
        keyboard={false}
        size="xl"
        onHide={handleCloseSize}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="h3">
              {productInfo?.name}
              <h6 className="mt-2">Category - {productInfo?.category?.name}</h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditSize
            categoryId={productInfo?.category_id}
            closepopup={handleCloseSize}
            sizeData={productInfo?.sizes}
            product_id={productInfo.id}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showDetails}
        backdrop="static"
        keyboard={false}
        size="xl"
        onHide={handleCloseDetails}
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemDetails list={productList?.rows} data={productInfo} />
        </Modal.Body>
      </Modal>
    </Pagelayout>
  );
}

// couponSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';

// Define an initial state
const initialState = {
  coupons: [],
  loading: false,
  error: null,
};

// Create an instance of the Service class
const service = new Service();

// Define the async thunk for fetching coupons
export const fetchCoupons = createAsyncThunk('coupons/fetchCoupons', async ({ page = '', search = '' }) => {
  try {
    const params = new URLSearchParams({ page, search }).toString();
    const url = `coupon/list?${params}`;
    const data = await service.get(url);
    return data?.data;
  } catch (error) {
    throw Error('Failed to fetch coupons');
  }
});

// Define the async thunk for fetching coupon details by id
export const fetchCouponDetails = createAsyncThunk('coupons/fetchCouponDetails', async (couponId) => {
  try {
    const data = await service.get(`coupon/${couponId}`);
    return data?.data;
  } catch (error) {
    throw Error('Failed to fetch coupon details');
  }
});

// Create the slice
const couponSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoupons.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.coupons = action.payload;
      })
      .addCase(fetchCoupons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCouponDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCouponDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.coupons = [action.payload]; // Assuming you want to store details as an array for consistency
      })
      .addCase(fetchCouponDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the async thunk for external usage
// export { fetchCoupons };

// Export the reducer
export default couponSlice.reducer;

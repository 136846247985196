import axios from "axios";
import { logoutApp } from "./Components/Utils";

//Create a instance
// const apiUrl = (config.enviornment === 'production') ? config.production.api_url : config.development.api_url;
const apiUrl = process.env.REACT_APP_BASE_URL
const instance = axios.create({
   baseURL: apiUrl,
   headers: {
      'Content-Type': 'application/json',
   }
});



//Set AUTH token for any request
instance.interceptors.request.use(function (config) {
   const token = localStorage.getItem('token') ? localStorage.getItem('token').replace(/['"]+/g, "") : '';
   config.headers.Authorization = token ? `Bearer ${token}`.replace(/"/g, "") : '';
   console.log(token)
   return config;
});

const doAPI = {
   isResponseExpired: (response) => {
      return response.data && response.data.is_expire;
   },
     // Handle 401 error
  handle401Error: () => {
   console.log('Token is expired or invalid');
   logoutApp(); // Redirect to login or handle it in your own way
},
   //Get data from the server by method = GET
   getData: (URL, parameters = {}) =>
   instance({
      'method': 'GET',
      'url': URL,
      'params': parameters
   })
   .then(response => {
      if (doAPI.isResponseExpired(response)) {
         doAPI.handle401Error();
      } else {
         
         return response;
      }
   })
   .catch(error => {
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
         doAPI.handle401Error();
      } else {
         console.error('API request error:', error);
         throw error;
      }
   }),
   //Post JSON Object on the server by method = POST
   postData: (URL, parameters = {}) =>
      instance({
         'method': 'POST',
         'url': URL,
         'data': parameters
      }).then(response => {
         if (doAPI.isResponseExpired(response)) {
            doAPI.handle401Error();
         } else {
            
            return response;
         }
      })
      .catch(error => {
         if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
            doAPI.handle401Error();
         } else {
            console.error('API request error:', error);
            throw error;
         }
      }),
   //Post Fromdata on the server by method = POST
   postWithImage: async (url, body, callback) => {
      const token = localStorage.getItem('token') ? localStorage.getItem('token').replace(/['"]+/g, "") : '';
      const formData = createFormData(body);
      console.log(`Bearer ${token}`)
      return axios.post(apiUrl + url, formData, {
         headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: token ? `Bearer ${token}` : '',
         },
         transformRequest: formData => formData,
         onUploadProgress: callback,
      }).catch(error => {
         throw error;
      });
   },
   //Post JSON Object on the server by method = PUT
   putData: (URL, parameters = {}) =>
      instance({
         'method': 'PUT',
         'url': URL,
         'data': parameters
      }).then(response => {
         if (doAPI.isResponseExpired(response)) {
            doAPI.handle401Error();
         } else {
            
            return response;
         }
      })
      .catch(error => {
         if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
            doAPI.handle401Error();
         } else {
            console.error('API request error:', error);
            throw error;
         }
      }),
};

/**
 * Helper function to create a from data
 * @param {*} body 
 * @returns 
 */
const createFormData = (body) => {
   const data = new FormData();
   Object.keys(body).forEach(key => {
      data.append(key, body[key]);
   });
   return data;
};

export default doAPI;
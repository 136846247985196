import React, { useEffect, useState } from 'react'
import Service from '../../http';
import { useDispatch, useSelector } from 'react-redux';
import Notification, { notify } from '../../Components/Notification';
import { Button, Card, Dropdown, Modal } from 'react-bootstrap';
import Pagelayout from '../../Layouts/Pagelayout';
import LTable from '../../Components/LTable';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';
import EditVatSetting from './EditVatSetting';
import { logoutApp, tableSerialNumber } from '../../Components/Utils';
import { fetchVATInfo } from '../../redux/vatSlice';

export default function SetVat() {
  const services = new Service();
  const [setting, setSetting] = useState([])
  const [loading, setLoading] = useState([])
  const [selectedData, setSelectedData] = useState()
  const [SettingEditForm, setShowSettingEditForm] = useState(false);
  const handleCloseSettingEditForm = () => setShowSettingEditForm(false);
  const handleShowSettingEditForm = (data) => {
    setSelectedData(data)
    setShowSettingEditForm(true)
  };

  const currency = useSelector((state) => state.currency)?.currencylist?.data;

  const dispatch = useDispatch();
  const vatInfo = useSelector((state) => state?.vatSetting);


  useEffect(() => {
    // Dispatch the fetchVATInfo action when the component mounts
    dispatch(fetchVATInfo());
  }, [dispatch]);

  const handleFormSubmitSuccess = () => {
    setShowSettingEditForm(false)
  }

  const handleStatusChange = (value, status) => {
    console.log(status)
    services && services.post('product/setting/set-vat',
      {
        is_vat: status == false ? 1 : 0,
        vat: value?.vat,
        currency_id: value?.currency_id
      }).then((res) => {
        if (res.status) {
          notify(res.message);
          dispatch(fetchVATInfo());
        } else {
          notify(res.message[0]?.message[0]?.message, { variant: 'error' });
        }
      })
  }

  return (
    <Pagelayout
      title="VAT Setting"
      breadcrumbs={
        [
          { title: 'Dashboard', link: '/dashboard' },
          { title: 'VAT Setting', link: '#' },
        ]
      }
    // actions={<Button onClick={() =>handleShowSettingEditForm()}>Add VAT Setting</Button>}
    >
      <Card>
        <Notification />
        <Card.Body>
          <LTable isLoading={vatInfo.status !== 'succeeded'}>
            <thead>
              <tr>
                <th>#</th>
                <th>VAT Amount</th>
                <th>Currency</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {vatInfo?.vatInfo && vatInfo?.vatInfo.map((i, index) => <tr key={index}>
              <td>{tableSerialNumber(index, vatInfo?.vatInfo.cur_page, vatInfo?.vatInfo.length)}</td>

                <td>{i.vat}%</td>
                <td>{i.currency.name} {i.currency.symbol}</td>
                <td>
                  <label
                    onClick={() => handleStatusChange(i, i.is_vat == 1)}
                    className={i.is_vat == 1 ? 'custom_check active' : 'custom_check'}
                  >
                    <div className='custom_check_wrapper'>
                      <span></span>
                      <label>
                        {i.is_vat == 1 ? 'Yes' : 'No'}
                      </label>
                    </div>
                  </label>
                </td>
                <td>
                  <Button onClick={() => handleShowSettingEditForm(i)}><MdEdit /> Edit</Button>

                </td>
              </tr>)}
            </tbody>
          </LTable>
        </Card.Body>
      </Card>


      <Modal show={SettingEditForm} onHide={handleCloseSettingEditForm}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditVatSetting onFormSubmitSuccess={handleFormSubmitSuccess} data={selectedData} />
        </Modal.Body>
      </Modal>

    </Pagelayout>

  )
}
